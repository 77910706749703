import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Stack, IconButton, 
      InputAdornment, TextField, Checkbox,
       Typography, Grid, FormControlLabel, RadioGroup, 
       FormControl, FormLabel, Radio, 
       Button,Select,MenuItem} from '@mui/material';
       
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';
import { useDispatch, useSelector } from 'react-redux';
import { signin, signinBusiness } from 'src/redux/actions/auth.action';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { createGroup, createJob } from 'src/redux/actions/group.action';

// ----------------------------------------------------------------------

export default function JobCreateForm() {
  const navigate = useNavigate();


  const StyledConnector = styled('div')(({ theme }) => ({
   
  
    [theme.breakpoints.down('xl')]: {
     
      left:"-5rem",
    },
  
  
    [theme.breakpoints.down('lg')]: {
     
      left:"-2rem",
    },
  
  
    [theme.breakpoints.down('md')]: {
     
      left:"3rem",
    }
  
  
  
  }));


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [bio, setBio] = useState('');

  const [classes,setClasses] =useState('')

 

  const [positionTitle, setPositionTitle] = useState('');


  const { user } = useSelector((state) => state.auth);
  const [companyName, setCompanyName] = useState(user && user.companyName?user.companyName:"");
  const  [companyEmail, setCompanyEmail] = useState("");
  const  [companyPhone, setCompanyPhone] = useState("");
  const  [companyWebsite, setCompanyWebsite] = useState("");

  const [selectedFile, setSelectedFile] = useState({selectedFile: [], selectedFileName: []});
  const [file, setFile] = useState();
  const handleselectedFile = event => {
    setSelectedFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
    setFile(URL.createObjectURL(event.target.files[0]));
};




  const [jobRole, setjobRole] = useState('');
  const [salary, setSalary] = useState('');
  const [skillLevel,setSkillLevel] = useState('')
  const [typeOfRemoteWork,setTypeOfRemoteWork] = useState('')
  const [workStatus, setWorkStatus] = useState('');
  const [addressOnSite, setAddressOnSite] = useState('');
  const [hiringManagerName, setHiringManagerName] = useState('');
  const [teamSize, setTeamSize] = useState('');
  const [roleAvailabilityReason, setRoleAvailabilityReason] = useState('');
  const [essentialSkills, setEssentialSkills] = useState('');

  const [nonEssentialSkills, setNonEssentialSkills] = useState('');

  const [successfulPersonDesc, setSuccessfulPersonDesc] = useState('');

  const [numberOfOpenings, setNumberOfOpenings] = useState('');

  const [sharedElseWhere, setSharedElseWhere] = useState('');

  const  [description,setDescription] = useState('');

  const [typeOfInterview1,setTypeOfInterview1]= useState('');
  const [typeOfInterview2,setTypeOfInterview2]= useState('');
  const [typeOfInterview3,setTypeOfInterview3]= useState('');

  const [typeOfInterview4,setTypeOfInterview4]= useState('');
  const [typeOfInterview5,setTypeOfInterview5]= useState('');
  const [typeOfInterview6,setTypeOfInterview6]= useState('');

  const [interviewRound1,setInterviewRound1]= useState('');
  const [interviewRound2,setInterviewRound2]= useState('');
  const [interviewRound3,setInterviewRound3]= useState('');

  const [interviewRound4,setInterviewRound4]= useState('');
  const [interviewRound5,setInterviewRound5]= useState('');
  const [interviewRound6,setInterviewRound6]= useState('');
  
  const [interviewLead1,setInterviewLead1]= useState('');
  const [interviewLead2,setInterviewLead2]= useState('');
  const [interviewLead3,setInterviewLead3]= useState('');
  

  const [interviewLead4,setInterviewLead4]= useState('');
  const [interviewLead5,setInterviewLead5]= useState('');
  const [interviewLead6,setInterviewLead6]= useState('');

  const [interviewLead7,setInterviewLead7]= useState('');
  const [interviewLead8,setInterviewLead8]= useState('');
  const [interviewLead9,setInterviewLead9]= useState('');
  

  const [duration1,setDuration1]= useState('');
  const [duration2,setDuration2]= useState('');
  const [duration3,setDuration3]= useState('');


  const [feedback1,setFeedback1]= useState('');
  const [feedback2,setFeedback2]= useState('');
  const [feedback3,setFeedback3]= useState('');

  const [interviewLocation1,setInterviewLocation1]= useState('');
  const [interviewLocation2,setInterviewLocation2]= useState('');
  const [interviewLocation3,setInterviewLocation3]= useState('');

  const [interviewPanelMembers1,setInterviewPanelMembers1]= useState('');
  const [interviewPanelMembers2,setInterviewPanelMembers2]= useState('');
  const [interviewPanelMembers3,setInterviewPanelMembers3]= useState('');
  

  const [interviewPanelMembers4,setInterviewPanelMembers4]= useState('');
  const [interviewPanelMembers5,setInterviewPanelMembers5]= useState('');
  const [interviewPanelMembers6,setInterviewPanelMembers6]= useState('');
  
   //RIGHT NOW  - JUN 12 2024, I AM ONLY ACTIVELY USING INTERVIEW PANEL MEMBERS 1 -6, TWO IN EACH TABLE, IN NUMERICAL ORDER - DAGOGO

  const [interviewPanelMembers7,setInterviewPanelMembers7]= useState('');
  const [interviewPanelMembers8,setInterviewPanelMembers8]= useState('');
  const [interviewPanelMembers9,setInterviewPanelMembers9]= useState('');


  const [availabilityStart1,setAvailabilityStart1]= useState('');
  const [availabilityStart2,setAvailabilityStart2]= useState('');
  const [availabilityStart3,setAvailabilityStart3]= useState('');

  const [availabilityStart4,setAvailabilityStart4]= useState('');
  const [availabilityStart5,setAvailabilityStart5]= useState('');
  const [availabilityStart6,setAvailabilityStart6]= useState('');

  const [availabilityStart7,setAvailabilityStart7]= useState('');
  const [availabilityStart8,setAvailabilityStart8]= useState('');
  const [availabilityStart9,setAvailabilityStart9]= useState('');


  const [availableDays1,setAvailableDays1]=useState('')
  const [availableDays2,setAvailableDays2]=useState('')
  const [availableDays3,setAvailableDays3]=useState('')


  const [availabilityEnd1,setAvailabilityEnd1]= useState('');
  const [availabilityEnd2,setAvailabilityEnd2]= useState('');
  const [availabilityEnd3,setAvailabilityEnd3]= useState('');

  const [availabilityEnd4,setAvailabilityEnd4]= useState('');
  const [availabilityEnd5,setAvailabilityEnd5]= useState('');
  const [availabilityEnd6,setAvailabilityEnd6]= useState('');

  const [availabilityEnd7,setAvailabilityEnd7]= useState('');
  const [availabilityEnd8,setAvailabilityEnd8]= useState('');
  const [availabilityEnd9,setAvailabilityEnd9]= useState('');


  
  const [jobPostings,setJobPostings] = useState('')
const [serviceFeeEnd, setServiceFeeEnd] =useState('')
const [serviceFeeStart,setServiceFeeStart] =useState('')
const [salaryRangeStart,setSalaryRangeStart] =useState('')
const [salaryRangeEnd,setSalaryRangeEnd] =useState('')
const [servicePercentage,setServicePercentage] = useState('')






  const dispatch = useDispatch(); 

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    marginTop:"0.2rem",
    cursor:"pointer"
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
    marginTop:"0.2rem"
  };

  const inputContainer2 = {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    marginTop:"0.2rem"
  };



  const userSignin = (e) => {
    e.preventDefault();
    setLoading(true);
    const user = { email, password };
    dispatch(signinBusiness(user, navigate, setLoading));
  }


 
  const createThisGroup = (e) => {
    e.preventDefault();
  
    setLoading(true);
   /* const id = user.id;
    const name = user.firstName + " " + user.lastName;
    const email = user.email;
    const profileImg = user.imageUrl;*/
    //const userData = {id, name, email, profileImg};
    let userData = {
    businessName:companyName,
    companyEmail,
    companyPhone,
    companyWebsite,
    jobRole,
    salary,
    skillLevel,
    typeOfRemoteWork,
    workStatus,
    addressOnSite,
    hiringManagerName,
    teamSize,
    interviewRound1:{
      typeOfInterview:typeOfInterview1,
      duration:duration1,
      location:interviewLocation1,
      interviewLead:interviewLead1,
      interviewPanelMember1:interviewPanelMembers1,
      interviewPanelMember2:interviewPanelMembers2,
      availabilityStart1:availabilityStart1,
      availabilityEnd1: availabilityEnd1,
      availabilityStart2: availabilityStart2,
      availabilityEnd2:availabilityEnd2,
      availabilityStart3:availabilityStart3,
      availabilityEnd3:availabilityEnd3,
      availableDays:availableDays1
  
      },
      interviewRound2:{
    
        typeOfInterview:typeOfInterview2,
        duration:duration2,
        location:interviewLocation2,
        interviewLead:interviewLead2,
        interviewPanelMember1:interviewPanelMembers3,
        interviewPanelMember2:interviewPanelMembers4,
        availabilityStart1:availabilityStart4,
        availabilityEnd1: availabilityEnd4,
        availabilityStart2: availabilityStart5,
        availabilityEnd2:availabilityEnd5,
        availabilityStart3:availabilityStart6,
        availabilityEnd3:availabilityEnd6,
        availableDays:availableDays2
  
      },
      interviewRound3:{
  
        typeOfInterview:typeOfInterview3,
        duration:duration3,
        location:interviewLocation3,
        interviewLead:interviewLead3,
        interviewPanelMember1:interviewPanelMembers5,
        interviewPanelMember2:interviewPanelMembers6,
        availabilityStart1:availabilityStart7,
        availabilityEnd1: availabilityEnd7,
        availabilityStart2: availabilityStart8,
        availabilityEnd2:availabilityEnd8,
        availabilityStart3:availabilityStart9,
        availabilityEnd3:availabilityEnd9,
        availableDays:availableDays3
  
      },
    roleAvailabilityReason,
    essentialSkills,
    nonEssentialSkills,
    successfulPersonDesc,
    numberOfOpenings,
    sharedElseWhere,
    }
   
    dispatch(createJob(userData,/*,selectedFile.selectedFile, userData,*/ navigate, setLoading));
  
  


    setTimeout(()=>{




      setjobRole('') ;
      setSalary('') ;
     setSkillLevel('') 
   setTypeOfRemoteWork('') 
    setWorkStatus('') ;
      setAddressOnSite('') ;
      setHiringManagerName('') ;
     setTeamSize('') ;
     setRoleAvailabilityReason('') ;
      setEssentialSkills('') ;
    
     setNonEssentialSkills('') ;
     setSuccessfulPersonDesc('') ;
    
      setNumberOfOpenings ('');
    
      setSharedElseWhere('') 
    
     setTypeOfInterview1('');
     setTypeOfInterview2('');
     setTypeOfInterview3('');
    
     setTypeOfInterview4('');
     setTypeOfInterview5('');
     setTypeOfInterview6('');
    
     setInterviewRound1('');
     setInterviewRound2('');
     setInterviewRound3('');
    
     setInterviewRound4('');
     setInterviewRound5('');
     setInterviewRound6('');
      
    
     setInterviewLead1('');
     setInterviewLead2('');
     setInterviewLead3('');

     setInterviewLead4('');
     setInterviewLead5('');
     setInterviewLead6('');

     setInterviewLead7('');
     setInterviewLead8('');
     setInterviewLead9('');
      
    
     setDuration1('');
     setDuration2('');
     setDuration3('');
    
    
     setFeedback1('');
     setFeedback2('');
     setFeedback3('');
    
     setInterviewPanelMembers1('');
     setInterviewPanelMembers2('');
     setInterviewPanelMembers3('');
      
    
     setInterviewPanelMembers4('');
     setInterviewPanelMembers5('');
     setInterviewPanelMembers6('');
      
    
 setInterviewPanelMembers7('');
 setInterviewPanelMembers8('');
 setInterviewPanelMembers9('');
    
     setAvailabilityStart1('');
     setAvailabilityStart2('');
     setAvailabilityStart3('');
    
     setAvailabilityStart4('');
     setAvailabilityStart5('');
     setAvailabilityStart6('');
    
     setAvailabilityStart7('');
     setAvailabilityStart8('');
     setAvailabilityStart9('');
   
       setAvailabilityEnd1('');
       setAvailabilityEnd2('');
       setAvailabilityEnd3('');
      
       setAvailabilityEnd4('');
       setAvailabilityEnd5('');
       setAvailabilityEnd6('');
      
       setAvailabilityEnd7('');
       setAvailabilityEnd8('');
       setAvailabilityEnd9('');
    
    
       setAvailableDays1([]);
       setAvailableDays2([]);
       setAvailableDays3([]);
      
    
    setJobPostings('') 
      setServiceFeeEnd('')
     setServiceFeeStart('')
    setSalaryRangeStart('')
     setSalaryRangeEnd('')
     setServicePercentage('') 



    },800)




  
  }



  const rows= [
    {interviewRound:"Round 1", typeOfInterview:"Team Interview -Virtual",feedback:"Basic Interview", duration:"3/4/2024"},
    {interviewRound:"Round 2", typeOfInterview:"Team Interview -Virtual",feedback:"Basic Interview", duration:"3/11/2024"},
    {interviewRound:"Round 3", typeOfInterview:"Team Interview -Virtual",feedback:"Basic Interview", duration:"3/11/2024"},
    
    
  ];

  const rows2 = [
   {interviewRound:"Round 1", interviewPanelMembers:["Mark Thomas - Lead Full Stack Developer","Mariah Johnson -Full Stack Developer","Ann Moore - Full Stack Developer"],interviewLead:"Mark Thomas"},
   {interviewRound:"Round 1", interviewPanelMembers:["Mark Thomas - Lead Full Stack Developer","Mariah Johnson -Full Stack Developer","Ann Moore - Full Stack Developer"],interviewLead:"Mark Thomas"},
   {interviewRound:"Round 1", interviewPanelMembers:["Mark Thomas - Lead Full Stack Developer","Mariah Johnson -Full Stack Developer","Ann Moore - Full Stack Developer"],interviewLead:"Mark Thomas"}
  ]


  return (
    <div style={{width:"80%",zIndex:"0"}}>
     <form onSubmit={createThisGroup}>



     <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",justifyContent:"flex-start",marginBottom:"2rem"}}>
              Company Information
            </Typography>



            <Grid container spacing={6} direction="row"  >
                <Grid item xs={6}>
                  <b style={{ fontSize:'17px', width: '100%', position:"relative",top:"-0.4rem"}}>Company name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Add answer here'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          
                          
                        },
                    }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <b style={{ fontSize:'17px', width: '100%', position:"relative",top:"-0.4rem" }}>Company Email Address*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='email@company.com'
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          
                          
                        },
                    }}
                    />
                  </div>
                </Grid>
              </Grid>


              <Grid container spacing={6} direction="row"  style={{marginTop:"0.1rem"}}>
                <Grid item xs={6}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Company Website*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Enter URL'
                      value={companyWebsite}
                      onChange={(e) => setCompanyWebsite(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          
                          
                        },
                    }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Company Phone Number*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='(123) 456-789'
                      value={companyPhone}
                      onChange={(e) => setCompanyPhone(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          
                          
                        },
                    }}
                    />
                  </div>
                </Grid>
              </Grid>






              <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",justifyContent:"flex-start",marginTop:"3rem",marginBottom:"2rem"}}>
              Position Information
            </Typography>

          <Grid container  xs={12} spacing={6} direction="row" style={{fontFamily:"Public Sans, sans-serif ",}}>
            <Grid item container  xs={6} spacing={6} style={{display:"flex",flexDirection:"row"}} >
                <Grid item xs={12}>
                  <b style={{ fontSize:'17px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}>Job Title*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Job Title'
                      value={jobRole}
                      onChange={(e) => setjobRole(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          
                        },
                    }}
                    />
                  </div>
                </Grid>


                {/*<Grid item xs={12}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Salary Range(do not include bonuses or any monetary rewards)*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='$150k - $200k'
                      value={salary}
                      onChange={(e) => setSalary(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          
                          
                        },
                    }}
                    />
                  </div>
                  </Grid>*/}



                <Grid item xs={12}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Skill level for the role?*</b>
                  <div style={inputContainer2}>
                  <FormControl style={{position:"relative",left:"-5.8rem",scale:"0.9"}}>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup  style={{color:"grey"}}
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                      <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.10)}} value="Entry Level(3yrs or less)" control={<Radio  style={{color:"grey"}} />}  label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative"}}>Entry Level(3yrs or less) <b style={{color:"#636363",width:"12rem",position:"absolute"}}>&nbsp; -&nbsp;  <s>10%</s> (Pilot Price: 7.5%)</b></Typography>} />
                     <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.15)}} value="Mid-level (3yrs - 5yrs)" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative",}}>Mid-level (3yrs - 5yrs)<b style={{color:"#636363",width:"15rem",position:"absolute"}}>&nbsp; -&nbsp;  <s>15%</s> (Pilot Price: 11.25%)</b></Typography>}  />
                     <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.18)}} value="Senior-level (6yrs - 10yrs)" control={<Radio  style={{color:"grey"}} />}  label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative",}}>Senior-level (6yrs - 10yrs)<b style={{color:"#636363",width:"15rem",position:"absolute"}}>&nbsp; -&nbsp;  <s>18%</s> (Pilot Price: 13.5%)</b></Typography>} />
                     <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.20)}} value="Lead/Manager/SME (11yrs - 20yrs.)" control={<Radio  style={{color:"grey"}} />}   label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative",}}>Lead/Manager/SME (11yrs - 20yrs.)<b style={{color:"#636363",width:"12rem",position:"absolute"}}>&nbsp; -&nbsp;  <s>20%</s> (Pilot Price: 15%)</b></Typography>}   />
                     <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.22)}} value="C Suite" control={<Radio  style={{color:"grey"}} />}   label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative",}}>C Suite<b style={{color:"#636363",width:"15rem",position:"absolute"}}>&nbsp; -&nbsp;  <s>22%</s> (Pilot Price: 16.5%)</b></Typography>} />
                     
                   </RadioGroup>
                 </FormControl>

                  <div style={{border:"1px solid lightgrey",padding:"1rem",paddingBottom:"3rem",paddingTop:"1rem",width:"95%",position:"relative",right:"1.5rem",display:"flex",justifyContent:"center",alignItems:"center",gap:"2.5rem",marginTop:"2rem"}}>
                    
                    {    <div style={{flex:"1",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start",gap:"0.5rem"}}>
                      <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      style={{backgroundColor:'transparent',padding:"0.2rem",border:"1px solid lightgrey",borderRadius:"0.5rem",width:"100%",margin:"0 auto",flex:"1"}}
                      spacing={{
                        xs: 1,
                        sm: 2,
                      }}
                    >
                     
                      <div onClick={()=>{setJobPostings(true)}} style={{cursor:"pointer",borderRadius:"0.5rem",backgroundColor:jobPostings?"#40255F":"transparent",color:jobPostings?"white":"#40255F",padding:"0.5rem",paddingLeft:"0.1rem",paddingRight:"0.1rem",width:"45%",textAlign:"center"}}>Salary</div>
                      <div onClick={()=>{setJobPostings(false)}} style={{cursor:"pointer",borderRadius:"0.5rem",backgroundColor:!jobPostings?"#40255F":"transparent",color:!jobPostings?"white":"#40255F",padding:"0.5rem",paddingLeft:"0.1rem",paddingRight:"0.1rem",width:"45%",textAlign:"center"}}>Hourly</div>
                     
                
                    
                    </Stack>
                    
                    
                    <div style={{display:"flex",gap:"2rem",position:"relative"}}>
                    <TextField
                      name=""
                      required
                      placeholder='$145,000'
                      value={salaryRangeStart}
                      onChange={(e) => {setSalaryRangeStart(e.target.value);
                        servicePercentage && servicePercentage > 0 ? setServiceFeeStart((e.target.value)*servicePercentage):setServiceFeeStart('')
                      }}
                      //style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          height:"0.2rem",
                          fontSize:"10px",
                          paddingLeft:"0.3rem"
                        },
                    }}
                      style={{width:"100%",backgroundColor:"white",height:"0.2rem"}}
                    />


               <StyledConnector style={{fontSize:"3rem",position:"absolute",top:"-1.2rem"}}>-</StyledConnector>
 
                 <TextField
                      name=""
                      required
                      placeholder='$155,000'
                      value={salaryRangeEnd}
                      onChange={(e) => {setSalaryRangeEnd(e.target.value);
                        servicePercentage ? setServiceFeeEnd((e.target.value)*servicePercentage):setServiceFeeEnd('')
                      }}
                      //style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          height:"0.2rem",
                          fontSize:"10px",
                          paddingLeft:"0.3rem"
                          
                        },
                    }}
                      style={{width:"100%",backgroundColor:"white",height:"0.2rem"}}
                    />


                    </div>
                  </div>
                    }
                    

                    <div style={{flex:"1",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start",gap:"0.9rem"}}>
                    <div  style={{cursor:"pointer",borderRadius:"0.5rem",backgroundColor:"#40255F",color:"white",padding:"0.5rem",textAlign:"center",}}>Service Fee</div>
                    
                    <div style={{display:"flex",gap:"2rem",position:"relative"}}>
                    <TextField
                      name=""
                      required
                      placeholder='$26,900'
                      value={serviceFeeStart}
                      onChange={(e) => setServiceFeeStart(e.target.value)}
                      //style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          height:"0.2rem",
                          fontSize:"10px",
                          paddingLeft:"0.5rem"
                          
                        },
                    }}
                      style={{width:"100%",backgroundColor:"white",height:"0.2rem"}}
                    />


               <StyledConnector style={{fontSize:"3rem",position:"absolute",top:"-1.2rem"}}>-</StyledConnector>
 
                 <TextField
                      name=""
                      required
                      placeholder='$27,100'
                      value={serviceFeeEnd}
                      onChange={(e) => setServiceFeeEnd(e.target.value)}
                      //style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          height:"0.2rem",
                          fontSize:"10px",
                          paddingLeft:"0.5rem"
                          
                        },
                    }}
                      style={{width:"100%",backgroundColor:"white",height:"0.2rem"}}
                    />


                    </div>
                    
                    </div>

                  </div>


                  </div>

                </Grid>




                <Grid item xs={12}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Where is the role located?*</b>
                  <div style={inputContainer}>
                  <FormControl style={{position:"relative",left:"-2.5rem",scale:"0.95"}}>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup  style={{color:"grey"}}
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel  onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US" control={<Radio   style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US  </Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, East Coast working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, East Coast working hours</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, West Coast working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, West Coast working hours </Typography>}/>
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, Midwest Coast working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, Midwest Coast working hours</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, South working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, South working hours </Typography>}/>
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, South West working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, South West working hours</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote world-wide" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote world-wide</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="50% Remote, 50% on-site (Hybrid)" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >50% Remote, 50% on-site (Hybrid)</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% On-site, please provide work address." control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% On-site, please provide work address.</Typography> }/>
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>


              

              </Grid>



     
      
              <Grid item container xs={6} spacing={0} style={{display:"flex",flexDirection:"row",gap:"0rem",position:"relative",left:"1rem"}}>
                
           
              <Grid item xs={12}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Type of Employment*</b>
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup  style={{color:"grey"}}
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                    
                     <FormControlLabel  onClick={(e)=>{setWorkStatus(e.target.value)}} value="full-time W-2 Employee" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans,sans-serif"}}>Full-time, W-2 Employee</Typography>} />
                     <FormControlLabel  onClick={(e)=>{setWorkStatus(e.target.value)}} value="full-time c2c" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans,sans-serif"}}>Full-time, C2C</Typography>} />
                     <FormControlLabel  onClick={(e)=>{setWorkStatus(e.target.value)}} value="part-time" control={<Radio   style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans,sans-serif"}}>Part-time</Typography>} />
                     {/*<FormControlLabel  onClick={(e)=>{setWorkStatus(e.target.value)}} value="other" control={<Radio   style={{color:"grey"}}/>} label="Other" />*/}
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>




                <Grid item xs={12} style={{marginTop:"-8.8rem"}}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>If this role is on-site, please provide the address of the location. If not, please add your Headquarters*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="address on site"
                      required
                      placeholder='123 Street name, City, ST, 12345'
                      value={addressOnSite}
                      onChange={(e) => setAddressOnSite(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans,sans-serif"
                          
                        },
                    }}
                      
                    />
                  </div>
                </Grid>



                <Grid item xs={12} style={{marginTop:"-10.3rem"}}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Name of Hiring Manager or Reporting Manager</b>
                  <div style={inputContainer}>
                    <TextField
                      name="hiring manager name"
                      required
                      placeholder='add answer here'
                      value={hiringManagerName}
                      onChange={(e) => setHiringManagerName(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans,sans-serif"
                          
                        },
                    }}
                    />
                  </div>
                </Grid>



                <Grid item xs={12} style={{marginTop:"-13.5rem"}}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>What's the team size for this role*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="team size"
                      required
                      placeholder='add answer here'
                      value={teamSize}
                      onChange={(e) => setTeamSize(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans,sans-serif"
                          
                        },
                    }}
                    />
                  </div>
                </Grid>




                <Grid item xs={12} style={{marginTop:"-16rem"}}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Why is this role available*</b>
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup style={{color:"grey"}}
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel  onClick={(e)=>{setRoleAvailabilityReason(e.target.value)}}  value="Resignation/Termination" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}}>Resignation/Termination</Typography>} />
                     <FormControlLabel onClick={(e)=>{setRoleAvailabilityReason(e.target.value)}}  value="Early Stages of Building a Team" control={<Radio  style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}}>Early Stages of Building a Team</Typography>}/>
                     <FormControlLabel onClick={(e)=>{setRoleAvailabilityReason(e.target.value)}}  value="Company Growth / New Work" control={<Radio   style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}}>Company Growth / New Work</Typography>} />
                     
                    
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>
              </Grid>
          </Grid>




          <Typography variant="h3" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
             The Interview Process
              </Typography>

              <Typography variant="body" gutterBottom style={{marginBottom:"2rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start"}}>
              Once you receive your batch of qualified candidates, what is the interview process for this role?
              </Typography>






              <Grid container spacing={6} direction="row">
                <Grid style={{marginTop:"2rem",marginBottom:"2rem"}} item xs={12}>
                <b style={{ fontSize: '17px', width: '60%',marginBottom:"2rem",position:"relative",top:"-0.4rem" }}>Description*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="description"
                    value={description}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"0.8rem",fontSize:"17px",fontFamily:"Arial",cursor:"pointer"}}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder=' Add Description here '
                    >
                    </textarea>

                  </div>
                </Grid>

             
              </Grid>



              <TableContainer sx={{py:"2rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black",fontSize:"1rem",position:"relative",bottom:"-1.3rem"}}>

                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}} >Type of Interview</TableCell>
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Duration</TableCell>
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Interview Panel Members</TableCell>
                          
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Team Availability</TableCell>
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows2.slice(0,1).map((row,index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                          >
                            <TableCell sx={{my:2, backgroundColor:"#e0dcdc",position:"relative"}}  scope="row">  
                            
                          {/*<TextField  
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif",
                                
                              },
                          }}
                            placeholder='team interview'
                            value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                            onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.3rem"}}
                          /> */}

                     <Select
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.5rem",width:"90%",  height:'2.7rem'}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="duration-label"
                           id="hi"
                           value={typeOfInterview1}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>type of interview</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setTypeOfInterview1(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>type of interview</MenuItem>   
                   <MenuItem  value={"Team Interview"}>Team Interview</MenuItem>
                   <MenuItem   value={"HR Interview"}>HR Interview</MenuItem>
                   <MenuItem   value={"1:1 interview"}>1:1 Interview</MenuItem>
                   
                 
                        
                         </Select>
                            


                            <Typography variant="body" style={{marginBottom:"-0.8rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem"}}>
                            Location
                            </Typography>
                    
                           { <TextField  
                            name="location of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif",
                                
                              },
                          }}
                            placeholder='location'
                            value={interviewLocation1}
                            onChange={(e)=>{setInterviewLocation1(e.target.value)}}
                           
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%"}}
                          /> }



                       {/* <Select
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%",height:"2.6rem"}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="location-label"
                           id="location-select"
                           value={classes}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>location</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setClasses(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>select</MenuItem>   
                   <MenuItem  value={"Virtual"}>Virtual</MenuItem>
                   <MenuItem   value={"Onsite"}>Onsite</MenuItem>
                  
                 
                        
                         </Select> */}
                              
                      
                            </TableCell>  



                            <TableCell sx={{my:2, backgroundColor:"#e0dcdc"}}  scope="row">  
                            
                          {/*  <TextField  
                              name="60 mins"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif",
                                  
                                },
                            }}
                              placeholder='duration'
                              value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                              onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.7rem"}}
                          /> */}



                 <Select
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.9rem",width:"90%",  height:'2.7rem'}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="duration-label"
                           id="hi"
                           value={duration1}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>duration</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setDuration1(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>duration</MenuItem>   
                   <MenuItem  value={"30 mins"}>30 mins</MenuItem>
                   <MenuItem   value={"60 mins"}>60 mins</MenuItem>
                   <MenuItem   value={"90 mins"}>90 mins</MenuItem>
                 
                        
                         </Select>
                              

                              </TableCell>


                            <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>
                    
                        
                            <TextField 
                           name="interview lead"
                           inputProps={{
                            style: {
                              cursor:"pointer",
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              
                            },
                        }}
                           placeholder='Tom Harris'
                           value={index=== 0 ?interviewLead1:(index === 1 ? interviewLead2:interviewLead3)}
                           onChange={(e) => {index=== 0 ?setInterviewLead1(e.target.value):(index === 1 ? setInterviewLead2(e.target.value):setInterviewLead3(e.target.value))}}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",position:"relative",top:"0.1rem"}}
                           />  

            <Typography variant="body" gutterBottom style={{marginBottom:"2rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem",position:"relative",top:"0.2rem"}}>
            Interview Lead
            </Typography>
                    
                    
                     {  row.interviewPanelMembers.slice(0,2).map((item,index2)=>(  
                            <TextField 
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                
                              },
                          }}
                            placeholder='Tom Harris'
                            value={index === 0?  (index2=== 0 ?interviewPanelMembers1:(index2 === 1 ? interviewPanelMembers2:interviewPanelMembers3)):
                              index === 1 ? (index2=== 0 ?interviewPanelMembers4:(index2 === 1 ? interviewPanelMembers5:interviewPanelMembers6)):
                               (index2=== 0 ?interviewPanelMembers7:(index2 === 1 ? interviewPanelMembers8:interviewPanelMembers9))

                            }
                            onChange={ (e)=>{

                              {index === 0?  (index2=== 0 ?setInterviewPanelMembers1(e.target.value):(index2 === 1 ? setInterviewPanelMembers2(e.target.value):setInterviewPanelMembers3(e.target.value))):
                                index === 1 ? (index2=== 0 ?setInterviewPanelMembers4(e.target.value):(index2 === 1 ? setInterviewPanelMembers5(e.target.value):setInterviewPanelMembers6(e.target.value))):
                                 (index2=== 0 ?setInterviewPanelMembers7(e.target.value):(index2 === 1 ? setInterviewPanelMembers8(e.target.value):setInterviewPanelMembers9(e.target.value)))

                              }
                            }

                            }
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",display:"flex",flexDirection:"column",marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  
                        )) }
                            </TableCell>

                         {/*
                            <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="interview lead"
                           inputProps={{
                            style: {
                              cursor:"pointer",
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              
                            },
                        }}
                           placeholder='Tom Harris'
                           value={index=== 0 ?interviewLead4:(index === 1 ? interviewLead5:interviewLead6)}
                           onChange={(e) => {index=== 0 ?setInterviewLead4(e.target.value):(index === 1 ? setInterviewLead5(e.target.value):setInterviewLead6(e.target.value))}}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                           />  
                           
                           </TableCell> 
                         */}


                           <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>
                          
                           <FormControl style={{position:"relative",top:"-0.6rem"}}>
                             <FormLabel id="demo-row-radio-buttons-group-label"><div style={{position:"relative",left:"-9px",display:"flex",gap:"6px",justifyContent:"center",width:"10rem",color:"black"}}>
                             <span style={{textDecoration:"underline"}}>Mon </span> 
                             <span style={{textDecoration:"underline"}}>Tue</span> 
                             <span style={{textDecoration:"underline"}}>Wed</span> 
                             <span style={{textDecoration:"underline"}}>Thu</span>   
                             <span style={{textDecoration:"underline"}}> Fri</span>  
                                 </div>
                                 </FormLabel>

                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  style={{display:"flex",justifyContent:"space-between",gap:"3px",width:"10rem"}}
                                >
                                  <FormControlLabel  style={{width:"1.5rem"}} value="monday"  onClick={(e)=>{if(availableDays1.includes(e.target.value)){setAvailableDays1(availableDays1.filter((item)=>(item !== e.target.value))) }else{setAvailableDays1([...availableDays1,e.target.value])} }} control={<Checkbox size="small"     />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="tuesday"  onClick={(e)=>{if(availableDays1.includes(e.target.value)){setAvailableDays1(availableDays1.filter((item)=>(item !== e.target.value))) }else{setAvailableDays1([...availableDays1,e.target.value])} }} control={<Checkbox size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="wednesday"  onClick={(e)=>{if(availableDays1.includes(e.target.value)){setAvailableDays1(availableDays1.filter((item)=>(item !== e.target.value))) }else{setAvailableDays1([...availableDays1,e.target.value])} }} control={<Checkbox size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="thursday"  onClick={(e)=>{if(availableDays1.includes(e.target.value)){setAvailableDays1(availableDays1.filter((item)=>(item !== e.target.value))) }else{setAvailableDays1([...availableDays1,e.target.value])} }} control={<Checkbox size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="friday"   onClick={(e)=>{if(availableDays1.includes(e.target.value)){setAvailableDays1(availableDays1.filter((item)=>(item !== e.target.value))) }else{setAvailableDays1([...availableDays1,e.target.value])} }}control={<Checkbox size="small" />}  />
                                  
                                </RadioGroup>
                               </FormControl>
       
                        <div style={{width:"15rem",position:"relative",top:"-1rem",display:"flex",flexDirection:"column"}}>
                         {/*1 */}  

                         <div style={{display:"flex", gap:"0.6rem"}}> 
                        <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityStart1:(index === 1?availabilityStart1: availabilityStart1)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityStart1(e.target.value):(index === 1?setAvailabilityStart1(e.target.value):setAvailabilityStart1(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityEnd1:(index === 1?availabilityEnd1: availabilityEnd1)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityEnd1(e.target.value):(index === 1?setAvailabilityEnd1(e.target.value):setAvailabilityEnd1(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          </div>

                     {/*2 */}  
                     <div style={{display:"flex", gap:"0.6rem"}}> 
                     <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityStart2:(index === 1?availabilityStart2: availabilityStart2)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityStart2(e.target.value):(index === 1?setAvailabilityStart2(e.target.value):setAvailabilityStart2(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  



                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>

                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityEnd2:(index === 1?availabilityEnd2: availabilityEnd2)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityEnd2(e.target.value):(index === 1?setAvailabilityEnd2(e.target.value):setAvailabilityEnd2(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  

                          </div>


                        {/*3 */}  
                        <div style={{display:"flex", gap:"0.6rem"}}> 
                        <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityStart3:(index === 1?availabilityStart3: availabilityStart3)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityStart3(e.target.value):(index === 1?setAvailabilityStart3(e.target.value):setAvailabilityStart3(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  



                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityEnd3:(index === 1?availabilityEnd3: availabilityEnd3)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityEnd3(e.target.value):(index === 1?setAvailabilityEnd3(e.target.value):setAvailabilityEnd3(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          </div>


                        </div>


                           </TableCell>

                          

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                </TableContainer>
                 {/*</div> */}
                 









                 {/* <div style={{padding:"1rem",border:"2px solid lightgray",borderRadius:"0.5rem",marginTop:"3rem",marginBottom:"3rem"}}> */}
               <Typography variant="h4" style={{ color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",textDecoration:"underline",position:"relative",top:"3.5rem"}}>
               Round 2
              </Typography>
              <TableContainer sx={{py:"2rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black",fontSize:"1rem",position:"relative",bottom:"-1.3rem"}}>

                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}} >Type of Interview</TableCell>
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Duration</TableCell>
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Interview Panel Members</TableCell>
                          
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Team Availability</TableCell>
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows2.slice(0,1).map((row,index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                          >
                            <TableCell sx={{my:2, backgroundColor:"#e0dcdc",position:"relative"}}  scope="row">  
                            
                          {/*<TextField  
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif",
                                
                              },
                          }}
                            placeholder='team interview'
                            value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                            onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.3rem"}}
                          /> */}

                     <Select
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.5rem",width:"90%",  height:'2.7rem'}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="duration-label"
                           id="hi"
                           value={typeOfInterview2}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>type of interview</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setTypeOfInterview2(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>type of interview</MenuItem>   
                   <MenuItem  value={"Team Interview"}>Team Interview</MenuItem>
                   <MenuItem   value={"HR Interview"}>HR Interview</MenuItem>
                   <MenuItem   value={"1:1 interview"}>1:1 Interview</MenuItem>
                        
                         </Select>
                            


                            <Typography variant="body" style={{marginBottom:"-0.8rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem"}}>
                            Location
                            </Typography>
                    
                           {<TextField  
                            name="location of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif",
                                
                              },
                          }}
                            placeholder='location'
                            value={interviewLocation2}
                            onChange={(e)=>{setInterviewLocation2(e.target.value)}}
                           
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%"}}
                          /> }



                       {/* <Select
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%",height:"2.6rem"}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="location-label"
                           id="location-select"
                           value={classes}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>location</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setClasses(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>select</MenuItem>   
                   <MenuItem  value={"Virtual"}>Virtual</MenuItem>
                   <MenuItem   value={"Onsite"}>Onsite</MenuItem>
                  
                 
                        
                         </Select> */}
                              
                      
                            </TableCell>  



                            <TableCell sx={{my:2, backgroundColor:"#e0dcdc"}}  scope="row">  
                            
                          {/*  <TextField  
                              name="60 mins"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif",
                                  
                                },
                            }}
                              placeholder='duration'
                              value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                              onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.7rem"}}
                          /> */}



                 <Select
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.9rem",width:"90%",  height:'2.7rem'}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="duration-label"
                           id="hi"
                           value={duration2}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>duration</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setDuration2(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>duration</MenuItem>   
                   <MenuItem  value={"30 mins"}>30 mins</MenuItem>
                   <MenuItem   value={"60 mins"}>60 mins</MenuItem>
                   <MenuItem   value={"90 mins"}>90 mins</MenuItem>
                 
                        
                         </Select>
                              

                              </TableCell>


                            <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>
                    
                        
                            <TextField 
                           name="interview lead"
                           inputProps={{
                            style: {
                              cursor:"pointer",
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              
                            },
                        }}
                           placeholder='Tom Harris'
                           value={index=== 0 ?interviewLead4:(index === 1 ? interviewLead5:interviewLead6)}
                           onChange={(e) => {index=== 0 ?setInterviewLead4(e.target.value):(index === 1 ? setInterviewLead5(e.target.value):setInterviewLead6(e.target.value))}}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",position:"relative",top:"0.1rem"}}
                           />  

            <Typography variant="body" gutterBottom style={{marginBottom:"2rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem",position:"relative",top:"0.2rem"}}>
            Interview Lead
            </Typography>
                    
                    
                     {  row.interviewPanelMembers.slice(0,2).map((item,index2)=>(  
                            <TextField 
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                
                              },
                          }}
                            placeholder='Tom Harris'
                            value={index === 0?  (index2=== 0 ?interviewPanelMembers3:(index2 === 1 ? interviewPanelMembers4:interviewPanelMembers5)):
                              index === 1 ? (index2=== 0 ?interviewPanelMembers4:(index2 === 1 ? interviewPanelMembers5:interviewPanelMembers6)):
                               (index2=== 0 ?interviewPanelMembers7:(index2 === 1 ? interviewPanelMembers8:interviewPanelMembers9))

                            }
                            onChange={ (e)=>{

                              {index === 0?  (index2=== 0 ?setInterviewPanelMembers3(e.target.value):(index2 === 1 ? setInterviewPanelMembers4(e.target.value):setInterviewPanelMembers5(e.target.value))):
                                index === 1 ? (index2=== 0 ?setInterviewPanelMembers4(e.target.value):(index2 === 1 ? setInterviewPanelMembers5(e.target.value):setInterviewPanelMembers6(e.target.value))):
                                 (index2=== 0 ?setInterviewPanelMembers7(e.target.value):(index2 === 1 ? setInterviewPanelMembers8(e.target.value):setInterviewPanelMembers9(e.target.value)))

                              }
                            }

                            }
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",display:"flex",flexDirection:"column",marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  
                        )) }
                            </TableCell>

                         {/*
                            <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="interview lead"
                           inputProps={{
                            style: {
                              cursor:"pointer",
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              
                            },
                        }}
                           placeholder='Tom Harris'
                           value={index=== 0 ?interviewLead4:(index === 1 ? interviewLead5:interviewLead6)}
                           onChange={(e) => {index=== 0 ?setInterviewLead4(e.target.value):(index === 1 ? setInterviewLead5(e.target.value):setInterviewLead6(e.target.value))}}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                           />  
                           
                           </TableCell> 
                         */}


                           <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>
                          
                           <FormControl style={{position:"relative",top:"-0.6rem"}}>
                             <FormLabel id="demo-row-radio-buttons-group-label"><div style={{position:"relative",left:"-9px",display:"flex",gap:"6px",justifyContent:"center",width:"10rem",color:"black"}}>
                             <span style={{textDecoration:"underline"}}>Mon </span> 
                             <span style={{textDecoration:"underline"}}>Tue</span> 
                             <span style={{textDecoration:"underline"}}>Wed</span> 
                             <span style={{textDecoration:"underline"}}>Thu</span>   
                             <span style={{textDecoration:"underline"}}> Fri</span>  
                                 </div>
                                 </FormLabel>

                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  style={{display:"flex",justifyContent:"space-between",gap:"3px",width:"10rem"}}
                                >
                                  <FormControlLabel  style={{width:"1.5rem"}} value="monday"  onClick={(e)=>{if(availableDays2.includes(e.target.value)){setAvailableDays2(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays2([...availableDays2,e.target.value])} }} control={<Checkbox size="small"     />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="tuesday" onClick={(e)=>{if(availableDays2.includes(e.target.value)){setAvailableDays2(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays2([...availableDays2,e.target.value])} }} control={<Checkbox size="small"     />}   />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="wednesday" onClick={(e)=>{if(availableDays2.includes(e.target.value)){setAvailableDays2(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays2([...availableDays2,e.target.value])} }} control={<Checkbox size="small"     />}   />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="thursday" onClick={(e)=>{if(availableDays2.includes(e.target.value)){setAvailableDays2(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays2([...availableDays2,e.target.value])} }} control={<Checkbox size="small"     />}    />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="friday" onClick={(e)=>{if(availableDays2.includes(e.target.value)){setAvailableDays2(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays2([...availableDays2,e.target.value])} }} control={<Checkbox size="small"     />}      />
                                  
                                </RadioGroup>
                               </FormControl>
       
                        <div style={{width:"15rem",position:"relative",top:"-1rem",display:"flex",flexDirection:"column"}}>
                         {/*1 */}  

                         <div style={{display:"flex", gap:"0.6rem"}}> 
                        <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityStart4:(index === 1?availabilityStart4: availabilityStart4)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityStart4(e.target.value):(index === 1?setAvailabilityStart4(e.target.value):setAvailabilityStart4(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityEnd4:(index === 1?availabilityEnd4: availabilityEnd4)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityEnd4(e.target.value):(index === 1?setAvailabilityEnd4(e.target.value):setAvailabilityEnd4(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          </div>

                     {/*2 */}  
                     <div style={{display:"flex", gap:"0.6rem"}}> 
                     <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityStart5:(index === 1?availabilityStart5: availabilityStart5)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityStart5(e.target.value):(index === 1?setAvailabilityStart5(e.target.value):setAvailabilityStart5(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  



                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>

                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityEnd5:(index === 1?availabilityEnd5: availabilityEnd5)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityEnd5(e.target.value):(index === 1?setAvailabilityEnd5(e.target.value):setAvailabilityEnd5(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  

                          </div>


                        {/*3 */}  
                        <div style={{display:"flex", gap:"0.6rem"}}> 
                        <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityStart6:(index === 1?availabilityStart6: availabilityStart6)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityStart6(e.target.value):(index === 1?setAvailabilityStart6(e.target.value):setAvailabilityStart6(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  



                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={index=== 0 ?availabilityEnd6:(index === 1?availabilityEnd6: availabilityEnd6)}
                            onChange={ (e)=>{index=== 0 ?setAvailabilityEnd6(e.target.value):(index === 1?setAvailabilityEnd6(e.target.value):setAvailabilityEnd6(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          </div>


                        </div>


                           </TableCell>

                          

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                </TableContainer>
                 {/*</div> */}


















                 {/* <div style={{padding:"1rem",border:"2px solid lightgray",borderRadius:"0.5rem",marginTop:"3rem",marginBottom:"3rem"}}> */}
               <Typography variant="h4" style={{ color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",textDecoration:"underline",position:"relative",top:"3.5rem"}}>
               Round 3
              </Typography>
                  <TableContainer sx={{py:"2rem", width: "100%"}} component={Paper}>
                    
                      <Table sx={{}} aria-label="simple table">
                        <TableHead>

                          <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black",fontSize:"1rem",position:"relative",bottom:"-1.3rem"}}>

                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}} >Type of Interview</TableCell>
                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Duration</TableCell>
                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Interview Panel Members</TableCell>
                            
                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Team Availability</TableCell>
                           
                           
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows2.slice(0,1).map((row,index) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                            >
                              <TableCell sx={{my:2, backgroundColor:"#e0dcdc",position:"relative"}}  scope="row">  
                              
                            {/*<TextField  
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif",
                                  
                                },
                            }}
                              placeholder='team interview'
                              value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                              onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.3rem"}}
                            /> */}

                       <Select
                             style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.5rem",width:"90%",  height:'2.7rem'}}
                            inputProps={{
                             classes: {
                                 icon: classes.icon,
                             },
                         }}
                           
                             labelId="duration-label"
                             id="hi"
                             value={typeOfInterview3}
                             //label="Classe et option"
                             displayEmpty
                             renderValue={(selected) => {
                               if (selected.length === 0) {
                                 return <em style={{color:"lightgray"}}>type of interview</em>;
                               }
                   
                               return selected;
                             }}
                             onChange={(event) => {
                               setTypeOfInterview3(event.target.value);
                             }}
                           >
                          
                     <MenuItem disabled value={""}>type of interview</MenuItem>   
                     <MenuItem  value={"Team Interview"}>Team Interview</MenuItem>
                     <MenuItem   value={"HR Interview"}>HR Interview</MenuItem>
                     <MenuItem   value={"1:1 interview"}>1:1 Interview</MenuItem>
                   
                          
                           </Select>
                              


                              <Typography variant="body" style={{marginBottom:"-0.8rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem"}}>
                              Location
                              </Typography>
                      
                             { <TextField  
                              name="location of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif",
                                  
                                },
                            }}
                              placeholder='location'
                              value={interviewLocation3}
                              onChange={(e)=>{setInterviewLocation3(e.target.value)}}
                             
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%"}}
                            /> }



                         {/* <Select
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%",height:"2.6rem"}}
                            inputProps={{
                             classes: {
                                 icon: classes.icon,
                             },
                         }}
                           
                             labelId="location-label"
                             id="location-select"
                             value={classes}
                             
                             displayEmpty
                             renderValue={(selected) => {
                               if (selected.length === 0) {
                                 return <em style={{color:"lightgray"}}>location</em>;
                               }
                   
                               return selected;
                             }}
                             onChange={(event) => {
                               setClasses(event.target.value);
                             }}
                           >
                          
                     <MenuItem disabled value={""}>select</MenuItem>   
                     <MenuItem  value={"Virtual"}>Virtual</MenuItem>
                     <MenuItem   value={"Onsite"}>Onsite</MenuItem>
                    
                   
                          
                            </Select>*/ }
                                
                        
                              </TableCell>  



                              <TableCell sx={{my:2, backgroundColor:"#e0dcdc"}}  scope="row">  
                              
                            {/*  <TextField  
                                name="60 mins"
                                inputProps={{
                                  style: {
                                    cursor:"pointer",
                                    height:'0.6rem'
                                    ,fontFamily:"Public Sans, sans-serif",
                                    
                                  },
                              }}
                                placeholder='duration'
                                value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                                onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                                style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.7rem"}}
                            /> */}



                   <Select
                             style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.9rem",width:"90%",  height:'2.7rem'}}
                            inputProps={{
                             classes: {
                                 icon: classes.icon,
                             },
                         }}
                           
                             labelId="duration-label"
                             id="hi"
                             value={duration3}
                             //label="Classe et option"
                             displayEmpty
                             renderValue={(selected) => {
                               if (selected.length === 0) {
                                 return <em style={{color:"lightgray"}}>duration</em>;
                               }
                   
                               return selected;
                             }}
                             onChange={(event) => {
                               setDuration3(event.target.value);
                             }}
                           >
                          
                     <MenuItem disabled value={""}>duration</MenuItem>   
                     <MenuItem  value={"30 mins"}>30 mins</MenuItem>
                     <MenuItem   value={"60 mins"}>60 mins</MenuItem>
                     <MenuItem   value={"90 mins"}>90 mins</MenuItem>
                   
                          
                           </Select>
                                
  
                                </TableCell>


                              <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>
                      
                          
                              <TextField 
                             name="interview lead"
                             inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                
                              },
                          }}
                             placeholder='Tom Harris'
                             value={index=== 0 ?interviewLead7:(index === 1 ? interviewLead8:interviewLead9)}
                             onChange={(e) => {index=== 0 ?setInterviewLead7(e.target.value):(index === 1 ? setInterviewLead8(e.target.value):setInterviewLead9(e.target.value))}}
                             style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",position:"relative",top:"0.1rem"}}
                             />  

              <Typography variant="body" gutterBottom style={{marginBottom:"2rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem",position:"relative",top:"0.2rem"}}>
              Interview Lead
              </Typography>
                      
                      
                       {  row.interviewPanelMembers.slice(0,2).map((item,index2)=>(  
                              <TextField 
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                  
                                },
                            }}
                              placeholder='Tom Harris'
                              value={index === 0?  (index2=== 0 ?interviewPanelMembers5:(index2 === 1 ? interviewPanelMembers6:interviewPanelMembers7)):
                                index === 1 ? (index2=== 0 ?interviewPanelMembers4:(index2 === 1 ? interviewPanelMembers5:interviewPanelMembers6)):
                                 (index2=== 0 ?interviewPanelMembers7:(index2 === 1 ? interviewPanelMembers8:interviewPanelMembers9))

                              }
                              onChange={ (e)=>{

                                {index === 0?  (index2=== 0 ?setInterviewPanelMembers5(e.target.value):(index2 === 1 ? setInterviewPanelMembers6(e.target.value):setInterviewPanelMembers7(e.target.value))):
                                  index === 1 ? (index2=== 0 ?setInterviewPanelMembers4(e.target.value):(index2 === 1 ? setInterviewPanelMembers5(e.target.value):setInterviewPanelMembers6(e.target.value))):
                                   (index2=== 0 ?setInterviewPanelMembers7(e.target.value):(index2 === 1 ? setInterviewPanelMembers8(e.target.value):setInterviewPanelMembers9(e.target.value)))
  
                                }
                              }

                              }
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",display:"flex",flexDirection:"column",marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  
                          )) }
                              </TableCell>

                           {/*
                              <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                             <TextField 
                             name="interview lead"
                             inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                
                              },
                          }}
                             placeholder='Tom Harris'
                             value={index=== 0 ?interviewLead4:(index === 1 ? interviewLead5:interviewLead6)}
                             onChange={(e) => {index=== 0 ?setInterviewLead4(e.target.value):(index === 1 ? setInterviewLead5(e.target.value):setInterviewLead6(e.target.value))}}
                             style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                             />  
                             
                             </TableCell> 
                           */}
 

                             <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>
                            
                             <FormControl style={{position:"relative",top:"-0.6rem"}}>
                               <FormLabel id="demo-row-radio-buttons-group-label"><div style={{position:"relative",left:"-9px",display:"flex",gap:"6px",justifyContent:"center",width:"10rem",color:"black"}}>
                               <span style={{textDecoration:"underline"}}>Mon </span> 
                               <span style={{textDecoration:"underline"}}>Tue</span> 
                               <span style={{textDecoration:"underline"}}>Wed</span> 
                               <span style={{textDecoration:"underline"}}>Thu</span>   
                               <span style={{textDecoration:"underline"}}> Fri</span>  
                                   </div>
                                   </FormLabel>

                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    style={{display:"flex",justifyContent:"space-between",gap:"3px",width:"10rem"}}
                                  >
                                    <FormControlLabel  style={{width:"1.5rem"}} value="monday" onClick={(e)=>{if(availableDays3.includes(e.target.value)){setAvailableDays3(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays3([...availableDays3,e.target.value])} }} control={<Checkbox size="small"     />}  />
                                    <FormControlLabel  style={{width:"1.5rem"}} value="tuesday" onClick={(e)=>{if(availableDays3.includes(e.target.value)){setAvailableDays3(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays3([...availableDays3,e.target.value])} }} control={<Checkbox size="small" />}  />
                                    <FormControlLabel  style={{width:"1.5rem"}} value="wednesday" onClick={(e)=>{if(availableDays3.includes(e.target.value)){setAvailableDays3(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays3([...availableDays3,e.target.value])} }} control={<Checkbox size="small" />}  />
                                    <FormControlLabel  style={{width:"1.5rem"}} value="thursday" onClick={(e)=>{if(availableDays3.includes(e.target.value)){setAvailableDays3(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays3([...availableDays3,e.target.value])} }} control={<Checkbox size="small" />}  />
                                    <FormControlLabel  style={{width:"1.5rem"}} value="friday" onClick={(e)=>{if(availableDays3.includes(e.target.value)){setAvailableDays3(availableDays2.filter((item)=>(item !== e.target.value))) }else{setAvailableDays3([...availableDays3,e.target.value])} }} control={<Checkbox size="small" />}  />
                                    
                                  </RadioGroup>
                                 </FormControl>
         
                          <div style={{width:"15rem",position:"relative",top:"-1rem",display:"flex",flexDirection:"column"}}>
                           {/*1 */}  

                           <div style={{display:"flex", gap:"0.6rem"}}> 
                          <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                              value={index=== 0 ?availabilityStart7:(index === 1?availabilityStart7: availabilityStart7)}
                              onChange={ (e)=>{index=== 0 ?setAvailabilityStart7(e.target.value):(index === 1?setAvailabilityStart7(e.target.value):setAvailabilityStart7(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  


                            <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                            <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                              value={index=== 0 ?availabilityEnd7:(index === 1?availabilityEnd7: availabilityEnd7)}
                              onChange={ (e)=>{index=== 0 ?setAvailabilityEnd7(e.target.value):(index === 1?setAvailabilityEnd7(e.target.value):setAvailabilityEnd7(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  


                            </div>

                       {/*2 */}  
                       <div style={{display:"flex", gap:"0.6rem"}}> 
                       <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                              value={index=== 0 ?availabilityStart8:(index === 1?availabilityStart8: availabilityStart8)}
                              onChange={ (e)=>{index=== 0 ?setAvailabilityStart8(e.target.value):(index === 1?setAvailabilityStart8(e.target.value):setAvailabilityStart8(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  



                            <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>

                            <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                              value={index=== 0 ?availabilityEnd8:(index === 1?availabilityEnd8: availabilityEnd8)}
                              onChange={ (e)=>{index=== 0 ?setAvailabilityEnd8(e.target.value):(index === 1?setAvailabilityEnd8(e.target.value):setAvailabilityEnd8(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  

                            </div>


                          {/*3 */}  
                          <div style={{display:"flex", gap:"0.6rem"}}> 
                          <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                              value={index=== 0 ?availabilityStart9:(index === 1?availabilityStart9: availabilityStart9)}
                              onChange={ (e)=>{index=== 0 ?setAvailabilityStart9(e.target.value):(index === 1?setAvailabilityStart9(e.target.value):setAvailabilityStart9(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  



                            <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                            <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                              value={index=== 0 ?availabilityEnd9:(index === 1?availabilityEnd9: availabilityEnd9)}
                              onChange={ (e)=>{index=== 0 ?setAvailabilityEnd9(e.target.value):(index === 1?setAvailabilityEnd9(e.target.value):setAvailabilityEnd9(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  


                            </div>


                          </div>


                             </TableCell>

                            

                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                  </TableContainer>
                 {/*</div> */}
                 




          




              <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
              Candidate Information
            </Typography>

            <Typography variant="body2" gutterBottom style={{display:"flex",justifyContent:"flex-start"}}>
             What is your ideal candidate for this role? What skills are you willing to train and what are skills they must have day one
            </Typography>


            <Grid container spacing={6} direction="row">
                <Grid style={{marginTop:"2rem" ,display:"flex",flexDirection:"column",gap:"1.5rem" ,fontFamily:"Public sans, sans-serif",}} item xs={6}>
                <b style={{ position:"relative",top:"0.7rem",  width: '60%' }}>Must have skills*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={essentialSkills}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",fontSize:"1.1rem",paddingLeft:"1rem",paddingTop:"1rem"}}
                    onChange={(e) => setEssentialSkills(e.target.value)}
                    placeholder=' Add answer here '
                    >
                    </textarea>

                  </div>
                </Grid>

                

                <Grid style={{marginTop:"2rem" ,display:"flex",flexDirection:"column",gap:"1.5rem",fontFamily:"Public sans, sans-serif",  }} item xs={6}>
                <b style={{ position:"relative",top:"0.7rem" , width: '60%' }}>Flexible skills, willing to train*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={nonEssentialSkills}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"0.8rem",fontSize:"1.1rem"}}
                    onChange={(e) => setNonEssentialSkills(e.target.value)}
                    placeholder=' Add answer here'
                    >
                    </textarea>

                  </div>
                </Grid>
              </Grid>



     
      
              <Grid container spacing={6} direction="row">
                <Grid style={{marginTop:"2rem" ,display:"flex",flexDirection:"column",gap:"1.5rem",fontFamily:"Public sans, sans-serif", }} item xs={6}>
                <b style={{  width: '60%',position:"relative",top:"0.7rem" ,position:"relative",top:"0.7rem"  }}>What type of person would be successful in this role?*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={successfulPersonDesc}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"0.8rem",fontSize:"1.1rem"}}
                    onChange={(e) => setSuccessfulPersonDesc(e.target.value)}
                    placeholder=' Add answer here '
                    >
                    </textarea>

                  </div>
                </Grid>

                <Grid style={{display:"flex",flexDirection:"column",gap:"1.5rem",marginTop:"2rem",fontFamily:"Public sans, sans-serif",}} item xs={6}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>How many openings of this role do you need filled?*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder=''
                      value={numberOfOpenings}
                      onChange={(e) => setNumberOfOpenings(e.target.value)}
                      //style={inputStyle}
                      style={{width:"100%",backgroundColor:"white"}}
                      InputProps={{
                        style:{
                          fontFamily:"Public sans, sans-serif",
                          backgroundColor:"white"
                        }
                      }}
                    />
                  </div>


                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem" }}>Is this role posted on any job board or shared with any staffing entity?*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder=''
                      value={sharedElseWhere}
                      onChange={(e) => setSharedElseWhere(e.target.value)}
                      //style={inputStyle}
                      style={{width:"100%",backgroundColor:"white"}}
                      InputProps={{
                        style:{
                          fontFamily:"Public sans, sans-serif",
                          backgroundColor:"white"
                        }
                      }}
                    />
                  </div>


             <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mt: 5,mb:1,color:"black" }}>
                <Typography style={{fontFamily:"Public Sans, sans-serif",}}>
                Please Upload Job Description*
                </Typography>
             </Stack>
              {/* <center style={{marginTop:"0rem"}}>
                  <LoadingButton  sx={{bgcolor:"black",fontFamily:"Public Sans, sans-serif",}}  size="large" type="submit" variant="contained" disabled={loading}>
                    {loading ? "Loading..." : "Upload File"}
                  </LoadingButton>
                    </center> */}



               <center style={{marginTop:"0rem"}}>
                  <Button component="label"  sx={{bgcolor:"#40255F"}}  size="large" type="button" variant="contained" disabled={loading}>
                    
                    <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleselectedFile}
                   />
                   { "Upload File"}
                  </Button>

                  <div style={{marginTop:"1rem"}}>{selectedFile.selectedFileName}</div>
               </center>




                </Grid>
              </Grid>











  
              



      <center style={{marginTop:"6rem"}}>
      <LoadingButton  sx={{bgcolor:"#40255F" ,fontFamily:"Public Sans, sans-serif",}}  size="large" type="submit" variant="contained" disabled={loading}>
        {loading ? "Loading..." : "Post Job"}
      </LoadingButton>
      </center>

      </form>


      

   
     

      {/*<Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 1,color:"gray" }}>
        <Typography>
        Thanks for Submitting
        </Typography>
      </Stack>*/}




    </div>
  );
}
