import { db, fb, auth, storage } from '../../config/firebase';
import { clearUser, loginFailed, loginSuccess, logoutFxn, signupFailed, storeUserData } from '../reducers/auth.slice';
import { v4 as uuidv4 } from 'uuid';
import { notifyErrorFxn, notifySuccessFxn } from 'src/utils/toast-fxn';
import { clearCandidatesPendingInterview, clearCandidatesPendingOffer, clearCandidatesPendingReview, clearJobsPendingInterview, clearJobsPendingOffer, clearJobsPendingReview, isItLoading, saveAllClosedJobs, saveAllGroup, saveAllJobs, saveAllJobsRecruiter, saveCandidateInFocus, saveCandidatesAcceptedOffer, saveCandidatesPendingInterview, saveCandidatesPendingOffer, saveCandidatesPendingReview, saveEmployeer, saveGroupMembers, saveJobCandidates, saveJobInFocus, saveJobsAcceptedOffer, saveJobsPendingInterview, saveJobsPendingOffer, saveJobsPendingReview, saveMyGroup, savePrivateGroup, savePublicGroup } from '../reducers/group.slice';
import firebase from "firebase/app";
//import sgMail from '@sendgrid/mail'
import welcomeEmailTemplate from 'src/config/welcomeEmailTemplate';
import axios from 'axios';





export const createJob = (groupData,/* user, file,*/ navigate, setLoading, /*url*/) => async (dispatch) => {
 // //console.log("WE HAVE ENTERED CREATE JOBS FUNCTION-->")
   dispatch(isItLoading(true))

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  var today  = new Date();
  let jobCreatedId;




  db.collection("jobs").add({
    jobRole: groupData.jobRole,
    businessName: groupData.businessName,
    companyEmail:groupData.companyEmail,
    companyWebsite:groupData.companyWebsite,
    companyPhone:groupData.companyPhone,
    recruiterName:'',
    candidates:[],
    interviews:[],
    recruiterIds:[],
    pendingReview:[],
    pendingInterview:[],
    pendingOfferLetter:[],
    messages:[],
    jobNumber:/*groupData.jobNumber*/"none",
    jobType:/*groupData.jobType*/"none",
    jobResponsibilities:["none in this form"],
    salary:groupData.salary,
    active: true,
    skillLevel:groupData.skillLevel,
    typeOfRemoteWork:groupData.typeOfRemoteWork,
    workStatus:groupData.workStatus,
    addressOnSite:groupData.addressOnSite,
    hiringManagerName:groupData.hiringManagerName,
    teamSize:groupData.teamSize,
    roleAvailabilityReason:groupData.roleAvailabilityReason,
    interviewRound1:{
      typeOfInterview:groupData.interviewRound1.typeOfInterview,
      duration:groupData.interviewRound1.duration,
      interviewLocation:groupData.interviewRound1.interviewLocation,
      interviewLead:groupData.interviewRound1.interviewLead,
      interviewPanelMember1:groupData.interviewRound1.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound1.interviewPanelMember2,
      availabilityStart1:groupData.interviewRound1.availabilityStart1,
      availabilityEnd1: groupData.interviewRound1.availabilityEnd1,
      availabilityStart2: groupData.interviewRound1.availabilityStart2,
      availabilityEnd2:groupData.interviewRound1.availabilityEnd2,
      availabilityStart3:groupData.interviewRound1.availabilityStart3,
      availabilityEnd3:groupData.interviewRound1.availabilityEnd3,
      availableDays:groupData.interviewRound1.availableDays,
  
      },
    interviewRound2:{
  
      typeOfInterview:groupData.interviewRound2.typeOfInterview,
      duration:groupData.interviewRound2.duration,
      interviewLocation:groupData.interviewRound2.interviewLocation,
      interviewLead:groupData.interviewRound2.interviewLead,
      interviewPanelMember1:groupData.interviewRound2.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound2.interviewPanelMember2,
      availabilityStart1:groupData.interviewRound2.availabilityStart1,
      availabilityEnd1: groupData.interviewRound2.availabilityEnd1,
      availabilityStart2: groupData.interviewRound2.availabilityStart2,
      availabilityEnd2:groupData.interviewRound2.availabilityEnd2,
      availabilityStart3:groupData.interviewRound2.availabilityStart3,
      availabilityEnd3:groupData.interviewRound2.availabilityEnd3,
      availableDays:groupData.interviewRound2.availableDays,

    },
    interviewRound3:{

      
      typeOfInterview:groupData.interviewRound3.typeOfInterview,
      duration:groupData.interviewRound3.duration,
      interviewLocation:groupData.interviewRound3.interviewLocation,
      interviewLead:groupData.interviewRound3.interviewLead,
      interviewPanelMember1:groupData.interviewRound3.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound3.interviewPanelMember2,
      availabilityStart1:groupData.interviewRound3.availabilityStart1,
      availabilityEnd1: groupData.interviewRound3.availabilityEnd1,
      availabilityStart2: groupData.interviewRound3.availabilityStart2,
      availabilityEnd2:groupData.interviewRound3.availabilityEnd2,
      availabilityStart3:groupData.interviewRound3.availabilityStart3,
      availabilityEnd3:groupData.interviewRound3.availabilityEnd3,
      availableDays:groupData.interviewRound3.availableDays,

    },
    essentialSkills:groupData.essentialSkills,
    nonEssentialSkills:groupData.nonEssentialSkills,
    successfulPersonDesc:groupData.successfulPersonDesc,
    numberOfOpenings:groupData.numberOfOpenings,
    sharedElseWhere:groupData.sharedElseWhere,
    jobCreatedDate: today.toLocaleDateString("en-US", options),

}).then(async(res)=>{
   // //console.log("RESPONSE ID: ", res.id);
 jobCreatedId = res.id

    fb.auth().createUserWithEmailAndPassword(
      groupData.companyEmail,
      'efG9dL'
      /*res.id.slice(0,6).toString(),*/ /*"123456"*/
  ).then((userResponse)=>{


    db.collection("businesses").doc(userResponse.user.uid).set({
   
      businessName: groupData.businessName,
      companyEmail:groupData.companyEmail,
      companyWebsite:groupData.companyWebsite,
      companyPhone:groupData.companyPhone,
      uid:userResponse.user.uid,
      id:userResponse.user.uid,
    
    })/*.then((res)=>{
        return db.collection('businesses').doc(res.id).update({
         
          uid:res.id,
          id:res.id,
        })
      })*/

      db.collection("jobs").doc(jobCreatedId && jobCreatedId).update({
        businessId:userResponse.user.uid,
      })



    //ADDING ROUND 1 TO INTERVIEW ROUNDS COLLECTION
    db.collection("interviewRounds").add({
      roundName:"round 1",
      businessId:userResponse.user.uid,
      jobId:jobCreatedId && jobCreatedId,
      typeOfInterview:groupData.interviewRound1.typeOfInterview,
      duration:groupData.interviewRound1.duration,
      interviewLocation:groupData.interviewRound1.interviewLocation,
      interviewLead:groupData.interviewRound1.interviewLead,
      interviewPanelMember1:groupData.interviewRound1.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound1.interviewPanelMember2,
      availabilityStart1:groupData.interviewRound1.availabilityStart1,
      availabilityEnd1: groupData.interviewRound1.availabilityEnd1,
      availabilityStart2: groupData.interviewRound1.availabilityStart2,
      availabilityEnd2:groupData.interviewRound1.availabilityEnd2,
      availabilityStart3:groupData.interviewRound1.availabilityStart3,
      availabilityEnd3:groupData.interviewRound1.availabilityEnd3,
      availableDays:groupData.interviewRound1.availableDays,
     

    }).then((respo)=>{

      db.collection("interviewRounds").doc(respo.id).update({
        uid:respo.id,
        id:respo.id

      })

    })

//ADDING ROUND 2 TO INTERVIEW ROUNDS COLLECTION
    db.collection("interviewRounds").add({
      roundName:"round 2",
      businessId:userResponse.user.uid,
      jobId:jobCreatedId && jobCreatedId,
      typeOfInterview:groupData.interviewRound2.typeOfInterview,
      duration:groupData.interviewRound2.duration,
      interviewLocation:groupData.interviewRound2.interviewLocation,
      interviewLead:groupData.interviewRound2.interviewLead,
      interviewPanelMember1:groupData.interviewRound2.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound2.interviewPanelMember2,
      availabilityStart1:groupData.interviewRound2.availabilityStart1,
      availabilityEnd1: groupData.interviewRound2.availabilityEnd1,
      availabilityStart2: groupData.interviewRound2.availabilityStart2,
      availabilityEnd2:groupData.interviewRound2.availabilityEnd2,
      availabilityStart3:groupData.interviewRound2.availabilityStart3,
      availabilityEnd3:groupData.interviewRound2.availabilityEnd3,
      availableDays:groupData.interviewRound2.availableDays,
     

    }).then((respo)=>{

      db.collection("interviewRounds").doc(respo.id).update({
        uid:respo.id,
        id:respo.id

      })

    })


//ADDING ROUND 3 TO INTERVIEW ROUNDS COLLECTION
    db.collection("interviewRounds").add({
      roundName:"round 3",
      businessId:userResponse.user.uid,
      jobId:jobCreatedId && jobCreatedId,
      typeOfInterview:groupData.interviewRound3.typeOfInterview,
      duration:groupData.interviewRound3.duration,
      interviewLocation:groupData.interviewRound3.interviewLocation,
      interviewLead:groupData.interviewRound3.interviewLead,
      interviewPanelMember1:groupData.interviewRound3.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound3.interviewPanelMember2,
      availabilityStart1:groupData.interviewRound3.availabilityStart1,
      availabilityEnd1: groupData.interviewRound3.availabilityEnd1,
      availabilityStart2: groupData.interviewRound3.availabilityStart2,
      availabilityEnd2:groupData.interviewRound3.availabilityEnd2,
      availabilityStart3:groupData.interviewRound3.availabilityStart3,
      availabilityEnd3:groupData.interviewRound3.availabilityEnd3,
      availableDays:groupData.interviewRound3.availableDays,
     

    }).then((respo)=>{

      db.collection("interviewRounds").doc(respo.id).update({
        uid:respo.id,
        id:respo.id

      })

    })



//sgMail.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY)

const msg = {
  to: `${groupData.companyEmail}`,
  from: `contactus@one-recruiter.com` ,
  subject:"One Recruiter - Business Account Setup",
  text:"Welcome to One Recruiter",
  html:welcomeEmailTemplate,

}

//sgMail.send(msg)

 axios.post(/*'https://sendgridserver-seven.vercel.app/api/om/welcome'*/'http://localhost:5008/api/om/welcome', {
  message:JSON.stringify(msg)
  })
.then(()=>{/*console.log("Message sent")*/})
.catch((error)=>{
  console.error("error sending msg",error)
})

   /* fb THIS ONE WAS IN USE BEFORE I INTRODUCED SENDGRID
    .auth()
    .sendPasswordResetEmail(groupData.companyEmail)*/
  })
     

   /* return*/ db.collection('jobs').doc(res.id).update({
      jobId: res.id,
      uid:res.id,
      id:res.id,
    }).then(() => {
   
    
     // sgMail.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY)

      const msg = {
        to: `${groupData.companyEmail}`,
        from: `contactus@one-recruiter.com` ,
        subject:"One Recruiter - Business Account Setup",
        text:"Welcome to One Recruiter",
        html:welcomeEmailTemplate,
      
      }


  //sgMail.send(msg)
      
      axios.post(/*'https://sendgridserver-seven.vercel.app/api/om/welcome'*/'http://localhost:5008/api/om/welcome', {
        message:JSON.stringify(msg)
        })
      .then(()=>{
        
        ////console.log("Message sent")
      })
      .catch((error)=>{
       // console.error("error sending msg",error)
      })
        


   /* fb THIS ONE WAS IN USE B4 I INTRODUCED SENDGRID
    .auth()
    .sendPasswordResetEmail(groupData.companyEmail)*/



        db.collection('jobs').doc(res.id).collection('appliedCandidatesCollection').add({
             quickBlock:"not using this colection for now"
           /*memberName: user.name,
            memberEmail: user.email,
            memberImageUrl: user.profileImg,
            invitedBy: user.id,
            invite: 0,
            paid: 0,
            users: [user.id, user.id],
            sentAt: today.toLocaleDateString("en-US", options),*/
          }).then((resp) => {
            ////console.log("membersCollection RESPONSE: ", resp);
            setLoading(false);
            db.collection('jobs').doc(res.id).collection('appliedCandidatesCollection').doc(resp.id).delete({
              /*id: resp.id,*/
            })
      
             
       /* fb
            .auth()
            .sendPasswordResetEmail(groupData.companyEmail)*/
        
          }).then(()=>{
          /*  fb
            .auth()
            .sendPasswordResetEmail(groupData.companyEmail)*/
         
         
          })
          .then(() => {
            dispatch(isItLoading(false))
            notifySuccessFxn("Job Created")
            setLoading(false);
            
            navigate('/how-it-works', { replace: true });
          }).catch((err) => {
            console.error("Error creating job: ", err);
            var errorMessage = err.message;
            notifyErrorFxn(errorMessage);
            setLoading(false);
            
          })
    })
  }).catch((err) => {
    console.error("Error creating job: ", err);
    var errorMessage = err.message;
    notifyErrorFxn("Please try again with a different email address");
    setLoading(false);
  })
}

export const closeJob = (jobId,businessId) => async (dispatch) => {
return db.collection('jobs').doc(jobId).update({
         
 jobStatus:"closed"
}).then((res)=>{
dispatch(fetchAllJobsForMyBusiness(businessId))
notifySuccessFxn("Job Closed Successfully!")
})


}


export const fetchJobInFocus = (jobId) => async (dispatch) => {


  return db.collection('jobs').doc(jobId).get(
         
   
   ).then((doc)=>{
   dispatch(saveJobInFocus(doc.data()))
 
   })
   

}



export const updateCandidateToSelectionReviewed = (candidateId) => async (dispatch) => {


  db.collection('candidates').doc(candidateId).update({
    selectionReviewed:true
  })
  .then((res)=>{  

  return db.collection('candidates').doc(candidateId).get(
         
   
   ).then((doc)=>{
   dispatch(saveCandidateInFocus(doc.data()))
 
   })
  
  }).catch((err) => {
    console.error("Error updating candidate to offer reviewed: ", err);
    var errorMessage = err.message;
   
  })

}



export const updateCandidateToOfferReviewed = (candidateId) => async (dispatch) => {

  db.collection('candidates').doc(candidateId).update({
    offerReviewed:true
  })
  .then((res)=>{  

  return db.collection('candidates').doc(candidateId).get(
         
   
   ).then((doc)=>{
   dispatch(saveCandidateInFocus(doc.data()))
 
   })
  
  }).catch((err) => {
    console.error("Error updating candidate to offer reviewed: ", err);
    var errorMessage = err.message;
   
  })

}



export const updateCandidateVideoRecordingRound1 = (candidateId,newLink) => async (dispatch) => {

  db.collection('candidates').doc(candidateId).update({
    videoRecordingRound1:newLink
  })
  .then((res)=>{  

  return db.collection('candidates').doc(candidateId).get(
         
   
   ).then((doc)=>{
   dispatch(saveCandidateInFocus(doc.data()))
  notifySuccessFxn("ROund 1 Recording Successfully Updated!")
   })
  
  }).catch((err) => {
    console.error("Error updating candidate's video for ROUND 1: ", err);
    var errorMessage = err.message;
   
  })

}


export const updateCandidateVideoRecordingRound2 = (candidateId,newLink) => async (dispatch) => {

  db.collection('candidates').doc(candidateId).update({
    videoRecordingRound2:newLink
  })
  .then((res)=>{  

  return db.collection('candidates').doc(candidateId).get(
         
   
   ).then((doc)=>{
   dispatch(saveCandidateInFocus(doc.data()))
   notifySuccessFxn("ROund 2 Recording Successfully Updated!")
 
   })
  
  }).catch((err) => {
    console.error("Error updating candidate's video for ROUND 2: ", err);
    var errorMessage = err.message;
   
  })

}



export const updateCandidateVideoRecordingRound3 = (candidateId,newLink) => async (dispatch) => {

  db.collection('candidates').doc(candidateId).update({
    videoRecordingRound3:newLink
  })
  .then((res)=>{  

  return db.collection('candidates').doc(candidateId).get(
         
   
   ).then((doc)=>{
   dispatch(saveCandidateInFocus(doc.data()))
   notifySuccessFxn("ROund 3 Recording Successfully Updated!")
 
   })
  
  }).catch((err) => {
    console.error("Error updating candidate's video for ROUND 3: ", err);
    var errorMessage = err.message;
   
  })

}




export const uploadJobDescription = (userData, file,  navigate, setLoading) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
  ////console.log('File Name: ', imageName);
  const uploadTask = storage.ref(`job_desc/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
      ////console.log(error);
    },
    () => {
      storage
        .ref("job_desc")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
         // //console.log('Image URL: ', url);
          dispatch(createGroup(userData,/* user, file*/navigate, setLoading, url));
        });
    }
  );
}



export const createGroup = (groupData,/* user, file,*/ navigate, setLoading, url) => async (dispatch) => {
 // //console.log("WE HAVE ENTERED CREATE JOBS FUNCTION-->")
 
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  var today  = new Date();
  
  dispatch(isItLoading(true))

  db.collection("jobs").add({
    jobRole: groupData.jobRole,
    businessName: groupData.businessName,
    businessId:groupData.businessId,
    recruiterName:'',
    candidates:[],
    interviews:[],
    recruiterIds:[],
    pendingReview:[],
    pendingInterview:[],
    pendingOfferLetter:[],
    messages:[],
    jobNumber:/*groupData.jobNumber*/"00",
    jobType:/*groupData.jobType*/"basic",
    //jobResponsibilities:[groupData.jobResponsibilities],
    salary:groupData.salary,
    active: true,
    skillLevel:groupData.skillLevel,
    sourceCandidates:groupData.sourceCandidates,
    postJobExternally:groupData.postJobExternally,
    typeOfRemoteWork:groupData.typeOfRemoteWork,
    workStatus:groupData.workStatus,
    addressOnSite:groupData.addressOnSite,
    hiringManagerName:groupData.hiringManagerName,
    teamSize:groupData.teamSize,
    roleAvailabilityReason:groupData.roleAvailabilityReason,
    interviewRound1:{
      typeOfInterview:groupData.interviewRound1.typeOfInterview,
      duration:groupData.interviewRound1.duration,
      interviewLocation:groupData.interviewRound1.interviewLocation,
      interviewLead:groupData.interviewRound1.interviewLead,
      interviewPanelMember1:groupData.interviewRound1.interviewPanelMember1,
      /*interviewPanelMember2:groupData.interviewRound1.interviewPanelMember2,
      availabilityStart1:groupData.interviewRound1.availabilityStart1,
      availabilityEnd1: groupData.interviewRound1.availabilityEnd1,
      availabilityStart2: groupData.interviewRound1.availabilityStart2,
      availabilityEnd2:groupData.interviewRound1.availabilityEnd2,
      availabilityStart3:groupData.interviewRound1.availabilityStart3,
      availabilityEnd3:groupData.interviewRound1.availabilityEnd3,*/
      availableDays:groupData.interviewRound1.availableDays,
  
      },
    interviewRound2:{
  
      typeOfInterview:groupData.interviewRound2.typeOfInterview,
      duration:groupData.interviewRound2.duration,
      interviewLocation:groupData.interviewRound2.interviewLocation,
      interviewLead:groupData.interviewRound2.interviewLead,
      interviewPanelMember1:groupData.interviewRound2.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound2.interviewPanelMember2,
      /*availabilityStart1:groupData.interviewRound2.availabilityStart1,
      availabilityEnd1: groupData.interviewRound2.availabilityEnd1,
      availabilityStart2: groupData.interviewRound2.availabilityStart2,
      availabilityEnd2:groupData.interviewRound2.availabilityEnd2,
      availabilityStart3:groupData.interviewRound2.availabilityStart3,
      availabilityEnd3:groupData.interviewRound2.availabilityEnd3,*/
      availableDays:groupData.interviewRound2.availableDays,

    },
    interviewRound3:{

      
      typeOfInterview:groupData.interviewRound3.typeOfInterview,
      duration:groupData.interviewRound3.duration,
      interviewLocation:groupData.interviewRound3.interviewLocation,
      interviewLead:groupData.interviewRound3.interviewLead,
      interviewPanelMember1:groupData.interviewRound3.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound3.interviewPanelMember2,
     /* availabilityStart1:groupData.interviewRound3.availabilityStart1,
      availabilityEnd1: groupData.interviewRound3.availabilityEnd1,
      availabilityStart2: groupData.interviewRound3.availabilityStart2,
      availabilityEnd2:groupData.interviewRound3.availabilityEnd2,
      availabilityStart3:groupData.interviewRound3.availabilityStart3,
      availabilityEnd3:groupData.interviewRound3.availabilityEnd3,*/
      availableDays:groupData.interviewRound3.availableDays,

    },
    essentialSkills:groupData.essentialSkills,
    nonEssentialSkills:groupData.nonEssentialSkills,
    successfulPersonDesc:groupData.successfulPersonDesc,
    numberOfOpenings:groupData.numberOfOpenings,
    sharedElseWhere:groupData.sharedElseWhere,
    jobCreatedDate: today.toLocaleDateString("en-US", options),
    jobDescription:url,
}).then(async(res)=>{
    //console.log("RESPONSE ID: ", res.id);


    //ADDING ROUND 1 TO INTERVIEW ROUNDS COLLECTION
    db.collection("interviewRounds").add({
      roundName:"round 1",
      businessId:groupData.businessId,
      jobId:res.id,
      typeOfInterview:groupData.interviewRound1.typeOfInterview,
      duration:groupData.interviewRound1.duration,
      interviewLocation:groupData.interviewRound1.interviewLocation,
      interviewLead:groupData.interviewRound1.interviewLead,
      interviewPanelMember1:groupData.interviewRound1.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound1.interviewPanelMember2,
      /*availabilityStart1:groupData.interviewRound1.availabilityStart1,
      availabilityEnd1: groupData.interviewRound1.availabilityEnd1,
      availabilityStart2: groupData.interviewRound1.availabilityStart2,
      availabilityEnd2:groupData.interviewRound1.availabilityEnd2,
      availabilityStart3:groupData.interviewRound1.availabilityStart3,
      availabilityEnd3:groupData.interviewRound1.availabilityEnd3,*/
      availableDays:groupData.interviewRound1.availableDays,
     

    }).then((respo)=>{

      db.collection("interviewRounds").doc(respo.id).update({
        uid:respo.id,
        id:respo.id

      })

    })

//ADDING ROUND 2 TO INTERVIEW ROUNDS COLLECTION
    db.collection("interviewRounds").add({
      roundName:"round 2",
      businessId:groupData.businessId,
      jobId:res.id,
      typeOfInterview:groupData.interviewRound2.typeOfInterview,
      duration:groupData.interviewRound2.duration,
      interviewLocation:groupData.interviewRound2.interviewLocation,
      interviewLead:groupData.interviewRound2.interviewLead,
      interviewPanelMember1:groupData.interviewRound2.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound2.interviewPanelMember2,
      /*availabilityStart1:groupData.interviewRound2.availabilityStart1,
      availabilityEnd1: groupData.interviewRound2.availabilityEnd1,
      availabilityStart2: groupData.interviewRound2.availabilityStart2,
      availabilityEnd2:groupData.interviewRound2.availabilityEnd2,
      availabilityStart3:groupData.interviewRound2.availabilityStart3,
      availabilityEnd3:groupData.interviewRound2.availabilityEnd3,*/
      availableDays:groupData.interviewRound2.availableDays,
     

    }).then((respo)=>{

      db.collection("interviewRounds").doc(respo.id).update({
        uid:respo.id,
        id:respo.id

      })

    })


//ADDING ROUND 3 TO INTERVIEW ROUNDS COLLECTION
    db.collection("interviewRounds").add({
      roundName:"round 3",
      businessId:groupData.businessId,
      jobId:res.id,
      typeOfInterview:groupData.interviewRound3.typeOfInterview,
      duration:groupData.interviewRound3.duration,
      interviewLocation:groupData.interviewRound3.interviewLocation,
      interviewLead:groupData.interviewRound3.interviewLead,
      interviewPanelMember1:groupData.interviewRound3.interviewPanelMember1,
      interviewPanelMember2:groupData.interviewRound3.interviewPanelMember2,
      /*availabilityStart1:groupData.interviewRound3.availabilityStart1,
      availabilityEnd1: groupData.interviewRound3.availabilityEnd1,
      availabilityStart2: groupData.interviewRound3.availabilityStart2,
      availabilityEnd2:groupData.interviewRound3.availabilityEnd2,
      availabilityStart3:groupData.interviewRound3.availabilityStart3,
      availabilityEnd3:groupData.interviewRound3.availabilityEnd3,*/
      availableDays:groupData.interviewRound3.availableDays,
     

    }).then((respo)=>{

      db.collection("interviewRounds").doc(respo.id).update({
        uid:respo.id,
        id:respo.id

      })

    })




    return db.collection('jobs').doc(res.id).update({
      jobId: res.id,
      uid:res.id,
      id:res.id,
    }).then(() => {
        db.collection('jobs').doc(res.id).collection('appliedCandidatesCollection').add({
             quickBlock:"not using this colection for now"
           /*memberName: user.name,
            memberEmail: user.email,
            memberImageUrl: user.profileImg,
            invitedBy: user.id,
            invite: 0,
            paid: 0,
            users: [user.id, user.id],
            sentAt: today.toLocaleDateString("en-US", options),*/
          }).then((resp) => {
            ////console.log("membersCollection RESPONSE: ", resp);
            setLoading(false);
            db.collection('jobs').doc(res.id).collection('appliedCandidatesCollection').doc(resp.id).delete({
              /*id: resp.id,*/
            })
          }).then(() => {
            dispatch(isItLoading(false))
            notifySuccessFxn("Job Created")
            setLoading(false);
            //navigate('/dashboard/home', { replace: true });
          }).catch((err) => {
            console.error("Error creating job: ", err);
            var errorMessage = err.message;
            notifyErrorFxn(errorMessage);
            setLoading(false);
          })
    })
  })


}


export const uploadGroupImage = (groupData, file, user, navigate, setLoading) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
 // //console.log('File Name: ', imageName);
  const uploadTask = storage.ref(`group_images/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
     // //console.log(error);
    },
    () => {
      storage
        .ref("group_images")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
         // //console.log('Image URL: ', url);
          dispatch(createGroup(groupData, user, file, navigate, setLoading, url));
        });
    }
  );
}

export const fetchMyGroups = (coolers =[]) => async (dispatch) => {
  ////console.log("Clicked...");
  dispatch(isItLoading(true));
  if (coolers && coolers.length) {
    const chunkSize = 10;
    const chunks = coolers.reduce((acc, _, i) => (i % chunkSize ? acc : [...acc, coolers.slice(i, i + chunkSize)]), []);
    const promises = chunks.map((chunk) => {
      return db
        .collection("jobs")
        .where("id", "in", chunk)
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => ({ ...doc.data() })));
    });
    Promise.all(promises)
      .then((results) => {
        const myGroups = results.flat();
        ////console.log("My Added Jobs Data-->:", myGroups);
        dispatch(saveMyGroup(myGroups));
        dispatch(isItLoading(false));
      })
      .catch((error) => {
        ////console.log("Error getting document:", error);
        dispatch(isItLoading(false));
      });
  } else {
    dispatch(saveMyGroup(coolers));
    dispatch(isItLoading(false));
  }
};






export const fetchGroups = (adminID) => async (dispatch) => {
  dispatch(isItLoading(true));
  db.collection("groups")
  .where('admin', '==', adminID)
   .get()
   .then((snapshot) => {
     const allGroups = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allGroups.length > 0) {
     dispatch(isItLoading(false));
    // //console.log("All Groups Data:", allGroups);
     dispatch(saveAllGroup(allGroups));
   } else {
       dispatch(isItLoading(false));
       dispatch(saveAllGroup(allGroups));
      // //console.log("No groups!");
   }
 }).catch((error) => {
  // //console.log("Error getting document:", error);
   dispatch(isItLoading(false));
 });
 };



 export const AddRecruiterJobs = (userId, jobId,userFirstName,userLastName) => async (dispatch) => {
  var user = db.collection('recruiters').doc(userId);
  


  user.update({
    recruiterJobs:firebase.firestore.FieldValue.arrayUnion(jobId)
  }).then((doc1) => {
    // //console.log("doc 1 is-->",doc1)
   

    db.collection('jobs').doc(jobId)
    .update({
      recruiterName:`${userFirstName + " " + userLastName}`
    })

 
    db.collection('recruiters').doc(userId)
    .get().then((doc)=>{

      if(doc.exists){
        dispatch(storeUserData(doc.data()))
      dispatch(fetchRecruiterJobs(doc.data().recruiterJobs));
      ////console.log("latest array of recruiter jobs-->",doc.data().recruiterJobs)
      }
    })

   



      notifySuccessFxn("job added to MY JOBS");
      //navigate('/dashboard/home', { replace: true });
  
 
     
}).catch((error) => {
  notifyErrorFxn("Could not find user, please try again!")
 // //console.log("No such document!");

 // //console.log("Error getting document:", error);
});
return user;
};



 export const fetchJobCandidates = (jobID) => async (dispatch) => {
  dispatch(isItLoading(true));
 

  //db.collection('candidates')
  //.where('jobsApplied', 'array-contains',jobID)
    db.collection('jobs').doc(jobID).collection('appliedCandidatesCollection')
  .get()

 //  .get()
   .then((snapshot) => {
   // //console.log("STILL WORKING AT THIS POINT O!---->:",snapshot.docs)
     const allJobCands = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allJobCands.length > 0) {

     dispatch(isItLoading(false));
    // //console.log("All JobCands Data ARE-->:", allJobCands);
     dispatch(saveJobCandidates(allJobCands));
   } else {
       dispatch(isItLoading(false));
       dispatch(saveJobCandidates(allJobCands));
      // //console.log("No JobCands!");
   }
 }).catch((error) => {
   ////console.log("Error getting document:", error);
   dispatch(isItLoading(false));
 });
 };




 export const fetchJobsWithCandidatesPendingReview = (businessID) => async (dispatch) => {
  dispatch(isItLoading(true));


  db.collection("candidates")
  .where('status', '==', 'pending review')
   .get()
   .then((snapshot) => {
     const allCandidates = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allCandidates.length > 0) {
     dispatch(isItLoading(false));
     //console.log("All Candidates Pending Review Data:", allCandidates);
     dispatch(saveCandidatesPendingReview(allCandidates));

     let jobIdsWithCandidatesPendingReview =  allCandidates.map((item)=>(item.jobId))

    db.collection("jobs")
    .where('id', 'in', jobIdsWithCandidatesPendingReview)
    .where('businessId', '==', businessID)
    .get()
    .then((snapshot) => {
      const allJobs = snapshot.docs.map((doc) => ({ ...doc.data() }));
    if (allJobs.length > 0) {

   
      dispatch(saveJobsPendingReview(allJobs));
      
    }}
  )


   } else {
   
    const allJobs = []

       dispatch(isItLoading(false));
       dispatch(saveCandidatesPendingReview([]));
       dispatch(saveJobsPendingReview([]));
       //console.log("No Candidates pending review, hence, no jobs pending review!");
   }
 }).catch((error) => {
   //console.log("Error getting candidates pending review:", error);
   dispatch(isItLoading(false));
 });
 };


 export const fetchJobsWithCandidatesPendingInterview = (businessID) => async (dispatch) => {
  dispatch(isItLoading(true));


  db.collection("candidates")
  .where('status', '==', 'pending interview')
   .get()
   .then((snapshot) => {
     const allCandidates = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allCandidates.length > 0) {
    //console.log("All Candidates Pending Interview Data:", allCandidates);
    dispatch(saveCandidatesPendingInterview(allCandidates));

    let jobIdsWithCandidatesPendingInterview =  allCandidates.map((item)=>(item.jobId))

    db.collection("jobs")
    .where('id', 'in', jobIdsWithCandidatesPendingInterview)
    .where('businessId', '==', businessID)
    .get()
    .then((snapshot) => {
      const allJobs = snapshot.docs.map((doc) => ({ ...doc.data() }));
    if (allJobs.length > 0) {

   
      dispatch(saveJobsPendingInterview(allJobs));

    }}
  )

     //dispatch(isItLoading(false));
   
   } else {  
     
    
       dispatch(isItLoading(false));
       dispatch(saveCandidatesPendingInterview([]));
       dispatch(saveJobsPendingInterview([]));

       //console.log("No Candidates pending interview, so no corresponding jobs!");
   }
 }).catch((error) => {
   //console.log("Error getting candidates pending interview:", error);
   dispatch(isItLoading(false));
 });
 };



 export const fetchJobsWithCandidatesPendingOffer = (/*businessID*/) => async (dispatch) => {
  dispatch(isItLoading(true));


  db.collection("candidates")
  .where('status', '==', 'pending offer')
   .get()
   .then((snapshot) => {
     const allCandidates = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allCandidates.length > 0) {
    //console.log("All Candidates accepted Offer Data:", allCandidates);
    dispatch(saveCandidatesPendingOffer(allCandidates));

    let jobIdsWithCandidatesPendingOffer =  allCandidates.map((item)=>(item.jobId))
  /*CANDIDATES D NOT HAVE A JOB ID NOW DO THEY ? JUL 16 2024 */


    db.collection("jobs")
    .where('id', 'in', jobIdsWithCandidatesPendingOffer)
   // .where('businessId', '==', businessID)
    .get()
    .then((snapshot) => {
      const allJobs = snapshot.docs.map((doc) => ({ ...doc.data() }));
    if (allJobs.length > 0) {

   //console.log("jobs with candidates accepted offer-->",allJobs)
      dispatch(saveJobsPendingOffer(allJobs));

    }}
  )

     //dispatch(isItLoading(false));
   
   } else {
       dispatch(isItLoading(false));
       dispatch(saveCandidatesPendingOffer(allCandidates));
       dispatch(saveJobsPendingOffer([]));

       //console.log("No Candidates accepted Offer, so no corresponding jobs!");
   }
 }).catch((error) => {
   //console.log("Error getting candidates accepted Offer:", error);
   dispatch(isItLoading(false));
 });
 };





 export const fetchJobsWithCandidatesAcceptedOffer = (/*businessID*/) => async (dispatch) => {
  dispatch(isItLoading(true));


  db.collection("candidates")
  .where('status', '==', 'accepted offer')
   .get()
   .then((snapshot) => {
     const allCandidates = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allCandidates.length > 0) {
    //console.log("All Candidates accepted Offer Data:", allCandidates);
    dispatch(saveCandidatesAcceptedOffer(allCandidates));

    let jobIdsWithCandidatesAcceptedOffer =  allCandidates.map((item)=>(item.jobId))
  /*CANDIDATES D NOT HAVE A JOB ID NOW DO THEY ? JUL 16 2024 */


    db.collection("jobs")
    .where('id', 'in', jobIdsWithCandidatesAcceptedOffer)
   // .where('businessId', '==', businessID)
    .get()
    .then((snapshot) => {
      const allJobs = snapshot.docs.map((doc) => ({ ...doc.data() }));
    if (allJobs.length > 0) {

   //console.log("jobs with candidates accepted offer-->",allJobs)
      dispatch(saveJobsAcceptedOffer(allJobs));

    }}
  )

     //dispatch(isItLoading(false));
   
   } else {
       dispatch(isItLoading(false));
       dispatch(saveCandidatesAcceptedOffer(allCandidates));
       dispatch(saveJobsAcceptedOffer([]));

       //console.log("No Candidates accepted Offer, so no corresponding jobs!");
   }
 }).catch((error) => {
   //console.log("Error getting candidates accepted Offer:", error);
   dispatch(isItLoading(false));
 });
 };





 export const fetchCandidatesPendingReview = (/*adminID*/) => async (dispatch) => {
  dispatch(isItLoading(true));


  db.collection("candidates")
  .where('status', '==', 'pending review')
   .get()
   .then((snapshot) => {
     const allCandidates = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allCandidates.length > 0) {
     dispatch(isItLoading(false));
     //console.log("All Candidates Pending review Data:", allCandidates);
     dispatch(saveCandidatesPendingReview(allCandidates));
   } else {
       dispatch(isItLoading(false));
       dispatch(saveCandidatesPendingReview([]));
       //console.log("No Candidates pending review!");
   }
 }).catch((error) => {
   //console.log("Error getting candidates pending review:", error);
   dispatch(isItLoading(false));
 });
 };



 export const fetchCandidatesPendingInterview = (/*adminID*/) => async (dispatch) => {
  dispatch(isItLoading(true));


  db.collection("candidates")
  .where('status', '==', 'pending interview')
   .get()
   .then((snapshot) => {
     const allCandidates = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allCandidates.length > 0) {
     dispatch(isItLoading(false));
     //console.log("All Candidates Pending Interview Data:", allCandidates);
     dispatch(saveCandidatesPendingInterview(allCandidates));
   } else {
       dispatch(isItLoading(false));
       dispatch(saveCandidatesPendingInterview([]));
       //console.log("No Candidates pending interview!");
   }
 }).catch((error) => {
   //console.log("Error getting candidates pending interview:", error);
   dispatch(isItLoading(false));
 });
 };



 export const fetchCandidatesPendingOffer = (/*adminID*/) => async (dispatch) => {
  dispatch(isItLoading(true));


  db.collection("candidates")
  .where('status', '==', 'pending interview')
   .get()
   .then((snapshot) => {
     const allCandidates = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allCandidates.length > 0) {
     dispatch(isItLoading(false));
     //console.log("All Candidates Pending offer Data:", allCandidates);
     dispatch(saveCandidatesPendingOffer(allCandidates));
   } else {
       dispatch(isItLoading(false));
       dispatch(saveCandidatesPendingOffer([]));
       //console.log("No Candidates pending offer!");
   }
 }).catch((error) => {
   //console.log("Error getting candidates pending offer:", error);
   dispatch(isItLoading(false));
 });
 };


 export const fetchAllJobsForMyBusiness = (businessID) => async (dispatch) => {
  dispatch(isItLoading(true));
  db.collection("jobs")
  .where('businessId', '==', businessID)
   .get()
   .then((snapshot) => {
     const allGroups = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allGroups.length > 0) {
     dispatch(isItLoading(false));
     //console.log("All Groups Data-->:", allGroups);
     dispatch(saveAllJobs(allGroups));
   } else {
       dispatch(isItLoading(false));
       dispatch(saveAllJobs(allGroups));
       //console.log("No groups!");
   }
 }).catch((error) => {
   //console.log("Error getting document:", error);
   dispatch(isItLoading(false));
 });
 };



 export const fetchAllJobsRecruiter = () => async (dispatch) => {
  dispatch(isItLoading(true));
  db.collection("jobs")
   .get()
   .then((snapshot) => {
     const allGroups = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allGroups.length > 0) {
     dispatch(isItLoading(false));
     //console.log("All Groups Data:", allGroups);
     dispatch(saveAllJobsRecruiter(allGroups));
   } else {
       dispatch(isItLoading(false));
       dispatch(saveAllJobsRecruiter(allGroups));
       //console.log("No groups!");
   }
 }).catch((error) => {
   //console.log("Error getting document:", error);
   dispatch(isItLoading(false));
 });
 };


 export const fetchAllClosedJobsForMyBusiness = (businessID) => async (dispatch) => {
  dispatch(isItLoading(true));
  db.collection("jobs")
  .where('businessId', '==', businessID)
  .where('status', '==', 'closed')
   .get()
   .then((snapshot) => {
     const allGroups = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (allGroups.length > 0) {
     dispatch(isItLoading(false));
     //console.log("All Groups Data:", allGroups);
     dispatch(saveAllClosedJobs(allGroups));
   } else {
       dispatch(isItLoading(false));
       dispatch(saveAllClosedJobs(allGroups));
       //console.log("No groups!");
   }
 }).catch((error) => {
   //console.log("Error getting document:", error);
   dispatch(isItLoading(false));
 });
 };


 export const acceptCandidateToInterview=  (candidateId,jobId,businessId,navigate) => async (dispatch) => {

    /* return*/ db.collection('jobs').doc(jobId).collection('appliedcandidateCollecion').doc(candidateId).update({
      status:"pending interview",
      pendingInterviewPeriodStarted:new Date(),
      initialReview:true
    })
   


  /* return*/ db.collection('candidates').doc(candidateId).update({
    status:"pending interview",
    pendingInterviewPeriodStarted:new Date(),
    initialReview:true
  }).then(()=>{
    dispatch(clearJobsPendingReview())
    dispatch(clearCandidatesPendingReview())
  })
  
  .then(()=>{

    dispatch(fetchJobsWithCandidatesPendingReview(businessId))

  }).then(() => {


      notifySuccessFxn("Candidate Accepted for Interview")
      navigate(-1)
    
  })
   

 }

 export const acceptCandidateForSelection =  (candidateId,jobId,businessId,navigate) => async (dispatch) => {

  /* return*/ db.collection('jobs').doc(jobId).collection('appliedcandidateCollecion').doc(candidateId).update({
    status:"pending offer",
    pendingSelectionPeriodStarted:new Date(),
    selected:true
  })
 


/* return*/ db.collection('candidates').doc(candidateId).update({
  status:"pending offer",
  pendingSelectionPeriodStarted:new Date(),
  selected:true
}).then(()=>{
  dispatch(clearJobsPendingInterview())
  dispatch(clearCandidatesPendingInterview())
})

.then(()=>{

  dispatch(fetchJobsWithCandidatesPendingInterview(businessId))

}).then(() => {


    notifySuccessFxn("Candidate Selected, now pending offer!")
    navigate(-1)
  
})
 

}


export const acceptCandidateToOffer =  (candidateId,jobId/*,businessId*/,navigate) => async (dispatch) => {

  /* return*/ db.collection('jobs').doc(jobId).collection('appliedcandidateCollecion').doc(candidateId).update({
    status:"accepted offer",
    pendingOfferPeriodStarted:new Date(),
    offered:true
  })
 


/* return*/ db.collection('candidates').doc(candidateId).update({
  status:"accepted offer",
  pendingOfferPeriodStarted:new Date(),
  offered:true
}).then(()=>{
  dispatch(clearJobsPendingOffer())
  dispatch(clearCandidatesPendingOffer())
})

.then(()=>{

dispatch(fetchJobsWithCandidatesAcceptedOffer(/*businessId*/))
  

}).then(() => {


    notifySuccessFxn("Candidate Offered the Job!")
    navigate('/dashboard/candidates-offered')
  
})
 

}



 export const rejectCandidateForInterview=  (candidateId,jobId,businessId,navigate) => async (dispatch) => {


   /* return*/ db.collection('jobs').doc(jobId).collection('appliedcandidatesCollecion').doc(candidateId).update({
    status:"rejected interview",
    initialReview:true
  })
 


/* return*/ db.collection('candidates').doc(candidateId).update({
  status:"rejected interview",
  initialReview:true
}).then(()=>{
  dispatch(clearJobsPendingReview())
  dispatch(clearCandidatesPendingReview())
})

.then(()=>{

  dispatch(fetchJobsWithCandidatesPendingReview(businessId))

})
.then(() => {

    notifySuccessFxn("Candidate Rejected")
    navigate(-1)
  
})
 

 }


 export const rejectCandidateForSelection=  (candidateId,jobId,businessId,navigate) => async (dispatch) => {


  /* return*/ db.collection('jobs').doc(jobId).collection('appliedcandidatesCollecion').doc(candidateId).update({
   status:"rejected selection",
  selected:false
 })



/* return*/ db.collection('candidates').doc(candidateId).update({
 status:"rejected selection",
selected:false
}).then(()=>{
 dispatch(clearJobsPendingInterview())
 dispatch(clearCandidatesPendingInterview())
})

.then(()=>{

 dispatch(fetchJobsWithCandidatesPendingInterview(businessId))

})
.then(() => {

   notifySuccessFxn("Candidate Rejected.")
   navigate(-1)
 
})


}



export const rejectCandidateForOffer=  (candidateId,jobId,businessId,navigate) => async (dispatch) => {


  /* return*/ db.collection('jobs').doc(jobId).collection('appliedcandidatesCollecion').doc(candidateId).update({
   status:"offer rejected",
  offered:false
 })



/* return*/ db.collection('candidates').doc(candidateId).update({
 status:"offer rejected",
offered:false
}).then(()=>{
 dispatch(clearJobsPendingOffer())
 dispatch(clearCandidatesPendingOffer())
})

.then(()=>{

 dispatch(fetchJobsWithCandidatesPendingOffer(businessId))

})
.then(() => {

   notifySuccessFxn("Candidate did not accept offer.")
   navigate(-1)
 
})


}


export const uploadCandidateResume = (file,jobId,candidateData,jobRole) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
  //console.log('Candidate CV File Name: ', imageName);
  const uploadTask = storage.ref(`candidate_resumes/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
      //console.log(error);
    },
    () => {
      storage
        .ref("candidate_resumes")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
          //console.log('Image URL: ', url);
          dispatch(AddJobToPendingAndAddCandidate(jobId,candidateData,jobRole,url));
        });
    }
  );
}





 export const AddJobToPendingAndAddCandidate =  (jobId,candidateData,jobRole,url) => async (dispatch) => {

   
   /* return*/ db.collection('jobs').doc(jobId).update({
    status:"pending review"
  }).then(() => {
 
      
 

      db.collection('jobs').doc(jobId).collection('appliedCandidatesCollection').add({
           
        
         candidateLocation:candidateData.candidateLocation,
         currentTitle:candidateData.currentTitle,
         noticePeriod:candidateData.noticePeriod,
         workAuthorization:candidateData.workAuthorization,
         salaryRange:candidateData.salaryRange,
         yearsOfExperience:candidateData.yearsOfExperience,
         firstName:candidateData.firstName,
         lastName:candidateData.lastName,
         currentEmployer:candidateData.currentEmployer,
         additionalSalaryNote:candidateData.additionalSalaryNote,
         candidateStrengths:candidateData.candidateStrengths,
         areasOfConcern: candidateData.areasOfConcern,
         additionalComments:candidateData.additionalComments,
         status:"pending review",
         initialReview:false,
         interviewReview:false,
         pendingReviewPeriodStarted:new Date(),
         jobId:jobId,
         jobRole:jobRole,
         businessId:candidateData.businessId,
         candidateResume:url,
         interviewRound1:{
          interviewLeadFeedback:{
            candidateAlignmentReason:"",
            candidateAlignmentRating:"",
            candidateAnswertReason:"",
            candidateAnswerRating:"",
            candidateAreasOfConcernReason:"",
            candidateAreasOfConcernRating:"",
            candidateWouldYouHire:""
           },
           interviewPanelMember1Feedback:{
             candidateAlignmentReason:"",
             candidateAlignmentRating:"",
             candidateAnswertReason:"",
             candidateAnswerRating:"",
             candidateAreasOfConcernReason:"",
             candidateAreasOfConcernRating:"",
             candidateWouldYouHire:""
 
           },
           interviewPanelMember2Feedback:{
             candidateAlignmentReason:"",
             candidateAlignmentRating:"",
             candidateAnswertReason:"",
             candidateAnswerRating:"",
             candidateAreasOfConcernReason:"",
             candidateAreasOfConcernRating:"",
             candidateWouldYouHire:""
 
           }
      
          },
        interviewRound2:{
      
          interviewLeadFeedback:{
            candidateAlignmentReason:"",
            candidateAlignmentRating:"",
            candidateAnswertReason:"",
            candidateAnswerRating:"",
            candidateAreasOfConcernReason:"",
            candidateAreasOfConcernRating:"",
            candidateWouldYouHire:""
           },
           interviewPanelMember1Feedback:{
             candidateAlignmentReason:"",
             candidateAlignmentRating:"",
             candidateAnswertReason:"",
             candidateAnswerRating:"",
             candidateAreasOfConcernReason:"",
             candidateAreasOfConcernRating:"",
             candidateWouldYouHire:""
 
           },
           interviewPanelMember2Feedback:{
             candidateAlignmentReason:"",
             candidateAlignmentRating:"",
             candidateAnswertReason:"",
             candidateAnswerRating:"",
             candidateAreasOfConcernReason:"",
             candidateAreasOfConcernRating:"",
             candidateWouldYouHire:""
 
           }
    
        },
        interviewRound3:{
    
        
          interviewLeadFeedback:{
           candidateAlignmentReason:"",
           candidateAlignmentRating:"",
           candidateAnswertReason:"",
           candidateAnswerRating:"",
           candidateAreasOfConcernReason:"",
           candidateAreasOfConcernRating:"",
           candidateWouldYouHire:""
          },
          interviewPanelMember1Feedback:{
            candidateAlignmentReason:"",
            candidateAlignmentRating:"",
            candidateAnswertReason:"",
            candidateAnswerRating:"",
            candidateAreasOfConcernReason:"",
            candidateAreasOfConcernRating:"",
            candidateWouldYouHire:""

          },
          interviewPanelMember2Feedback:{
            candidateAlignmentReason:"",
            candidateAlignmentRating:"",
            candidateAnswertReason:"",
            candidateAnswerRating:"",
            candidateAreasOfConcernReason:"",
            candidateAreasOfConcernRating:"",
            candidateWouldYouHire:""

          }
        
         
    
        },

        })
        .then((resp)=>{

   
          db.collection('jobs').doc(jobId).collection('appliedCandidatesCollection').doc(resp.id).update({
            id:resp.id
          })

     
          db.collection('candidates').doc(resp.id).set({

         
            candidateLocation:candidateData.candidateLocation,
            currentTitle:candidateData.currentTitle,
            noticePeriod:candidateData.noticePeriod,
            workAuthorization:candidateData.workAuthorization,
            salaryRange:candidateData.salaryRange,
            yearsOfExperience:candidateData.yearsOfExperience,
            firstName:candidateData.firstName,
             lastName:candidateData.lastName,
             currentEmployer:candidateData.currentEmployer,
            additionalSalaryNote:candidateData.additionalSalaryNote,
            candidateStrengths:candidateData.candidateStrengths,
            areasOfConcern: candidateData.areasOfConcern,
            additionalComments:candidateData.additionalComments,
            candidateResume:url,
            id:resp.id,
            status:"pending review",
            initialReview:false,
            interviewReview:false,
            pendingReviewPeriodStarted:new Date(),
            jobId:jobId,
            jobRole:jobRole,
            interviewRound1:{
              interviewLeadFeedback:{
                candidateAlignmentReason:"",
                candidateAlignmentRating:"",
                candidateAnswertReason:"",
                candidateAnswerRating:"",
                candidateAreasOfConcernReason:"",
                candidateAreasOfConcernRating:"",
                candidateWouldYouHire:""
               },
               interviewPanelMember1Feedback:{
                 candidateAlignmentReason:"",
                 candidateAlignmentRating:"",
                 candidateAnswertReason:"",
                 candidateAnswerRating:"",
                 candidateAreasOfConcernReason:"",
                 candidateAreasOfConcernRating:"",
                 candidateWouldYouHire:""
     
               },
               interviewPanelMember2Feedback:{
                 candidateAlignmentReason:"",
                 candidateAlignmentRating:"",
                 candidateAnswertReason:"",
                 candidateAnswerRating:"",
                 candidateAreasOfConcernReason:"",
                 candidateAreasOfConcernRating:"",
                 candidateWouldYouHire:""
     
               }
          
              },
            interviewRound2:{
          
              interviewLeadFeedback:{
                candidateAlignmentReason:"",
                candidateAlignmentRating:"",
                candidateAnswertReason:"",
                candidateAnswerRating:"",
                candidateAreasOfConcernReason:"",
                candidateAreasOfConcernRating:"",
                candidateWouldYouHire:""
               },
               interviewPanelMember1Feedback:{
                 candidateAlignmentReason:"",
                 candidateAlignmentRating:"",
                 candidateAnswertReason:"",
                 candidateAnswerRating:"",
                 candidateAreasOfConcernReason:"",
                 candidateAreasOfConcernRating:"",
                 candidateWouldYouHire:""
     
               },
               interviewPanelMember2Feedback:{
                 candidateAlignmentReason:"",
                 candidateAlignmentRating:"",
                 candidateAnswertReason:"",
                 candidateAnswerRating:"",
                 candidateAreasOfConcernReason:"",
                 candidateAreasOfConcernRating:"",
                 candidateWouldYouHire:""
     
               }
        
            },
            interviewRound3:{
        
            
              interviewLeadFeedback:{
               candidateAlignmentReason:"",
               candidateAlignmentRating:"",
               candidateAnswertReason:"",
               candidateAnswerRating:"",
               candidateAreasOfConcernReason:"",
               candidateAreasOfConcernRating:"",
               candidateWouldYouHire:""
              },
              interviewPanelMember1Feedback:{
                candidateAlignmentReason:"",
                candidateAlignmentRating:"",
                candidateAnswertReason:"",
                candidateAnswerRating:"",
                candidateAreasOfConcernReason:"",
                candidateAreasOfConcernRating:"",
                candidateWouldYouHire:""
    
              },
              interviewPanelMember2Feedback:{
                candidateAlignmentReason:"",
                candidateAlignmentRating:"",
                candidateAnswertReason:"",
                candidateAnswerRating:"",
                candidateAreasOfConcernReason:"",
                candidateAreasOfConcernRating:"",
                candidateWouldYouHire:""
    
              }
            
             
        
            },
            

          })
           

        })
        .then(() => {


          notifySuccessFxn("Candidate Added Successfully!")
         
        }).catch((err) => {
          console.error("Error adding candidate to this Job: ", err.message);
          var errorMessage = err.message;
          notifyErrorFxn("Error adding candidate to this Job,please try again ");
         
        })
  })

 }


 export const fetchRecruiterJobs = (jobsArray) => async (dispatch) => {
  dispatch(isItLoading(true));
  db.collection("jobs")
   .where('id', 'in',jobsArray)
   .get()
   .then((snapshot) => {
     const myGroups = snapshot.docs.map((doc) => ({ ...doc.data() }));
   if (myGroups.length > 0) {
     dispatch(isItLoading(false));
     //console.log("All Groups Data-->:", myGroups);
     dispatch(saveMyGroup(myGroups));
   } else {
       dispatch(isItLoading(false));
       dispatch(saveMyGroup(myGroups));
       //console.log("No groups!");
   }
 }).catch((error) => {
   //console.log("Error getting document:", error);
   dispatch(isItLoading(false));
 });
 };



export const fetchPublicGroup = () => async (dispatch) => {
 dispatch(isItLoading(true));
 db.collection("groups")
  .where("status", "==", "public")
  .get()
  .then((snapshot) => {
    const publicGroups = snapshot.docs.map((doc) => ({ ...doc.data() }));
  if (publicGroups.length) {
    dispatch(isItLoading(false));
    //console.log("Public Groups Data:", publicGroups);
    dispatch(savePublicGroup(publicGroups));
  } else {
      dispatch(isItLoading(false));
      //console.log("No public groups!");
  }
}).catch((error) => {
  //console.log("Error getting document:", error);
  dispatch(isItLoading(false));
});
};

export const fetchPrivateGroup = () => async (dispatch) => {
    dispatch(isItLoading(true));
    db.collection("groups")
     .where("status", "==", "private")
     .get()
     .then((snapshot) => {
       const privateGroups = snapshot.docs.map((doc) => ({ ...doc.data() }));
     if (privateGroups.length) {
       dispatch(isItLoading(false));
       //console.log("Private Groups Data:", privateGroups);
       dispatch(savePrivateGroup(privateGroups));
     } else {
         dispatch(isItLoading(false));
         //console.log("No private groups!");
     }
   }).catch((error) => {
     //console.log("Error getting document:", error);
     dispatch(isItLoading(false));
   });
   };


   export const joinGroup = (groupID, user, today, navigate, userWalletBal, groupFee, groupBal, groupName, accruedBalance) => async (dispatch) => {
    let todaysDate = new Date().toISOString().slice(0, 10) //2018-08-03
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today  = new Date();
    const date = today.toISOString();  

   
    let newUserBal = userWalletBal - groupFee;
    let newGroupBal = groupBal + groupFee;
    let newAccruedBal = accruedBalance + groupFee;
      // //console.log("New Group Bal: ", newGroupBal);
    dispatch(isItLoading(true));
    let newMembers;
    var docRef = db.collection("groups").doc(groupID);
    docRef.get().then((doc) => {
    const data = doc.data();
    const members = data.members;
    newMembers = [...members, user.id];
}).then(() => {
  db.collection('groups')
  var userRef = db.collection("groups").doc(groupID);
  userRef.update({
    accountBalance: newGroupBal,
    members: [...newMembers],
  }).then((res) => {
    db.collection('employees')
    .doc(user.id)
    .update({
      walletBalance: newUserBal,
      accruedBalance: newAccruedBal,
      coolers: [...user?.coolers, groupID],
    })
   .then(() => {
    db.collection('groups').doc(groupID).collection('membersCollection').add({
      memberName: user.firstName + " " + user.lastName,
      memberEmail: user.email,
      memberImageUrl: "",
      invitedBy: user.id,
      invite: 0,
      paid: 1,
      users: user.id,
      sentAt: today,
    }).then((resp) => {
      //console.log("membersCollection RESPONSE: ", resp);
      db.collection('groups').doc(groupID).collection('membersCollection').doc(resp.id).update({
        id: resp.id,
      }).then(() => {
        return db.collection('inbox')
          .add({
              id: user.id,
              msg: `You have joined ${groupName}`,
              coolerName: groupName,
              amount: groupFee,
              isViewed: false,
              unread: 0,
              time: date,
          })
      }).then(() => {
        return db.collection('transactions')
          .add({
              userID: user.id,
              coolerID: groupID,
              type: 'Payment',
              amount: groupFee,
              date: todaysDate,
              createdAt: today.toLocaleDateString("en-US", options),
          })
      })
  }).then(() => {
    dispatch(isItLoading(false));
    notifySuccessFxn("Joined Group")
    // window.location = '/dashboard/home';
    navigate('/dashboard/home', { replace: true });
    }).catch((error) => {
    //console.log("Error joining group:", error);
    var errorMessage = error.message;
    notifyErrorFxn(errorMessage)
    dispatch(isItLoading(false));
  });
   }) 
   })
})
 };


 export const makeMonthlyPayment = (groupID, user, today, navigate, userWalletBal, groupFee, groupBal, groupName, accruedBalance) => async (dispatch) => {
  let todaysDate = new Date().toISOString().slice(0, 10) //2018-08-03
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  var today  = new Date();
  const date = today.toISOString();  

 
  let newUserBal = userWalletBal - groupFee;
  let newGroupBal = groupBal + groupFee;
  let newAccruedBal = accruedBalance + groupFee;
    // //console.log("New Group Bal: ", newGroupBal);
  dispatch(isItLoading(true));
 
db.collection('groups')
var userRef = db.collection("groups").doc(groupID);
userRef.update({
  accountBalance: newGroupBal,
  
}).then((res) => {
  db.collection('employees')
  .doc(user.id)
  .update({
    walletBalance: newUserBal,
    accruedBalance: newAccruedBal,
  })
 .then(() => {
  
      return db.collection('inbox')
        .add({
            id: user.id,
            msg: `You have paid the monthly fee for ${groupName}`,
            coolerName: groupName,
            amount: groupFee,
            isViewed: false,
            unread: 0,
            time: date,
        })
    })
    .then(() => {
      return db.collection('transactions')
        .add({
            userID: user.id,
            coolerID: groupID,
            type: 'Payment',
            amount: groupFee,
            date: todaysDate,
            createdAt: today.toLocaleDateString("en-US", options),
        })
    })
.then(() => {
  dispatch(isItLoading(false));
  notifySuccessFxn("Made monthly Payment")
  // window.location = '/dashboard/home';
  navigate('/dashboard/home', { replace: true });
  }).catch((error) => {
  //console.log("Error joining group:", error);
  var errorMessage = error.message;
  notifyErrorFxn(errorMessage)
  dispatch(isItLoading(false));
});
 }) 
 }
;
 





   export const makeCoolerPayment = (groupID, user, today, navigate, userWalletBal, groupFee, groupBal, groupName, accruedBalance) => async (dispatch) => {
    let todaysDate = new Date().toISOString().slice(0, 10) //2018-08-03
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var today  = new Date();
    const date = today.toISOString();  

   
    let newUserBal = userWalletBal - groupFee;
    let newGroupBal = groupBal + groupFee;
    let newAccruedBal = accruedBalance + groupFee;
      // //console.log("New Group Bal: ", newGroupBal);
    dispatch(isItLoading(true));
  db.collection('groups')
  var userRef = db.collection("groups").doc(groupID);
  userRef.update({
    accountBalance: newGroupBal,
  }).then((res) => {
    db.collection('employees')
    .doc(user.id)
    .update({
      walletBalance: newUserBal,
      accruedBalance: newAccruedBal,
    })
   .then(() => {
    db.collection('groups').doc(groupID).collection('membersCollection').add({
      memberName: user.firstName + " " + user.lastName,
      memberEmail: user.email,
      memberImageUrl: "",
      invitedBy: user.id,
      invite: 0,
      paid: 1,
      users: user.id,
      sentAt: today,
    }).then((resp) => {
      }).then(() => {
        return db.collection('transactions')
          .add({
              userID: user.id,
              coolerID: groupID,
              type: 'Payment',
              amount: groupFee,
              date: todaysDate,
              createdAt: today.toLocaleDateString("en-US", options),
          })
      })
  }).then(() => {
    dispatch(isItLoading(false));
    notifySuccessFxn("Payment successful")
    // window.location = '/dashboard/home';
    navigate('/dashboard/home', { replace: true });
    }).catch((error) => {
    //console.log("Error joining group:", error);
    var errorMessage = error.message;
    notifyErrorFxn(errorMessage)
    dispatch(isItLoading(false));
  });
   }) 
 };


export const joinPublicGroup = (groupID, user, today, navigate) => async (dispatch) => {
    dispatch(isItLoading(true));
    let newMembers;
    var docRef = db.collection("groups").doc(groupID);
    docRef.get().then((doc) => {
    const data = doc.data();
    const members = data.members;
    newMembers = [...members, user.id];
}).then(() => {
  db.collection('groups')
  var userRef = db.collection("groups").doc(groupID);
  userRef.update({
    members: [...newMembers],
  }).then((res) => {
    db.collection('groups').doc(groupID).collection('membersCollection').add({
      memberName: user.firstName + " " + user.lastName,
      memberEmail: user.email,
      memberImageUrl: user.imageUrl,
      invitedBy: user.id,
      invite: 0,
      paid: 1,
      users: [user.id, user.id],
      sentAt: today,
    }).then((resp) => {
      //console.log("membersCollection RESPONSE: ", resp);
      db.collection('groups').doc(groupID).collection('membersCollection').doc(resp.id).update({
        id: resp.id,
      })
  }).then(() => {
    dispatch(isItLoading(false));
    notifySuccessFxn("Joined Group")
    navigate('/dashboard/home', { replace: true });
    }).catch((error) => {
    //console.log("Error joining group:", error);
    var errorMessage = error.message;
    notifyErrorFxn(errorMessage)
    dispatch(isItLoading(false));
  });
   })
})
 };

 
export const joinPrivateGroup = (groupID, user, today, navigate) => async (dispatch) => {
  dispatch(isItLoading(true));
  let newMembers;
  var docRef = db.collection("groups").doc(groupID);
  docRef.get().then((doc) => {
  const data = doc.data();
  const members = data.members;
  newMembers = [...members, user.id];
}).then(() => {
db.collection('groups')
var userRef = db.collection("groups").doc(groupID);
userRef.update({
  members: [...newMembers],
}).then((res) => {
  db.collection('groups').doc(groupID).collection('membersCollection').add({
    memberName: user.firstName + " " + user.lastName,
    memberEmail: user.email,
    memberImageUrl: user.imageUrl,
    invitedBy: user.id,
    invite: 0,
    paid: 1,
    users: [user.id, user.id],
    sentAt: today,
  }).then((resp) => {
    //console.log("membersCollection RESPONSE: ", resp);
    db.collection('groups').doc(groupID).collection('membersCollection').doc(resp.id).update({
      id: resp.id,
    })
}).then(() => {
  dispatch(isItLoading(false));
  notifySuccessFxn("Joined Group")
  navigate('/dashboard/home', { replace: true });
  }).catch((error) => {
  //console.log("Error joining group:", error);
  var errorMessage = error.message;
  notifyErrorFxn(errorMessage)
  dispatch(isItLoading(false));
});
 })
})
};


export const fetchGroupMembers = (groupMembers) => async (dispatch) => {
  dispatch(isItLoading(true));
  db.collection('employees')
    .where('id', 'in', groupMembers)
    .get()
    .then((snapshot) => {
      const groupMembers = snapshot.docs.map((doc) => ({ ...doc.data() }));
      if (groupMembers && groupMembers.length) {
        dispatch(isItLoading(false));
        //console.log('groupMembers Data:', groupMembers);
        dispatch(saveGroupMembers(groupMembers));
      } else {
        dispatch(isItLoading(false));
        //console.log('No group members!');
      }
    })
    .catch((error) => {
      //console.log('Error getting document:', error);
      dispatch(isItLoading(false));
    });
};

export const fetchEmployeer = (id) => async (dispatch) => {
  var user = db.collection("employers").doc(id);
  user.get().then((doc) => {
  if (doc.exists) {
    dispatch(saveEmployeer(doc.data()));
  } else {
      //console.log("No such document!");
  }
}).catch((error) => {
  //console.log("Error getting document:", error);
});
return user;
};