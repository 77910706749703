import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar2';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Searchbar2 from './Searchbar2';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  //backgroundColor: '#6077F000',
  backgroundColor:'#40255F !important',
  boxShadow: 'none',
  //:"Libre Baskerville, sans serif",
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH/2}px) !important`,
    //dagogo put this here!
    padding: `${theme.spacing(0, 5) } !important`,
    //padding:"1rem",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
 // minHeight:`${HEADER_MOBILE} !important`,
   height:"7rem",
  [theme.breakpoints.up('lg')]: {
  //  minHeight: `${HEADER_DESKTOP} !important`,
    height:"7rem",
   // padding: `${theme.spacing(2, 5) } !important`,
   padding:"1rem",
   position:"relative"
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate()
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        {/*<Typography variant="h4" sx={{color: 'black' }}>
         Welcome {'Chase'} - <span style={{color:"blue"}}>158288</span> 👋
        
        </Typography>*/}


        
        <Box style={{ flexGrow: 1 }} />

        {/*<Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
         
          <AccountPopover />
        </Stack>*/}

        
        {<div
       
          style={{position:"absolute",left:"72%",top:"1rem",display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:'#F2F2F2',padding:"1rem !important",paddingLeft:"1rem",gap:"1.5rem",borderRadius:"0.5rem",height:"5rem",width:"28rem !important"}}
          
        >
         
          <div onClick={()=>{navigate('/dashboard/home')}} style={{width:"5rem",height:"2.5rem",borderRadius:"0.5rem",backgroundColor:"#40255F",padding:"0.5rem",display:"flex",alignItems:"center",justifyContent:"center",fontFamily:"Public Sans, sans-serif ",cursor:"pointer"}}>Home</div>
          <div onClick={()=>{user && user.userType==='business' && navigate('/dashboard/expenses')}} style={{width:"5rem",height:"2.5rem",borderRadius:"0.5rem",backgroundColor:"#40255F",padding:"0.5rem",display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer",fontFamily:"Public Sans, sans-serif "}}>Expenses</div>
          <div onClick={()=>{navigate('/dashboard/profile')}} style={{width:"5rem",height:"2.5rem",borderRadius:"0.5rem",backgroundColor:"#40255F",padding:"0.5rem",display:"flex",alignItems:"center",justifyContent:"center",fontFamily:"Public Sans, sans-serif ",cursor:"pointer"}}>Profile</div>
        
        
          <div 
         
        
          style={{width:"7rem",gap:"1rem",display:"flex",alignItems:"center",justifyContent:"center"}}
        >
         
          <AccountPopover />
        </div>
        
        </div>}
        

        
      </StyledToolbar>
    </StyledRoot>
  );
}
