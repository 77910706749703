import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import ProfileForm from 'src/components/login/profileForm';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import JobPostingForm from 'src/components/login/jobPostingForm';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import folder from 'src/assets/images/folder.png'
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobsWithCandidatesPendingOffer,fetchJobInFocus } from 'src/redux/actions/group.action';
import { saveCandidateInFocus } from 'src/redux/reducers/group.slice';




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
   // fontFamily:"Libre Baskerville, sans serif",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));

// ----------------------------------------------------------------------

export default function CandidatesPendingOfferPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [companyName, setCompanyName] = useState('');

  const { user } = useSelector((state) => state.auth);
  //console.log("Logged in user is-->",user)

  const { candidatesPendingOffer,jobsPendingOffer, isLoading } = useSelector((state) => state.group);

  //console.log("candidates pending offer is-->",candidatesPendingOffer)
  //console.log("jobs pending offer is-->",jobsPendingOffer)

  const rows= [
    {interviewRound:"IT-544", typeOfInterview:"Chris Dalton",feedback:"0 Days", duration:"Sr. Full Stack Developer"},
    {interviewRound:"IT-522", typeOfInterview:"Michelle Smith",feedback:"3 Days", duration:"Data Architect"},
    {interviewRound:"OPS-322", typeOfInterview:"Glen Jackson",feedback:"1 Day", duration:"Director of People Operations"},
    
  ];

  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])


  useEffect(()=>{

    if(user){
    dispatch(fetchJobsWithCandidatesPendingOffer(user && user.id?user && user.id:user & user.uid?user.uid:" "));
    }


  },[user])
  

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };



  return (
    <>
      <Helmet>
        <title> Action Items | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       
        <Container style={{marginTop:"0rem",width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         



      
       {/*THIRD SECTION  */}
            <Typography variant="h2" gutterBottom style={{marginTop:"0rem",width:"max-content",/*color:"#40255F",*/display:"flex",justifyContent:"center"}}>
            Candidates Pending Offer Letter
            </Typography>
   
          {/*<Divider style={{width:"100%",marginBottom:"3rem"}}/>*/}

          <Typography variant="body2" sx={{ mt: 3,mb:10, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem",textAlign:"left" }}>
          Please keep in mind that the candidates mentioned in the job postings are in the final stage of
           the interview process and awaiting your final decision. Before sending an offer letter,
            if you have any additional questions about a candidate, we suggest using our communication
             tool to contact the Talent Expert assigned to the job posting. It is important to consider that 
             candidates may need some time to evaluate the offer or discuss it with their support system.
              We believe that a three to five business day timeframe is ideal for making a decision to accept
               or reject the offer. If a candidate takes longer than three days to respond, please reach
                out to OneRecruiter Support.
              
            </Typography>



           
            


            
            <div style={{padding:"1rem",border:"2px solid lightgray",borderRadius:"0.5rem",width:"66rem"}}>
            <TableContainer sx={{py:"0rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black"}}>

                          <TableCell style={{fontSize:"1.2rem",color:"black",fontFamily:"Public Sans, sans-serif", }} align="center">JP ID</TableCell>
                          <TableCell style={{fontSize:"1.2rem",color:"black",fontFamily:"Public Sans, sans-serif", }} align="center">Candidate Name</TableCell>
                          
                          <TableCell style={{fontSize:"1.2rem",color:"black",fontFamily:"Public Sans, sans-serif", }} align="center">Time Lapsed</TableCell>
                          <TableCell style={{fontSize:"1.2rem",color:"black" ,fontFamily:"Public Sans, sans-serif",}} align="center">Title</TableCell>
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {candidatesPendingOffer && candidatesPendingOffer.length? candidatesPendingOffer.map((row,index) => (
                          
                           <TableRow
                           key={index}
                           sx={{my:2, '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                           onClick={()=>{dispatch(saveCandidateInFocus(row)) ;dispatch(fetchJobInFocus(row.jobId));navigate('/dashboard/candidates-pending-offer-candidate-expanded',{state:{candidateObj:row}})}}
                           
                         >
                           <TableCell sx={{ backgroundColor:"#e0dcdc",width:"28%"}}  scope="row">  
                           
                         <TextField  
                           name="interview round"
                           inputProps={{
                             style: {
                             height:'0.6rem'
                             ,fontFamily:"Public Sans, sans-serif !important",
                             },
                             }}
                           placeholder='Round -1'
                           value={/*row.candidateLocation*/"IT-22"}
                           onChange={(e) => setCompanyName(e.target.value)}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                         />
                           
                     
                           </TableCell>


                           {<TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="type of interview"
                           inputProps={{
                             style: {
                             height:'0.6rem'
                             ,fontFamily:"Public Sans, sans-serif !important",
                             },
                             }}
                           placeholder=''
                           value={ (row && row.firstName) + " " + (row && row.lastName && row.lastName.slice(0,1) + ".")}
                           onChange={(e) => setCompanyName(e.target.value)}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                         />  

                           </TableCell>}


                         {/*  <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                          <TextField 
                          name="duration"
                          inputProps={{
                           style: {
                           height:'0.6rem'
                           ,fontFamily:"Public Sans, sans-serif !important",
                           },
                           }}
                          placeholder='60 mins'
                          value={row.jobRole}
                          onChange={(e) => setCompanyName(e.target.value)}
                          style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                          />  
                          
                         </TableCell> */}


                          <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>

                         <TextField 
                         name="feedback"
                         inputProps={{
                           style: {
                           height:'0.6rem'
                           ,fontFamily:"Public Sans, sans-serif !important",
                           },
                           }}
                         placeholder=''
                         value={`${ Math.round(((new Date().getTime()/1000) - (row/*candidatesPendingOffer.filter((candidate)=>(candidate.jobId === row.id))[0] && candidatesPendingOffer.filter((candidate)=>(candidate.jobId === row.id))[0]*/.pendingSelectionPeriodStarted.seconds ))/86400) } Days` /*new Date(row.pendingOfferPeriodStarted.seconds*1000).toDateString()*/ }
                         onChange={(e) => setCompanyName(e.target.value)}
                         style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif !important",}}
                         />  
                         
                         </TableCell>

                          <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>

                        <TextField 
                         name="feedback"
                         inputProps={{
                           style: {
                           height:'0.6rem'
                           ,fontFamily:"Public Sans, sans-serif !important",
                           },
                           }}
                         placeholder=''
                         value={row.jobRole}
                         //onChange={(e) => setCompanyName(e.target.value)}
                         style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif !important",}}
                         />  
                         
                         </TableCell>



                        {/* <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>
                         <LoadingButton onClick={()=>{addToTheRecruiterJobs(user && user.id,row.id,user &&user.firstName,user && user.lastName)}}
                          sx={{bgcolor:"#40255F",fontFamily:"Public Sans, sans-serif",width:"10rem",padding:"1rem"}}   type="submit" variant="contained" disabled={false}>
                         { "Add Job"}
                        </LoadingButton>
                         </TableCell>*/}

                         </TableRow>
                       )):
                       <TableRow  sx={{marginTop:"0.5rem",fontFamily:"Public Sans, sans-serif !important",width:"100%"}}>
                       <TableCell colSpan={5}>  <center style={{display:"flex",justifyContent:"center",padding:"0.5rem",width:"100%",color:"gray",fontSize:"22px",fontWeight:"800"}}> Candidates with offers pending will be displayed here.. </center></TableCell>
                       </TableRow>
                      }
                      </TableBody>
                    </Table>
                </TableContainer>
            </div>
   

           
            {/*<JobPostingForm />*/}
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
