import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TableHead, TableRow, TableCell, TableContainer, Paper, Table, TableBody } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import ProfileForm from 'src/components/login/profileForm';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import JobPostingForm from 'src/components/login/jobPostingForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidatesPendingReview } from 'src/redux/actions/group.action';
import { tableCellClasses } from "@mui/material/TableCell";
import JobViewingForm from 'src/components/login/jobViewingForm';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
    //fontFamily:"Libre Baskerville, sans serif"
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));

// ----------------------------------------------------------------------

export default function ViewJobPostedPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  

  useEffect(()=>{

    if(!location.state.currentJob.id){
      navigate(-1)
    }
  
    //review function fetchCandidatesPendingReview on this page
    dispatch(fetchCandidatesPendingReview());
    
   
  
  },[])


  const { candidatesPendingReview,jobsPendingReview, isLoading } = useSelector((state) => state.group);

  const rows= [
    {interviewRound:"Helena H.", status:"In progress", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/7"},
    {interviewRound:"Oscar D.", status:"In progress", position:"Lead Application Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/8"},
    {interviewRound:"Carlo E.", status:"In progress", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"3/7"},


    {interviewRound:"Daniel P.", status:"In progress", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/7"},
    {interviewRound:"Mark R.", status:"In progress", position:"Lead Application Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/8"},
    {interviewRound:"George L.", status:"In progress", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"3/7"},
    
  ];

  return (
    <>
      <Helmet>
        <title> Posted Job | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       
        <Container style={{marginTop:"6rem",width:"100%",position:"relative"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         
           <p onClick={()=>{navigate(-1)}}
              style={{position:"absolute",left:"-0%",top:"-0%",display:"flex",alignItems:"center",gap:"0.5rem"}}> 
             <FaRegArrowAltCircleLeft/> 
             Back 
             </p>



      
       {/*THIRD SECTION  */}
            <Typography variant="h2" gutterBottom style={{marginTop:"0rem",width:"44rem",color:"#40255F",display:"flex",justifyContent:"center"}}>
            {/*Job Posting Form*/} {location.state && location.state.currentJob.jobRole }
            </Typography>
   
          <Divider style={{width:"100%",marginBottom:"3rem"}}/>

          <Typography variant="body2" sx={{ mt: 1,mb:10, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem" }}>
          {/*Please complete the form below with as much detail as possible. The information you provide will help 
          recruiters communicate the company's culture, mission, and how the role fits within the organization.
           Additionally, it will assist us in identifying the ideal candidate for the position based on their
            background experience. Once you submit this form, a Business Account onboarding email will be sent
             immediately. Upon completing the "How to" Demo and company profile information, a Talent Expert 
             will be assigned to your job posting immediately. Please reach out to your Account Representative, 
          listed in onboarding email.*/}
           Company Name:{' '} {location.state && location.state.currentJob.businessName }
           <br/>
           View job details below for more Infomration.
               
              
            </Typography>
           

           
            <JobViewingForm job={location.state && location.state.currentJob} />

            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}





     <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",justifyContent:"flex-start",marginTop:"4rem",paddingLeft:"2rem",width:"85%"}}>
              Candidates Added
            </Typography>
           

     <Divider style={{width:"80%",marginTop:"2rem",marginBottom:"3rem",border:"2px solid lightgrey"}}/>

        


        
         
<TableContainer sx={{py:"2rem", width: "95%"}} component={Paper}>
        
        <Table sx={{}} aria-label="simple table">
          <TableHead>

            <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black"}}>

              <TableCell style={{color:"#40255F",fontSize:"24px",fontWeight:"bold"}} align="center">Candidate Name</TableCell>
              <TableCell style={{color:"#40255F",fontSize:"24px",fontWeight:"bold"}} align="center">Reviewed</TableCell>
              <TableCell style={{color:"#40255F",fontSize:"24px",fontWeight:"bold"}} align="center">Decision</TableCell>
              {/*<TableCell align="center">Candidate Reviewed</TableCell>*/}
             
             
            </TableRow>
          </TableHead>
          <TableBody>
          {candidatesPendingReview && candidatesPendingReview.length ? candidatesPendingReview.filter((item)=>(item.jobId === location.state.currentJob.id)).map((row,index) => (
              <TableRow onClick={()=>{/*navigate('/dashboard/candidates-pending-review-candidate-expanded',{state:{candidateObj:row}} )*/}}
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                 ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                
                 [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none"
                },
                
                }}
              >
                <TableCell  align="center"  sx={{my:3, backgroundColor:"transparent"}}  >  
                
              
                <p style={{color:"#40255F",fontSize:"22px",fontWeight:"600"}}>{(row.firstName && row.firstName) + " " + (row.lastName && row.lastName).substring(0,1)+"."}</p>
               
                <p style={{color:"gray",fontSize:"14px"}}>{row.jobRole}</p>
          
                </TableCell>


                <TableCell align="center"  sx={{my:2, backgroundColor:"transparent",color:"gray"}}>

                
                {row && row.initialReview?'Yes':'No'}
                

                </TableCell>


              {/*  <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

               <TextField 
               name="duration"
               
               placeholder='60 mins'
               value={row.duration}
               onChange={(e) => setCompanyName(e.target.value)}
               style={{color:"grey",backgroundColor:"white",borderRadius:"1rem",border:"1px solid black"}}
               />  
               
               </TableCell> */}


               <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"transparent",color:"gray"}}>

              
              {rows[0].feedback}
              
              
              </TableCell>

              </TableRow>
            )):
            
            <TableRow  sx={{marginTop:"0.5rem",fontFamily:"Public Sans, sans-serif !important",width:"100%"}}>
            <TableCell colSpan={5}>  <center style={{display:"flex",justifyContent:"center",padding:"0.5rem",width:"100%",color:"gray",fontSize:"22px",fontWeight:"800"}}> Candidates for job will be displayed here.. </center></TableCell>
            </TableRow>
            
            }
          </TableBody>
        </Table>
    </TableContainer>
   

    <Divider style={{marginTop:"4rem",width:"80%",marginBottom:"3rem",border:"2px solid lightgrey"}}/>

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
