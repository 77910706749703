import { db, fb, auth, storage } from '../../config/firebase';
import { clearUser, loginFailed, loginSuccess, logoutFxn, setDemo, setRecruiter, signupFailed, storeUserData } from '../reducers/auth.slice';
import { v4 as uuidv4 } from 'uuid';
import { notifyErrorFxn, notifySuccessFxn } from 'src/utils/toast-fxn';
import { clearGroup } from '../reducers/group.slice';
import resetDemoUser from './helperActions/resetDemoUser'
//import sgMail from '@sendgrid/mail'

import welcomeEmailTemplate from 'src/config/welcomeEmailTemplate';
import axios from 'axios';


  export const signinBusiness = (user, navigate, setLoading) => async (dispatch) => {
    fb.auth().signInWithEmailAndPassword(user.email, user.password)
    .then((userCredential) => {
      // Signed in//
      var user = userCredential.user;
      //console.log('Signed In user is: ', user.email);
      
   //value resetting for demo user
  if(user && user.uid === "FzHaFDUVrQNZOP4keX8t5cJlvm32"){

    resetDemoUser(user.uid) 
  }
   return user
    }).then((user)=>{
      dispatch(fetchUserDataBusiness(user.uid, "sigin", navigate, setLoading));

      dispatch(setRecruiter(false));
      dispatch(setDemo(true))
    })
    .catch((error) => {
      setLoading(false);
      var errorCode = error.code;
      var errorMessage = error.message;
      notifyErrorFxn("Failed to sign in, please check your details and try again");
      //console.log('Error Code is: ', errorCode, + ' Msg is: ', errorMessage);
      dispatch(loginFailed(errorMessage));
    });

};

export const signinRecruiter = (user, navigate, setLoading) => async (dispatch) => {
  fb.auth().signInWithEmailAndPassword(user.email, user.password)
  .then((userCredential) => {
    // Signed in//
    var user = userCredential.user;
    //console.log('Signed In user is: ', user.email);
    
 //value resetting for demo user
if(user && user.uid === "FzHaFDUVrQNZOP4keX8t5cJlvm32"){

  resetDemoUser(user.uid) 
}
 return user
  }).then((user)=>{
    dispatch(fetchUserDataRecruiter(user.uid, "sigin", navigate, setLoading));
    dispatch(setRecruiter(true));dispatch(setDemo(false))
  })
  .catch((error) => {
    setLoading(false);
    var errorCode = error.code;
    var errorMessage = error.message;
    notifyErrorFxn("Failed to sign in, please check your details and try again");
    //console.log('Error Code is: ', errorCode, + ' Msg is: ', errorMessage);
    dispatch(loginFailed(errorMessage));
  });

};


export const signup = (user, navigate, setLoading) => async (dispatch) => {
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  var today  = new Date();
  const date = today.toISOString();
 
  db.collection('candidates')                                                    // i am not actively doing anything with these 3 lines of code, i just dont want to delete in case of the future may 7 2024
  //  .where('employerNumber', '==', parseInt(user.employeer))        // i am not actively doing anything with these 3 lines of code, i just dont want to delete in case of the future may 7 2024
    .get()        // i am not actively doing anything with these 3 lines of code, i just dont want to delete in case of the future may 7 2024
    .then((snapshot) => {
      //const employeer = snapshot.docs.map((doc) => ({ ...doc.data() })); maybe this will come in handy later - may 7 2024
      /*if (employeer.length) { */ 
        //console.log('Employeer Exist:', employeer[0].cooler);
        fb.auth().createUserWithEmailAndPassword(
          user.email,
          'efG9dL'/*user.password*/
      ).then(async(res)=>{
        return db.collection('businesses').doc(res.user.uid).set({
          id: res.user.uid,
          email: user.email,
          firstName: user.fname,
          lastName: user.fname,
          acctHolderName:user.acctHolderName,
          bestContactNo:user.bestContactNo,
          positionTitle:user.positionTitle,
          companyName:user.companyName,
          companyWebsite:user.companyWebsite,
          companySocMedia:user.companyWebsite,
          companyEmail:user.companyEmail,
          ceoName:user.ceoName,
          yearFounded:user.yearFounded,
          companyBio:user.companyBio,
          additionalBenefits:user.additionalBenefits,
          employeeBenefits:user.employeeBenefits,
          companyCulture:user.companyCulture,
          companyOnboarding:user.companyOnboarding,
          howDidYouHear:user.howDidYouHear,
          password: 123456,
          recruiterJobs: [],
          userType:"business",
          //employeerNumber: user.employeer,
          //employeerID: employeer[0].id,
          //accruedBalance: 0,
          //walletBalance: 1000,
          accountCreated: today.toLocaleDateString("en-US", options),
        }).then(() => {
        /* return db.collection('inbox')
          .add({
              id: res.user.uid,
              msg: 'Welcome to Cooler. Thank you for joining us!',
              isViewed: false,
              unread: 0,
              time: date,
          })*/
  
         // sgMail.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY)

          const msg = {
            to: `${user.email}`,
            from: `contactus@one-recruiter.com` ,
            subject:"One Recruiter - Business Account Setup",
            text:"Welcome to One Recruiter",
            html:welcomeEmailTemplate,
          
          }
          
         // sgMail.send(msg)
         axios.post(/*'https://sendgridserver-seven.vercel.app/api/om/welcome'*/'http://localhost:5008/api/om/welcome', {
          message:JSON.stringify(msg)
          })
        .then(()=>{/*console.log("Message sent")*/})
          .catch((error)=>{
            console.error("error sending msg",error)
          })
          
           
           /* fb THIS WAS IN USE BEFORE I INTRODUCED SENDGRID
            .auth()
            .sendPasswordResetEmail(user.email)*/

        })
      }).then(() => {
        notifySuccessFxn("Applied Successfully✔😊")
        navigate('/success-application', { replace: true });
      }).catch((err) => {
        console.error("Error signing up/signing business up-->: ", err);
        var errorMessage = err.message;
        notifyErrorFxn("Error signing up, please check your email and try again.");
        dispatch(signupFailed({ errorMessage }));
        setLoading(false);
      })

    /*  } else {
        setLoading(false);
       
        notifyErrorFxn("Invalid Employeer Code");
      }*/
    }).catch((error) => {
      setLoading(false);
      //console.log('Error querying collection:', error);
    });
}


export const forgotPassword = (email, setLoading, setForgotPassword) => async (dispatch) => {
       setLoading(true);


       //sgMail.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY)

const msg = {
  to: `${email}`,
  from: `contactus@one-recruiter.com` ,
  subject:"One Recruiter - Business Account Setup",
  text:"Welcome to One Recruiter",
  html:welcomeEmailTemplate,

}

//sgMail.send(msg)
 axios.post(/*'https://sendgridserver-seven.vercel.app/api/om/welcome'*/'http://localhost:5008/api/om/welcome', {
  message:JSON.stringify(msg)
  })
.then(() => {
  setLoading(false);
  notifySuccessFxn('Password reset email sent. Check your inbox for instructions.');
  setForgotPassword(false);
})
.catch(error => {
  setLoading(false);
  notifyErrorFxn(`Error sending password reset email: ${error.message}`);
  // setMessage(`Error sending password reset email: ${error.message}`);
});
};

      // fb THIS ONE WAS IN USE B4 I COMMENTED IT OUT
      //.auth()
      //.sendPasswordResetEmail(email)
     


export const uploadImage = (user, file, navigate, setLoading) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
 
  const uploadTask = storage.ref(`profile_images/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
      //console.log(error);
    },
    () => {
      storage
        .ref("profile_images")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
          //console.log('Image URL: ', url);
          dispatch(signup(user, file, navigate, setLoading, url));
        });
    }
  );
}


export const fetchUserDataBusiness = (id, type, navigate, setLoading) => async (dispatch) => {
  var user = db.collection(/*'employees'*//*'recruiters'*/'businesses').doc(id);
  user.get().then((doc) => {
  if (doc.exists) {
    // console.log("User Data:", doc.data());
    dispatch(storeUserData(doc.data()));
    if(type === "sigin"){
      notifySuccessFxn("Logged In😊");
      navigate('/dashboard/home', { replace: true });
    }
  } else {
      setLoading(false);
      notifyErrorFxn("Unauthorized❌")
      //console.log("No such document!");
  }
}).catch((error) => {
  //console.log("Error getting document:", error);
});
return user;
};


export const fetchUserRecruiter = (id, type, navigate, setLoading) => async (dispatch) => {
  var user = db.collection("recruiters").doc(id);
  user.get().then((doc) => {
  if (doc.exists) {
    // console.log("User Data:", doc.data());
    dispatch(storeUserData(doc.data()));
    if(type === "sigin"){
      notifySuccessFxn("Logged In😊");
      navigate('/dashboard/home', { replace: true });
    }
  } else {
      setLoading(false);
      notifyErrorFxn("Unauthorized❌")
      //console.log("No such document!");
  }
}).catch((error) => {
  //console.log("Error getting document:", error);
});
return user;
};


export const fetchUserDataRecruiter = (id, type, navigate, setLoading) => async (dispatch) => {
  var user = db.collection("recruiters").doc(id);
  user.get().then((doc) => {
  if (doc.exists) {
    // console.log("User Data:", doc.data());
    dispatch(storeUserData(doc.data()));
    if(type === "sigin"){
      notifySuccessFxn("Logged In😊");
      navigate('/dashboard/home', { replace: true });
    }
  } else {
      setLoading(false);
      notifyErrorFxn("Unauthorized❌")
     // console.log("No such document!");
  }
}).catch((error) => {
 // console.log("Error getting document:", error);
});
return user;
};


export const uploadProfileImage = (profileData, file, userID, navigate, setLoading) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
  //console.log('File Name: ', imageName);
  const uploadTask = storage.ref(`profile_images/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
     // console.log(error);
    },
    () => {
      storage
        .ref("profile_images")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
         // console.log('Image URL: ', url);
          dispatch(updateProfile(profileData, userID, file, navigate, setLoading, url));
        });
    }
  );
}


export const updateProfile = (profileData, userID, file, navigate, setLoading, url) => async (dispatch) => {
  // return  
  db.collection(/*'employees'*/ 'recruiters').doc(userID).update({
    paymentLink:profileData && profileData.paymentLink? profileData.paymentLink:"",
    imageUrl: url,
  }).then((res)=>{
       if(profileData?.password){
        //update password start
        const user = auth.currentUser;
        user.updatePassword(profileData.password)
          .then(() => {
            setLoading(false);
           // console.log("Password updated successfully");
            notifySuccessFxn("Updated successfully");
            navigate('/dashboard/home', { replace: true });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error updating password: ", error);
            notifyErrorFxn(error.message);
          });
       //update password end
       }else{
        setLoading(false);
        console.error("No Password to update");
        notifySuccessFxn("Updated successfully");
        navigate('/dashboard/home', { replace: true });
       }
     
  }).catch((err) => {
    setLoading(false);
    //console.log("ERR-: ", err);
  })
}


export const logout = (navigate,user) => async (dispatch) => {
 
   
   //value resetting for demo user 
  if(user && user.id === "FzHaFDUVrQNZOP4keX8t5cJlvm32"){

    resetDemoUser(user.id) 
 

   db.collection(/*'employees'*/'recruiters')
   .doc(user.id)
   .update({
     walletBalance: 1000,
     accruedBalance:0,
     coolers: [],
   }).then(()=>{

   
    fb.auth().signOut().then(() => {
      dispatch(logoutFxn());
      dispatch(clearUser());
      dispatch(clearGroup());
      navigate('/home', { replace: true });
     // console.log('logout successful!');
    }).catch((error) => {
      // An error happened.
     // console.log('logout failed response: ', error.message);
    });

   })
  
  //value resetting for demo user END (closing bracket for if statement below)
  }else{
  
 
  fb.auth().signOut().then(() => {
    dispatch(logoutFxn());
    dispatch(clearUser());
    dispatch(clearGroup());
    navigate('/home', { replace: true });
    //console.log('logout successful!');
  }).catch((error) => {
    // An error happened.
   // console.log('logout failed response: ', error.message);
  });
  
}

}