import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Grid, FormControlLabel, RadioGroup, Radio, FormControl, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaBell, FaCircle } from "react-icons/fa";
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';


import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import ProfileForm from 'src/components/login/profileForm';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import JobPostingForm from 'src/components/login/jobPostingForm';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import samplecv from 'src/assets/images/samplecv.png'
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { acceptCandidateToOffer,rejectCandidateForOffer, updateCandidateToOfferReviewed ,updateCandidateVideoRecordingRound1, updateCandidateVideoRecordingRound2} from 'src/redux/actions/group.action';

import oneRecruiterLogo from "src/assets/images/Cooler.png"
import { saveFeedbackFormDetails } from 'src/redux/reducers/group.slice';
import VideoModal from 'src/components/video/videoModal'
import Modal from '@mui/material/Modal';
import sampleVideo    from 'src/assets/videos/sample-job-interview.mp4'
import { FaPencil } from 'react-icons/fa6';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
   // fontFamily:"Libre Baskerville, sans serif",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
 // maxWidth: 1200,
 width:"100%",
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

export default function CandidatesPendingOfferPageExpanded() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  
  
  const [companyName, setCompanyName] = useState('');

  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companySocMedia, setCompanySocMedia] = useState('');
  const [feedBackScreen,setFeedBackScreen] = useState(false)
  const [rejectionReason,setRejectionReason] = useState('')

  const [hireRatingReason,setHireRatingReason] = useState('')
  
  const [candidateAlignmentRatingReason,setCandidateAlignmentRatingReason] = useState('')
  const [candidateAnswerRatingReason,setCandidateAnswerRatingReason] = useState('')
 
  const [areasOfConcernRatingReason,setAreasOfConcernRatingReason] = useState('')

 /* const [RatingReason,setRatingReason] = useState('')
  const [RatingReason,setRatingReason] = useState('')*/

  
  const { user } = useSelector((state) => state.auth);

  const [candidateObj,setCandidateObj] = useState(location.state && location.state.candidateObj?location.state.candidateObj:{firstName:"Chris",lastName:"Smokes",jobRole:"Software Developer"} );
  
 
  const { jobInFocus,feedbackFormDetails,candidateInFocus } = useSelector((state) => state.group);

  const [feedBackForm,setFeedBackForm] = useState(false)
  const [hiringDecision,setHiringDecision] = useState('')
  const [jobRole,setJobRole] = useState('')

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openInterviewEdit1, setOpenInterviewEdit1] = useState(false);
  const handleCloseInterviewEdit1 = () => setOpenInterviewEdit1(false);

  const [openInterviewEdit2, setOpenInterviewEdit2] = useState(false);
  const handleCloseInterviewEdit2 = () => setOpenInterviewEdit2(false);


  const [videoRecordingRound1,setVideoRecordingRound1] = useState('')
  const [videoRecordingRound2,setVideoRecordingRound2] = useState('')



  //console.log("JOB IN FOCUS IS--->",jobInFocus)


  useEffect(()=>{

if(candidateInFocus){
  setCandidateObj(candidateInFocus)
}

  },[candidateInFocus])


  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])


  const handleViewPdf = (pdfUrl) => {
    window.open(
      `https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`,
      'PDF Viewer',
      'width=1000,height=800'
    );
  };

  



  const rows= [
    {interviewRound:"Michael Thompson.", position:"Interview Lead", typeOfInterview:'Basic Interview ',reviewed:"No", duration:"0/7"},
    {interviewRound:"Tamara Jackson.", position:"Interview Lead", typeOfInterview:'Basic Interview ',reviewed:"No", duration:"0/8"},
    {interviewRound:"Jack Johnson.", position:"Interview Lead", typeOfInterview:'Basic Interview ',reviewed:"No", duration:"3/7"},


  ];

  const rows2 = [
    {interviewRound:"Michelle Conner.", position:"Interview Lead", typeOfInterview:"Executive Interview",reviewed:"No", duration:"0/7"},
    {interviewRound:"Jonathan Carper", position:"Interview Lead", typeOfInterview:"Executive Interview",reviewed:"No", duration:"0/8"},
  ]

  const rows3 = [
    {interviewRound:"Jose Martinez", position:"Interview Lead", typeOfInterview:"HR Interview",reviewed:"Yes", duration:"3/7"},
  ]


  const rows4 = [
    {interviewRound:"Chris Dalton", position:"Sr. Full Stack Developer", reviewed:"Yes",signed:"No"},
  ]


  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };


  const inputStyleLink = {
    background: 'white',
    marginRight: '30px',
    width: '40rem',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  
  };


  const inputContainerBig = {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    width:'100%',
   
  };



  return (
    <>
      <Helmet>
        <title> Candidate Pending Selection | oneRecruiter </title>
      </Helmet>

      <StyledRoot onClick={()=>{{setFeedBackForm(false);setFeedBackScreen(false);   }}}>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}


    
     

       
        <Container style={{marginTop:"0rem",width:"100%",position:"relative"}}>


     {
     
     <Modal
     open={open}
     onClose={handleClose}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
   >
     <Box sx={style}>
     <video  width="1050" height="900" controls >
     <source src={candidateObj && candidateObj.videoRecordingRound1?candidateObj.videoRecordingRound1 :sampleVideo} type="video/mp4"/>
     </video>
     </Box>
   </Modal>}



   { /**FOR EDITING INTERVIEW LINK  ROUND 1*/
     
     <Modal
     open={openInterviewEdit1}
     onClose={handleCloseInterviewEdit1}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
   >
     <Box sx={style}>
      
   <Grid xs={12} style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>

           <Typography variant="h6" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#000000",display:"flex",justifyContent:"flex-start"}}>
              ROUND 1
              </Typography>



           <Typography variant="h6" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#000000",display:"flex",justifyContent:"flex-start"}}>
              Current Link
              </Typography>
              
              
                <Grid container xs={12} style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
                  <b style={{ fontSize:'13px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}></b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      disabled={true}
                      placeholder='no link attached'
                      value={candidateObj && candidateObj.videoRecordingRound1}
                      //onChange={(e) => setJobRole(e.target.value)}
                      style={inputStyleLink}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>




                <Typography variant="h6" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#000000",display:"flex",justifyContent:"flex-start"}}>
              New Link (enter link)
              </Typography>
              
              
                <Grid container xs={12}  style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
                  
                  <div style={inputContainer}>
                    <TextField
                      name="new link"
                      required
                      placeholder='e.g https://.google.com'
                      value={videoRecordingRound1}
                      onChange={(e) => setVideoRecordingRound1(e.target.value)}
                      style={inputStyleLink}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>


                <Grid item  xs={12} style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"1rem",marginTop:"2rem"}} >
                  
                  <Button onClick={()=>{setFeedBackForm(false);dispatch(updateCandidateVideoRecordingRound1(candidateObj && candidateObj.id,videoRecordingRound1) )}} 
                  style={{width:"8rem",color:"black",paddingLeft:"0.8rem",paddingRight:"0.8rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",fontFamily:"Public Sans, sans-serif",fontSize:"0.7rem"}}>Submit</Button>

                 </Grid>


</Grid>
     </Box>
   </Modal>}



   { /**FOR EDITING INTERVIEW LINK  ROUND 2*/
     
     <Modal
     open={openInterviewEdit2}
     onClose={handleCloseInterviewEdit2}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
   >
     <Box sx={style}>
      
   <Grid xs={12} style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>

           <Typography variant="h6" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#000000",display:"flex",justifyContent:"flex-start"}}>
              ROUND 2
              </Typography>



           <Typography variant="h6" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#000000",display:"flex",justifyContent:"flex-start"}}>
              Current Link
              </Typography>
              
              
                <Grid container xs={12} style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
                  <b style={{ fontSize:'13px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}></b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      disabled={true}
                      placeholder='no link attached'
                      value={candidateObj && candidateObj.videoRecordingRound2}
                      //onChange={(e) => setJobRole(e.target.value)}
                      style={inputStyleLink}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>




                <Typography variant="h6" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#000000",display:"flex",justifyContent:"flex-start"}}>
              New Link (enter link)
              </Typography>
              
              
                <Grid container xs={12}  style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
                  
                  <div style={inputContainer}>
                    <TextField
                      name="new link"
                      required
                      placeholder='e.g https://.google.com'
                      value={videoRecordingRound2}
                      onChange={(e) => setVideoRecordingRound2(e.target.value)}
                      style={inputStyleLink}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>


                <Grid item  xs={12} style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"1rem",marginTop:"2rem"}} >
                  
                  <Button onClick={()=>{setFeedBackForm(false);dispatch(updateCandidateVideoRecordingRound2(candidateObj && candidateObj.id,videoRecordingRound2) )}} 
                  style={{width:"8rem",color:"black",paddingLeft:"0.8rem",paddingRight:"0.8rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",fontFamily:"Public Sans, sans-serif",fontSize:"0.7rem"}}>Submit</Button>

                 </Grid>


</Grid>
     </Box>
   </Modal>}



        
       {feedBackScreen && 
        <div onClick={(e)=>{e.stopPropagation()}}
         style={{position:"absolute",top:"15%",left:"13%",backgroundColor:"#40255F",color:"white",zIndex:"200",width:"44rem",padding:"50px",paddingTop:"20px",paddingBottom:"18px",fontFamily:"Libre Baskerville"}}> 

           
        
                  <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"2rem" }}>
                  
                  <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                  <h3 style={{fontSize:"13px",fontWeight:"700"}}>{candidateObj && candidateObj.firstName + " " + candidateObj.lastName.substring(0,1)}</h3>
                  {/*<i>Posted: {new Date(candidateObj &&  candidateObj.pendingReviewPeriodStarted.seconds*1000).toDateString()}</i>*/}
                  <p  style={{fontSize:"12px",fontWeight:"900"}}>Job Posting ID: IT-543</p> 
                  <p  style={{fontSize:"12px",fontWeight:"900"}}>{candidateObj.jobRole}</p> 
                  </div>
                  
                  
                  <div >
                 <img src={oneRecruiterLogo} style={{height:"3rem",width:"5rem"}}/>
                   
                   </div>
               </div>



               <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12} style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}>
                  <b style={{ fontSize: '12px', width: '95%',position:"relative",top:"-0.3rem",marginBottom:"0.6rem"  }}>Please Submit your Feedback on why this candidate is not moving forward*</b>
                  <div style={inputContainerBig}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={2} 
                    name="Rejection Reason"
                    placeholder='Add Answer Here...'
                    value={rejectionReason}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"0.7rem",marginBottom:"1rem",color:"black"}}
                    onChange={(e) => setRejectionReason(e.target.value)}
                    
                    >
                    </textarea>





                  </div>
                </Grid>

                
                
              </Grid>

                <div style={{fontSize:"0.7rem",fontWeight:"bold",marginBottom:"1rem",marginTop:"0.8rem"}}>
                  Please Rate the Quality of the candidate
                  </div>

              <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"1.5rem" }}>



              <div style={{width:"40%",display:"flex",flexDirection:"column",gap:"0.8rem",alignItems:"flex-start"}}> 
               
                  <div style={{fontSize:"0.7rem"}}>
                   How well did the candidate's profile align to this position ?
                  </div>

                  <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"0.3rem"}}>
                  <StarIcon style={{fontSize:"0.8rem"}}/>
                  <StarIcon style={{fontSize:"0.8rem"}}/>
                  <StarIcon style={{fontSize:"0.8rem"}}/>
                  <StarOutlineIcon style={{fontSize:"0.8rem"}}/>
                  <StarOutlineIcon style={{fontSize:"0.8rem"}}/>
                  </div>


               </div>


               <div style={{width:"40%",display:"flex",flexDirection:"column",gap:"0.8rem",alignItems:"flex-start"}}> 
              

                 <div style={{fontSize:"0.7rem"}}>
                   How well did the candidate align to your company's culture ?
                  </div>

                    
              
                  <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"0.3rem"}}>
                  <StarIcon style={{fontSize:"0.8rem"}}/>
                  <StarIcon  style={{fontSize:"0.8rem"}}/>
                  <StarIcon  style={{fontSize:"0.8rem"}}/>
                  <StarOutlineIcon  style={{fontSize:"0.8rem"}}/>
                  <StarOutlineIcon  style={{fontSize:"0.8rem"}}/>
                  </div>

               </div>

              </div>




              <Grid item  xs={12} style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"1rem"}} >
                  
                  
 
 
                  <Button onClick={()=>{dispatch(rejectCandidateForOffer(candidateObj.id,candidateObj.jobId,jobInFocus.businessId,navigate));setFeedBackScreen(false)}} style={{width:"8rem",color:"red",paddingLeft:"0.8rem",paddingRight:"0.8rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",fontFamily:"Public Sans, sans-serif",fontSize:"0.7rem"}}>Reject Candidate</Button>
                  
 
                  <Button onClick={()=>{setFeedBackScreen(false)}} style={{width:"8rem",color:"black",paddingLeft:"0.8rem",paddingRight:"0.8rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",fontFamily:"Public Sans, sans-serif",fontSize:"0.7rem"}}>Cancel</Button>



                 </Grid>

        </div>
       }




{feedBackForm && 
        <div onClick={(e)=>{e.stopPropagation()}} 
         style={{position:"absolute",top:"15%",left:"20%",backgroundColor:"#FFFFFF"/*backgroundColor:"#40255F"*/,color:"black",zIndex:"200",width:"44rem",padding:"50px",paddingTop:"20px",paddingBottom:"18px",fontFamily:"Libre Baskerville",border:"3px #F5F5F5 solid"}}> 

           
        
                  <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",marginBottom:"2rem" }}>
                  
                  <Typography variant="h3" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"black",display:"flex",justifyContent:"center",textDecoration:"underline"}}>
                  Feedback Form
                 </Typography>
                  
                  
                  
               </div>



               <Typography variant="h6" gutterBottom style={{marginTop:"2rem",marginBottom:"1.5rem",fontFamily:"Libre Baskerville, sans-serif", color:"#40255F",display:"flex",justifyContent:"flex-start",textDecoration:"underline"}}>
              Panel Member Information
              </Typography>
            <Grid container spacing={1} direction="row" style={{width:"100%"}}>
            <Grid item xs={4}>
                  <b style={{ fontSize:'13px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}>Panel Memeber Name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='First and Last'
                      value={feedbackFormDetails && feedbackFormDetails.panelMemberName}
                      //onChange={(e) => setJobRole(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>
                

                <Grid item xs={4}>
                  <b style={{ fontSize:'13px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}>Job Title*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Title'
                      value={jobRole}
                      onChange={(e) => setJobRole(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>
                


                <Grid item xs={4}>
                  <b style={{ fontSize:'13px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}>Company Email*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Email'
                      value={jobRole}
                      onChange={(e) => setJobRole(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>
                
              </Grid>




              <Grid container spacing={1} direction="row" style={{width:"100%",marginTop:"1rem"}}>
           
                <Grid item xs={4}>
                  <b style={{ fontSize:'13px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}>Company Name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='ABC, LLC'
                      value={jobRole}
                      onChange={(e) => setJobRole(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>
                
              </Grid>


              <Typography variant="h6" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#40255F",display:"flex",justifyContent:"flex-start",textDecoration:"underline"}}>
              Interview Information
              </Typography>
              
              <Grid container spacing={1} direction="row" style={{width:"100%", marginTop:"1rem"}}>
                <Grid item xs={4}>
                  <b style={{ fontSize:'13px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}>Date of the Interview*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='First and Last'
                      value={jobRole}
                      onChange={(e) => setJobRole(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>
                

                <Grid item xs={4}>
                  <b style={{ fontSize:'13px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}>Candidate Name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="candidate-name"
                      required
                      placeholder='First and Last'
                      value={feedbackFormDetails && feedbackFormDetails.candidateName}
                      //onChange={(e) => setJobRole(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>
                


                <Grid item xs={4}>
                  <b style={{ fontSize:'13px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}>Job Title*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='title'
                      value={feedbackFormDetails && feedbackFormDetails.jobRole}
                      onChange={(e) => setJobRole(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          fontSize:"13px",
                          height:"0.5rem",
                          borderRadius:"0.5rem"
                        },
                    }}
                    />
                  </div>
                </Grid>
                
              </Grid>



              <Typography variant="h6" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#40255F",display:"flex",justifyContent:"flex-start",textDecoration:"underline"}}>
              Interview Feedback
              </Typography>

              <Divider style={{width:"100%",marginTop:"1rem",marginBottom:"3rem",border:"1.5px solid lightgrey"}}/>
               <div style={{width:"70%",display:"flex",flexDirection:"column",gap:"0.8rem",alignItems:"flex-start",marginBottom:"2.5rem"}}> 
               
               <div style={{fontSize:"0.75rem"}}>
                How well did the candidate align to the requirements for the role* ?
               </div>

               <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"0.3rem"}}>
               <StarIcon style={{fontSize:"0.8rem"}}/>
               <StarIcon style={{fontSize:"0.8rem"}}/>
               <StarIcon style={{fontSize:"0.8rem"}}/>
               <StarOutlineIcon style={{fontSize:"0.8rem"}}/>
               <StarOutlineIcon style={{fontSize:"0.8rem"}}/>
               </div>


            </div>

               <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12} style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}>
                  <b style={{ fontSize: '12px', width: '95%',position:"relative",top:"-0.3rem",marginBottom:"0.6rem"  }}>Please Elaborate your rating*</b>
                  <div style={inputContainerBig}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={2} 
                    name="How well did the candidate align to the requirements for the role"
                    placeholder='Add Answer Here...'
                    value={candidateAlignmentRatingReason}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"0.7rem",marginBottom:"1rem",color:"black"}}
                    onChange={(e) => setCandidateAlignmentRatingReason(e.target.value)}
                    
                    >
                    </textarea>





                  </div>
                </Grid>
 
              </Grid>



              


              <Divider style={{width:"100%",marginTop:"2rem",marginBottom:"3rem",border:"1.5px solid lightgrey"}}/>

              <div style={{width:"75%",display:"flex",flexDirection:"column",gap:"0.8rem",alignItems:"flex-start",marginBottom:"2.5rem"}}> 
               
               <div style={{fontSize:"0.75rem"}}>
                How well did the candidate answer questions related to the position* ?
               </div>

               <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"0.3rem"}}>
               <StarIcon style={{fontSize:"0.8rem"}}/>
               <StarIcon style={{fontSize:"0.8rem"}}/>
               <StarIcon style={{fontSize:"0.8rem"}}/>
               <StarOutlineIcon style={{fontSize:"0.8rem"}}/>
               <StarOutlineIcon style={{fontSize:"0.8rem"}}/>
               </div>


            </div>
              <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12} style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}>
                  <b style={{ fontSize: '12px', width: '95%',position:"relative",top:"-0.3rem",marginBottom:"0.6rem"  }}>Please Elaborate your rating*</b>
                  <div style={inputContainerBig}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={2} 
                    name="How well did the candidate answer questions?"
                    placeholder='Add Answer Here...'
                    value={candidateAnswerRatingReason}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"0.7rem",marginBottom:"1rem",color:"black"}}
                    onChange={(e) => setCandidateAnswerRatingReason(e.target.value)}
                    
                    >
                    </textarea>





                  </div>
                </Grid>
 
              </Grid>



              <Divider style={{width:"100%",marginTop:"2rem",marginBottom:"3rem",border:"1.5px solid lightgrey"}}/>

    
              <div style={{width:"75%",display:"flex",flexDirection:"column",gap:"0.8rem",alignItems:"flex-start",marginBottom:"2.5rem"}}> 
               
               <div style={{fontSize:"0.75rem"}}>
                Are there areas of concern* ?
               </div>

               <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"0.3rem"}}>
               <StarIcon style={{fontSize:"0.8rem"}}/>
               <StarIcon style={{fontSize:"0.8rem"}}/>
               <StarIcon style={{fontSize:"0.8rem"}}/>
               <StarOutlineIcon style={{fontSize:"0.8rem"}}/>
               <StarOutlineIcon style={{fontSize:"0.8rem"}}/>
               </div>


            </div>

              <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12} style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}>
                  <b style={{ fontSize: '12px', width: '95%',position:"relative",top:"-0.3rem",marginBottom:"0.6rem"  }}>Please Elaborate your rating*</b>
                  <div style={inputContainerBig}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={2} 
                    name="Areas of Concern"
                    placeholder='Add Answer Here...'
                    value={areasOfConcernRatingReason}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"0.7rem",marginBottom:"1rem",color:"black"}}
                    onChange={(e) => setAreasOfConcernRatingReason(e.target.value)}
                    
                    >
                    </textarea>





                  </div>
                </Grid>
 
              </Grid>

             

              <Divider style={{width:"100%",marginTop:"2rem",marginBottom:"3rem",border:"1.5px solid lightgrey"}}/>
              <div style={{width:"75%",display:"flex",flexDirection:"column",gap:"0.8rem",alignItems:"flex-start",marginBottom:"2.5rem"}}> 
               
               <div style={{fontSize:"0.75rem"}}>
                Do you recommend hiring this candidate* ?
               </div>

              
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup style={{color:"grey"}}
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="Yes"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel  onClick={(e)=>{setHiringDecision(e.target.value)}}  value="Yes" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}}>Yes</Typography>} />
                     <FormControlLabel onClick={(e)=>{setHiringDecision(e.target.value)}}  value="No" control={<Radio  style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}}>No</Typography>}/>
                     <FormControlLabel onClick={(e)=>{setHiringDecision(e.target.value)}}  value="Maybe" control={<Radio   style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}}>Maybe</Typography>} />
                     
                    
                   </RadioGroup>
                 </FormControl>


            </div>

              <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12} style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}>
                  <b style={{ fontSize: '12px', width: '95%',position:"relative",top:"-0.3rem",marginBottom:"0.6rem"  }}>Please Elaborate your rating*</b>
                  <div style={inputContainerBig}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={2} 
                    name="Do you recomend hiring this candidate ?"
                    placeholder='Add Answer Here...'
                    value={hireRatingReason}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"0.7rem",marginBottom:"1rem",color:"black"}}
                    onChange={(e) => setHireRatingReason(e.target.value)}
                    
                    >
                    </textarea>





                  </div>
                </Grid>
 
              </Grid>



              

             




              <Grid item  xs={12} style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"1rem"}} >
                  
                  <Button onClick={()=>{setFeedBackForm(false);dispatch(updateCandidateToOfferReviewed(candidateObj && candidateObj.id) )}} style={{width:"8rem",color:"black",paddingLeft:"0.8rem",paddingRight:"0.8rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",fontFamily:"Public Sans, sans-serif",fontSize:"0.7rem"}}>Review</Button>

                 </Grid>

        </div>
       }

 
         
         
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         
  <p onClick={()=>{navigate('/dashboard/action-items')}}
   style={{position:"absolute",left:"0%",top:"0%",display:"flex",alignItems:"center",gap:"0.5rem"}}> 
  <FaRegArrowAltCircleLeft/> 
  Candidates Pending Offer Letter
  </p>


      
       {/*THIRD SECTION  */}
          

            <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"3rem" }}>

           <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"gray"}}>
           <h1 style={{color:"black",fontSize:"30px",fontWeight:"700"}}>{candidateObj && candidateObj.firstName + " " + candidateObj.lastName.substring(0,1)}</h1>
           <i>Submitted: 3/20/2024</i>
           <i>Status:Needs Attention</i>
           <i>Talent Expert: <span style={{color:"blue",textDecoration:"underline"}}>Jessica Campbell</span></i>
           </div>


           <div >
           <p  style={{fontSize:"22px",fontWeight:"900"}}>Job Posting ID: IT-543</p> 
           <p  style={{fontSize:"22px",fontWeight:"900"}}>{candidateObj.jobRole}</p> 
            <p style={{border:"1px solid black",display:"flex",alignItems:"center",gap:"1rem",padding:"0.6rem"}}>
              Contact Talent Expert
              <FaBell/>
            </p>
            
            </div>

            </div>
   
        

        
            <Grid container xs={12} spacing={2} style={{fontFamily:"Public Sans, sans-serif",}} direction="row">
           
           
            <Grid item container xs={5} direction="row" style={{position:"relative",left:"-1rem",display:"flex",justifyContent:"flex-start",gap:"0rem"}}>
              
            
              <Grid item  xs={12} >
              {candidateObj && candidateObj.candidateResume?
                 <div onClick={()=>{handleViewPdf(candidateObj && candidateObj.candidateResume)}} style={{backgroundColor:"white",cursor:"pointer"}} > 
                  <object  data={`${candidateObj && candidateObj.candidateResume}`} type="application/pdf" width="100%" height="690px"  style={{pointerEvents: "none"}}>
                 
                  <p>Unable to display PDF file. <a href={candidateObj && candidateObj.candidateResume} style={{color:"blue",textDecoration:"underline"}}>Download</a> instead.</p>
                 
                 </object>
                 </div>
                  :
                <img src={samplecv} alt="sample resume"/>
                }
                </Grid>


                



            </Grid>

            <Grid item container xs={7} direction="column">
            
             
            <Grid container spacing={6} direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative" ,top:"0.7rem"}}>Location*</b>
                  
                  <div style={inputContainer}>
                    <TextField
                      name="location"
                      required
                      placeholder='Baltimore MD'
                      value={candidateObj.candidateLocation}
                      //onChange={(e) => setCompanyWebsite(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                        fontFamily:"Public Sans, sans-serif",
                        },
                        }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%'  ,position:"relative" ,top:"0.7rem"}}>Years of Experience*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="yearsOfExperience"
                      required
                      placeholder='10 years'
                      value={candidateObj.yearsOfExperience}
                      //onChange={(e) => setCompanySocMedia(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                        fontFamily:"Public Sans, sans-serif",
                        },
                        }}
                    />
                  </div>
                </Grid>
              </Grid>



              <Grid container spacing={6} direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative" ,top:"0.7rem" }}>Current Title*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="currentTitle"
                      required
                      placeholder='Sr. Full Stack Developer'
                      value={candidateObj.currentTitle}
                      //onChange={(e) => setCompanyWebsite(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                        fontFamily:"Public Sans, sans-serif",
                        },
                        }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative" ,top:"0.7rem" }}>Current Employer*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='Microsoft'
                      value={candidateObj.currentEmployer}
                      //onChange={(e) => setCompanySocMedia(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                        fontFamily:"Public Sans, sans-serif",
                        },
                        }}
                    />
                  </div>
                </Grid>
              </Grid>




              <Grid container spacing={6} direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative" ,top:"0.7rem" }}>Notice Required*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="noticePeriod"
                      required
                      placeholder='2 weeks'
                      value={candidateObj.noticePeriod}
                      //onChange={(e) => setCompanyWebsite(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                        fontFamily:"Public Sans, sans-serif",
                        },
                        }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative" ,top:"0.7rem" }}>Work Authorization Status*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="work authorization status"
                      required
                      placeholder='US Citizen'
                      value={candidateObj.workAuthorization}
                      //onChange={(e) => setCompanySocMedia(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                        fontFamily:"Public Sans, sans-serif",
                        },
                        }}
                    />
                  </div>
                </Grid>
              </Grid>


              <Grid container spacing={6} direction="row">
                 

              <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative" ,top:"0.7rem" }}>Salary Range*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='$150k - $160k'
                      value={candidateObj.salaryRange}
                      //onChange={(e) => setCompanySocMedia(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                        fontFamily:"Public Sans, sans-serif",
                        },
                        }}
                    />
                  </div>
                </Grid>
                
                
                
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%'  ,position:"relative" ,top:"0.7rem"}}>Additional Salary Note space</b>
                  <div style={inputContainer}>
                     <textarea rows={5} 
                    name="jobResponsibilities"
                    placeholder=''
                    value={candidateObj.additionalSalaryNote}
                    style={{borderRadius:"0.3rem",width:"90%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"1rem"}}
                    //onChange={(e) => setCompanyWebsite(e.target.value)}
                    
                    >
                    </textarea>
                  </div>
                </Grid>


             
           
            </Grid>



            </Grid>



            <Grid item  xs={9} style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column",gap:"0rem"}} >
                  
               
            {jobInFocus.interviewRound1 && jobInFocus.interviewRound1.typeOfInterview &&
            <>
           <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"gray"}}>
           <h3 style={{color:"#40255F",fontSize:"25px",fontWeight:"700"}}>Round 1 - {jobInFocus.interviewRound1 && jobInFocus.interviewRound1.typeOfInterview} <span style={{color:"black"}}>&nbsp; </span></h3>
           <i>Date: 4/1/2024</i>
           <i>Location:{jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewLocation}</i>
         
           </div>
       
           <h4  onClick={()=>{setOpen(true)}}  style={{ cursor:"pointer",color:"black",textDecoration:"underline",fontSize:"22px",marginTop:"0.8rem",marginBottom:"0.8rem",display:"flex",alignItems:"center"}}>Interview Recording <FaPencil onClick={(e)=>{e.stopPropagation(); setOpenInterviewEdit1(true)}} style={{marginLeft:"1rem",fontSize:"1.3rem"}} /></h4>

           <h3 style={{color:"black",marginBottom:"0rem",position:"relative",top:"0rem",fontSize:"25px",fontWeight:"700",marginTop:"1rem"}}>Panel Feedback</h3>

                <Divider style={{width:"100%",marginTop:"0rem",marginBottom:"0.5rem",border:"2px solid lightgrey"}}/>

                
                <TableContainer sx={{py:"0rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"transparent",color:"black"}}>

                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}}>Team Member</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Feedback Form</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Reviewed</TableCell>
                         
                          {/*<TableCell align="center">Candidate Reviewed</TableCell>*/}
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>

                      {jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewLead &&
                      <TableRow
                            
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                            {jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewLead &&
                            <p style={{color:"#40255F",fontSize:"17px",fontWeight:"900"}}>{jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewLead}</p>
                            }

                           <p style={{color:"gray",fontSize:"14px",fontWeight:"900",marginTop:"0rem"}}>{"Interview Lead"}</p>
                            </TableCell>



                           
                            <TableCell  onClick={(e)=>{dispatch(saveFeedbackFormDetails({
                             jobRole:candidateObj && candidateObj.jobRole,
                             candidateName:candidateObj && `${candidateObj.firstName}` + " " + `${candidateObj.lastName}`,
                             panelMemberName:jobInFocus && jobInFocus.interviewRound1.interviewLead
                          }))
                           e.stopPropagation()
                          ;setFeedBackForm(true); window.scrollTo(0, 0);}}
                            
                            align="center"  sx={{cursor:"pointer",my:0, fontSize:"14px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows[0].typeOfInterview}
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:0,fontSize:"14px", backgroundColor:"transparent",color:"gray"}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                          </TableRow>
                       
                      }


          {jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewPanelMember1 &&
                          <TableRow 
                            
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                            {jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewPanelMember1 &&
                            <p style={{color:"#40255F",fontSize:"17px",fontWeight:"900"}}>{jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewPanelMember1}</p>
                            }
                           
                            </TableCell>



                           
                            <TableCell onClick={(e)=>{dispatch(saveFeedbackFormDetails({
                             jobRole:candidateObj && candidateObj.jobRole,
                             candidateName:candidateObj && `${candidateObj.firstName}` + " " + `${candidateObj.lastName}`,
                             panelMemberName:jobInFocus && jobInFocus.interviewRound1.interviewLead
                          }))
                           e.stopPropagation()
                          ;setFeedBackForm(true); window.scrollTo(0, 0);}} 
                            
                            align="center"  sx={{cursor:"pointer",my:0, fontSize:"14px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows[0].typeOfInterview}
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:0,fontSize:"14px", backgroundColor:"transparent",color:"gray"}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                          </TableRow>
                       
                  }




      {jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewPanelMember2 &&
                          <TableRow 
                            
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                            {jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewPanelMember2 &&
                            <p style={{color:"#40255F",fontSize:"17px",fontWeight:"900"}}>{jobInFocus.interviewRound1 && jobInFocus.interviewRound1.interviewPanelMember2}</p>
                            }
                          
                            </TableCell>



                           
                            <TableCell  onClick={(e)=>{dispatch(saveFeedbackFormDetails({
                             jobRole:candidateObj && candidateObj.jobRole,
                             candidateName:candidateObj && `${candidateObj.firstName}` + " " + `${candidateObj.lastName}`,
                             panelMemberName:jobInFocus && jobInFocus.interviewRound1.interviewLead
                          }))
                           e.stopPropagation()
                          ;setFeedBackForm(true); window.scrollTo(0, 0);}}

                            
                            align="center"  sx={{cursor:"pointer",my:0, fontSize:"14px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows[0].typeOfInterview}
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:0,fontSize:"14px", backgroundColor:"transparent",color:"gray"}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                          </TableRow>
                 }


                      </TableBody>
                    </Table>
                </TableContainer>
              

                <Divider style={{width:"100%",marginTop:"0.5rem",marginBottom:"1rem",border:"2px solid lightgrey"}}/>
      
             </>
          }


        {jobInFocus.interviewRound2 && jobInFocus.interviewRound2.typeOfInterview &&
          <>
                <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"gray"}}>
           <h3 style={{color:"#40255F",fontSize:"25px",fontWeight:"700"}}>Round 2 - {jobInFocus.interviewRound2 && jobInFocus.interviewRound2.typeOfInterview} <span style={{color:"black"}}>&nbsp;</span></h3>
           <i>Date: 4/3/2024</i>
           <i>Location:{jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewLocation}</i>
         
           </div>
       
           <h4  onClick={()=>{setOpen(true)}}  style={{ cursor:"pointer",color:"black",textDecoration:"underline",fontSize:"22px",marginTop:"0.8rem",marginBottom:"0.8rem",display:"flex",alignItems:"center"}}>Interview Recording <FaPencil onClick={(e)=>{e.stopPropagation(); setOpenInterviewEdit2(true)}} style={{marginLeft:"1rem",fontSize:"1.3rem"}} /></h4>

           <h3 style={{color:"black",marginBottom:"0rem",position:"relative",top:"0rem",fontWeight:"700",marginTop:"1rem",fontSize:"25px"}}>Panel Feedback</h3>

                <Divider style={{width:"100%",marginTop:"0rem",marginBottom:"0.5rem",border:"2px solid lightgrey"}}/>

                
                <TableContainer sx={{py:"0rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"transparent",color:"black"}}>

                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}}>Team Member</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Feedback Form</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Reviewed</TableCell>
                         
                          {/*<TableCell align="center">Candidate Reviewed</TableCell>*/}
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>

              {jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewLead &&    
                      <TableRow 
                            
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                            {jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewLead&&
                            <p style={{color:"#40255F",fontSize:"17px",fontWeight:"900"}}>{jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewLead}</p>
                             }


                           <p style={{color:"gray",fontSize:"14px",fontWeight:"900",marginTop:"0rem"}}>{"Interview Lead"}</p>
                            </TableCell>



                           
                            <TableCell  onClick={(e)=>{dispatch(saveFeedbackFormDetails({
                             jobRole:candidateObj && candidateObj.jobRole,
                             candidateName:candidateObj && `${candidateObj.firstName}` + " " + `${candidateObj.lastName}`,
                             panelMemberName:jobInFocus && jobInFocus.interviewRound1.interviewLead
                          }))
                           e.stopPropagation()
                          ;setFeedBackForm(true); window.scrollTo(0, 0);}}
                             
                            
                            align="center"  sx={{cursor:"pointer",my:0, fontSize:"14px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows[0].typeOfInterview}
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:0,fontSize:"14px", backgroundColor:"transparent",color:"gray"}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                          </TableRow>
                   }

            {jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewPanelMember1 &&
                          <TableRow 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                            {jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewPanelMember1 &&
                            <p style={{color:"#40255F",fontSize:"17px",fontWeight:"900"}}>{jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewPanelMember1}</p>
                            }
                           
                            </TableCell>



                           
                            <TableCell  onClick={(e)=>{dispatch(saveFeedbackFormDetails({
                             jobRole:candidateObj && candidateObj.jobRole,
                             candidateName:candidateObj && `${candidateObj.firstName}` + " " + `${candidateObj.lastName}`,
                             panelMemberName:jobInFocus && jobInFocus.interviewRound1.interviewLead
                          }))
                           e.stopPropagation()
                          ;setFeedBackForm(true); window.scrollTo(0, 0);}}

                             align="center"  sx={{cursor:"pointer",my:0, fontSize:"14px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows[0].typeOfInterview}
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:0,fontSize:"14px", backgroundColor:"transparent",color:"gray"}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                          </TableRow>
          }



        {jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewPanelMember2 &&
                          <TableRow 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                            {jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewPanelMember2 &&
                            <p style={{color:"#40255F",fontSize:"17px",fontWeight:"900"}}>{jobInFocus.interviewRound2 && jobInFocus.interviewRound2.interviewPanelMember2}</p>
                            }
                          
                            </TableCell>



                           
                            <TableCell  onClick={(e)=>{dispatch(saveFeedbackFormDetails({
                             jobRole:candidateObj && candidateObj.jobRole,
                             candidateName:candidateObj && `${candidateObj.firstName}` + " " + `${candidateObj.lastName}`,
                             panelMemberName:jobInFocus && jobInFocus.interviewRound1.interviewLead
                          }))
                           e.stopPropagation()
                          ;setFeedBackForm(true); window.scrollTo(0, 0);}}
                            
                            align="center"  sx={{cursor:"pointer",my:0, fontSize:"14px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows[0].typeOfInterview}
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:0,fontSize:"14px", backgroundColor:"transparent",color:"gray"}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                          </TableRow>
            }


                      </TableBody>
                    </Table>
                </TableContainer>
            </>
            }
                
          {jobInFocus.interviewRound3 && jobInFocus.interviewRound3.typeOfInterview &&

            <>

                <Divider style={{width:"100%",marginTop:"0.5rem",marginBottom:"3rem",border:"2px solid lightgrey"}}/>


                <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"gray"}}>
                <h3 style={{color:"#40255F",fontSize:"25px",fontWeight:"700"}}>Round 3 - {jobInFocus.interviewRound3 && jobInFocus.interviewRound3.typeOfInterview} <span style={{color:"black"}}>&nbsp;</span></h3>
                <i>Date: 4/5/2024</i>
                <i style={{marginTop:"0rem"}}>Location: {jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewLocation}</i>
                
              
                </div>

    
     

                <h3 style={{color:"black",marginBottom:"0rem",position:"relative",top:"0rem",fontWeight:"700",marginTop:"1rem",fontSize:"25px"}}>Panel Feedback</h3>

                <Divider style={{width:"100%",marginTop:"0rem",marginBottom:"0.5rem",border:"2px solid lightgrey"}}/>

               
                <TableContainer sx={{py:"0rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"transparent",color:"black"}}>

                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}}>Team Member</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Feedback Form</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Reviewed</TableCell>
                         
                          {/*<TableCell align="center">Candidate Reviewed</TableCell>*/}
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>

            {jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewLead &&  
                      <TableRow 
                            
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                            {jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewLead &&
                            <p style={{color:"#40255F",fontSize:"17px",fontWeight:"900"}}>{jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewLead}</p>
                             }

                           <p style={{color:"gray",fontSize:"14px",fontWeight:"900",marginTop:"0rem"}}>{"Interview Lead"}</p>
                            </TableCell>



                           
                            <TableCell  
                            onClick={(e)=>{dispatch(saveFeedbackFormDetails({
                              jobRole:candidateObj && candidateObj.jobRole,
                              candidateName:candidateObj && `${candidateObj.firstName}` + " " + `${candidateObj.lastName}`,
                              panelMemberName:jobInFocus && jobInFocus.interviewRound1.interviewLead
                           }))
                            e.stopPropagation()
                           ;setFeedBackForm(true); window.scrollTo(0, 0);}}
                            
                            align="center"  sx={{cursor:"pointer",my:0, fontSize:"14px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows[0].typeOfInterview}
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:0,fontSize:"14px", backgroundColor:"transparent",color:"gray"}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                          </TableRow> 
                     } 
                       

                  {jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewPanelMember1 &&
                          <TableRow 
                            
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                            {jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewPanelMember1 &&
                            <p style={{color:"#40255F",fontSize:"17px",fontWeight:"900"}}>{jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewPanelMember1}</p>
                            }
                           
                            </TableCell>



                           
                            <TableCell onClick={(e)=>{dispatch(saveFeedbackFormDetails({
                             jobRole:candidateObj && candidateObj.jobRole,
                             candidateName:candidateObj && `${candidateObj.firstName}` + " " + `${candidateObj.lastName}`,
                             panelMemberName:jobInFocus && jobInFocus.interviewRound1.interviewLead
                          }))
                           e.stopPropagation()
                          ;setFeedBackForm(true); window.scrollTo(0, 0);}}
                            
                            align="center"  sx={{cursor:"pointer",my:0, fontSize:"14px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows[0].typeOfInterview}
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:0,fontSize:"14px", backgroundColor:"transparent",color:"gray"}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                          </TableRow>
                       }  



               {jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewPanelMember2 &&
                          <TableRow 
                            
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                            {jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewPanelMember2 &&
                            <p style={{color:"#40255F",fontSize:"17px",fontWeight:"900"}}>{jobInFocus.interviewRound3 && jobInFocus.interviewRound3.interviewPanelMember2}</p>
                            }
                          
                            </TableCell>



                           
                            <TableCell 
                            onClick={(e)=>{dispatch(saveFeedbackFormDetails({
                              jobRole:candidateObj && candidateObj.jobRole,
                              candidateName:candidateObj && `${candidateObj.firstName}` + " " + `${candidateObj.lastName}`,
                              panelMemberName:jobInFocus && jobInFocus.interviewRound1.interviewLead
                           }))
                            e.stopPropagation()
                           ;setFeedBackForm(true); window.scrollTo(0, 0);}}
                             align="center"  sx={{cursor:"pointer",my:0, fontSize:"14px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows[0].typeOfInterview}
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:0,fontSize:"14px", backgroundColor:"transparent",color:"gray"}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                          </TableRow>
                     }

                      </TableBody>
                    </Table>
                </TableContainer>

              </>  
               
              }





        
                <Divider style={{width:"100%",marginTop:"1rem",marginBottom:"1.5rem",border:"2px solid lightgray"}}/>

                <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"gray"}}>
                <h3 style={{color:"#40255F",fontSize:"25px",fontWeight:"700"}}>Offer Letter<span style={{color:"black"}}>&nbsp;</span></h3>
                <i>Date Sent: 4/8/2024</i>
               
                
              
                </div>



               

                <Divider style={{width:"100%",marginTop:"1.5rem",marginBottom:"0.5rem",border:"2px solid lightgrey"}}/>

                
                <TableContainer sx={{py:"0rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"transparent",color:"black"}}>

                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}}>Candidate name</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Position Title</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Reviewed</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"23px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Signed</TableCell>
                         
                          {/*<TableCell align="center">Candidate Reviewed</TableCell>*/}
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        
                          <TableRow 
                            
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell sx={{my:0, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                  
                            <p style={{color:"#40255F",fontSize:"20px",fontWeight:"900"}}>{candidateObj && candidateObj.firstName + " " + candidateObj.lastName}</p>
                           
                          
                            </TableCell>
                           
                            <TableCell align="center"  sx={{my:0, fontSize:"18px",backgroundColor:"transparent",color:"gray",fontWeight:"900",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {candidateObj && candidateObj.jobRole}
                            

                            </TableCell>


                            <TableCell align="center"  sx={{my:0,fontSize:"18px", backgroundColor:"transparent",color:"gray",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {candidateObj && candidateObj.offerReviewed?"Yes":"No"}
                            

                            </TableCell>

                            <TableCell align="center"  sx={{my:0,fontSize:"18px", backgroundColor:"transparent",color:"gray",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {rows4[0].signed}
                            
                            
                            </TableCell>


                          </TableRow>
                      
                      </TableBody>
                    </Table>
                </TableContainer>
               

             

       

                </Grid>



          
                <Grid item  xs={12} style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"2rem",marginTop:"9rem"}} >
                  
                  <Button onClick={()=>{dispatch(acceptCandidateToOffer(candidateObj.id,candidateObj.jobId,/*jobInFocus.businessId,*/navigate))}} style={{color:"green",padding:"1rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",fontFamily:"Public Sans, sans-serif",}}>Accept, Move to Next Steps</Button>
 
 
                  <Button onClick={(e)=>{e.stopPropagation();setFeedBackScreen(true); window.scrollTo(0, 0);}} style={{color:"red",padding:"1rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",fontFamily:"Public Sans, sans-serif",}}>Reject, Not Moving Forward</Button>
                  
 
                 </Grid>
         




           </Grid>

   
               
           
            {/*<JobPostingForm />*/}
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
