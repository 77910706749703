import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
import { useEffect,useRef } from 'react';
import { findDOMNode } from 'react-dom'
import { fCurrency, fNumber } from '../utils/formatNumber';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchGroups, fetchMyGroups } from 'src/redux/actions/group.action';


import { useMemo, useState} from 'react';

import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { BaseOptionChart } from 'src/components/chart2';


import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@mui/icons-material/Search';

import Input from '@material-ui/core/Input';
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';



export default function IntroPage() {
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { myGroups, isLoading } = useSelector((state) => state.group);
  const { transactions } = useSelector((state) => state.transaction);

  const [searchText, setSearchText] = useState('');


  function handleSearchText(event) {
    setSearchText(event.target.value);
  }




  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      /*theme.palette.primary.lighter,
      theme.palette.primary.light,*/
      theme.palette.primary.main,
      theme.palette.primary.dark,
    ],
    labels: ["ACCRUED","ACCRUED"],
    interactions: [],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center', show:false },
    tooltip: {
      enabled: false
  },


 
    plotOptions: {
      pie: {
        expandOnClick:false,
        click:false,
        donut: {
          size: '70%',
          labels: {
            // value: {
            //   formatter: (val) => fNumber(0),
            // },
            value: {
              formatter: (val) =>  {if(user &&user.accruedBalance)
                           {return fCurrency(user && user.accruedBalance)}
                           else {return '$0'}
   
                          }
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
               // console.log("user?.accruedBalance: ", user?.accruedBalance);
                return user?.accruedBalance === 0 ? "$0" : fCurrency(user?.accruedBalance);
                // return fNumber(0);
              },
            },
            hover: {
              enabled: false,
             
          },
          toggle: {
            enabled: false,
           
        },
          },
        },
      },
    },
   
  });


 



  useEffect(() => {
    if(user?./*u*/id == undefined){
     return navigate("/login");
    }
   }, [])

   const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '40%',
  };

  useEffect(() => {
    dispatch(fetchMyGroups(user?.recruiterJobs));
  
  }, [user])

 
  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])
  






  return (
    <>
      <Helmet>
        <title> One Recruiter | Business </title>
      </Helmet>

      <Container maxWidth="xl">
       
        <Grid  container direction="row" justifyContent="flex-end" alignItems="center">
      
      


           <div style={{borderRadius:"0.5rem",border:"1px solid lightgray",width:"35%",marginTop:"4rem"}} className="flex p-4 items-center  px-8 py-4">
                <Icon color="action"><SearchIcon style={{position:"relative",top:"-0.5rem"}}/></Icon>

                <Input
                  placeholder="Search..."
                  className="flex flex-1 px-8"
                  disableUnderline
                  fullWidth
                  value={searchText}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchText}
                />
              </div>
    </Grid>
    <br/>
    
      <br/>


        <Grid container style={{justifyContent:"center",alignItems:"center",height:"40vh"}} spacing={2}>
           
         

             <div item xs={12} style={{justifyContent:"center",alignItems:"center",fontWeight:"900",fontSize:"36px"}}>
              Welcome Back, {user && user.userType === 'business'?(user.companyName?user.companyName:user.businessName) :user && user.userType === 'recruiter'?user.firstName + " " + user.lastName  :'Purple LLC' }
             </div>



            
           
          </Grid>
          <br/>




          <Grid container style={{justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column",paddingLeft:"2rem"}} spacing={2}>
       
             



             <div item xs={12} style={{justifyContent:"flex-start",alignItems:"center",fontWeight:"900",fontSize:"26px",paddingBottom:"0rem", borderBottom:"2px solid lightgray",width:"37rem",marginBottom:"1rem",color:"#40255F"}}>
              Best Practice Resources
             </div>


             <i item xs={12} style={{justifyContent:"flex-start",alignItems:"center",fontWeight:"900",fontSize:"16px",paddingBottom:"1rem",fontFamily:"Public Sans, sans-serif !important", }}>
             <a style={{color:"blue",textDecoration:"underline",fontFamily:"Public Sans, sans-serif ",}} href='#'>Interviewology: The New Science of Interviewing</a> &nbsp; <span style={{fontFamily:"Public Sans, sans-serif ",}}>Written By: Anna Papalia</span>
             </i>
           
           
          </Grid>
       
       
         
      </Container>
    </>
  );
}
