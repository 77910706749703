import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import LoginPage from './pages/LoginPageBusiness';
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';



import RegisterPage from './pages/RegisterPage2';

import InboxPage from './pages/InboxPage';
import SettingsPage from './pages/SettingsPage';
import UploadVideoPage from './pages/UploadVideo';
import ViewCandidatesPage from './pages/ViewCandidatesPage';
import AddJobPage from './pages/AddJobPage';
import EditCandidatePage from './pages/EditCandidatePage';
import ViewFullJobPage from './pages/ViewFullJobPage';

import WelcomePage from './pages/WelcomePage';
import ApplicationPage from './pages/ApplicationPage';
import IntroPage from './pages/IntroPage';
import ExpensesPage from './pages/ExpensesPage';
import ProfilePage from './pages/ProfilePage';
import PostJobPage from './pages/PostJobPage';
import ActionItemsPage from './pages/ActionItemsPage';
import CandidatesPendingReviewPage from './pages/CandidatesPendingReviewPage';
import CandidatesPendingReviewPageCandidates from './pages/CandidatesPendingReviewPageCandidates';
import CandidatesPendingSelectionPage from './pages/CandidatesPendingSelectionPage';
import CandidatesPendingSelectionPageCandidates from './pages/CandidatesPendingSelectionPageCandidates';
import CandidatesPendingOfferPage from './pages/CandidatesPendingOfferPage';
import CandidatesPendingReviewPageExpanded from './pages/CandidatesPendingReviewPageExpanded';
import CandidatesPendingSelectionPageExpanded from './pages/CandidatesPendingSelectionPageExpanded';
import CandidatesPendingOfferPageExpanded from './pages/CandidatesPendingOfferPageExpanded';
import SuccessApplicationPage from './pages/SuccessApplicationPage';
import CreateJobPage from './pages/CreateJobPage';
import ActiveJobListingsPage from './pages/ActiveJobListingsPage';
import HiredCandidatesPage from './pages/HiredCandidatesPage';
import ClosedJobsPage from './pages/ClosedJobsPage';
import NotificationsPage from './pages/NotificationsPage';
import DraftedJobsPage from './pages/DraftedJobsPage';
import ComingSoonPage from './pages/ComingSoonPage';
import HowItWorksPage from './pages/HowItWorksPage';
import LoginPageRecruiter from './pages/LoginPageRecruiter';
import LoginPageBusiness from './pages/LoginPageBusiness';
import ActiveJobListingsRecruiterPage from './pages/ActiveJobListingsRecruiterPage';
import AddCandidatePage from './pages/AddCandidatePage';
import ApplicationPageRecruiter from './pages/ApplicationPageRecruiter';
import MyJobsRecruiterPage from './pages/MyJobsRecruiterPage';
import CandidatesPendingOfferPageCandidates from './pages/CandidatesPendingOfferPageCandidates';
import ViewJobPostedPage from './pages/ViewJobPostedPage';
import AddCandidateBusinessPage from './pages/AddCandidateBusinessPage';
import AddCandidateBusinessPageAction from './pages/AddCandidateBusinessPageAction';


export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: 'home', element: <IntroPage /> },
        { path: 'expenses', element: <ExpensesPage /> },
       
        { path: 'post-a-job', element: <PostJobPage /> },
        { path: 'view-posted-job', element: <ViewJobPostedPage /> },
        { /*path: 'my-cooler', element: <MyCoolersPage /> */},
        { path: 'view-candidates', element: <ViewCandidatesPage /> },
        { path: 'job-full-view', element: <ViewFullJobPage /> },
        {/* path: 'add-job', element: <AddJobPage /> */},
        { path: 'add-job', element: <PostJobPage /> },
        { path: 'active-job-postings', element: <ActiveJobListingsPage /> },

        { path: 'my-jobs', element: <ActiveJobListingsPage /> },
        { path: 'add-candidate', element: <AddCandidatePage /> },

        { path: 'active-job-postings-recruiter', element: <ActiveJobListingsRecruiterPage /> },

        { path: 'my-jobs-recruiter', element: <MyJobsRecruiterPage /> },

         // the path and component below has the same listing and functionality as my-jobs-recruiter page
        { path: 'add-candidate-business', element: <AddCandidateBusinessPage /> },

        { path: 'add-candidate-business-action', element: <AddCandidateBusinessPageAction /> },
        
        

        { path: 'action-items', element: <ActionItemsPage /> },

        { path: 'candidates-pending-review', element: <CandidatesPendingReviewPage /> },
        { path: 'candidates-pending-selection', element: <CandidatesPendingSelectionPage /> },
        { path: 'candidates-pending-offer', element: <CandidatesPendingOfferPage /> },

        { path: 'candidates-offered', element: <CandidatesPendingOfferPageCandidates /> },
        
        
        { path: 'candidates-pending-offer-candidate-expanded', element: <CandidatesPendingOfferPageExpanded /> },

        { path: 'candidates-pending-review-candidates', element: <CandidatesPendingReviewPageCandidates /> },
        { path: 'candidates-pending-review-candidate-expanded', element: <CandidatesPendingReviewPageExpanded /> },



        { path: 'candidates-pending-selection-candidates', element: <CandidatesPendingSelectionPageCandidates /> },
        { path: 'candidates-pending-selection-candidate-expanded', element: <CandidatesPendingSelectionPageExpanded /> },

        { path: 'edit-candidate', element: <EditCandidatePage /> },

        { path: 'profile', element: <ProfilePage /> },

        { path: 'projects', element: <ComingSoonPage /> },

        { path: 'top-talent-experts', element:  <ComingSoonPage /> },
        

        { path: 'hired-candidates', element: <HiredCandidatesPage /> },

        { path: 'drafted-jobs', element: <DraftedJobsPage /> },

        { path: 'drafted', element:  <ComingSoonPage /> },

        { path: 'closed-jobs', element: <ClosedJobsPage /> },

        { path: 'chat', element: <NotificationsPage /> },


      ],
    },
    {/*
      path: 'login',
      element: <LoginPage />,
  */},

    {
      path: 'login-business',
      element: <LoginPageBusiness />,
    },
    {
      path: 'login-recruiter',
      element: <LoginPageRecruiter />,
    },


    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'application',
      element: <ApplicationPage />,
    },

    {
      path: 'application-recruiter',
      element: <ApplicationPageRecruiter />,
    },

    {
      path: 'how-it-works',
      element: <HowItWorksPage />,
    },

    { path: 'create-job', element: <CreateJobPage /> },

    {
      path: 'success-application',
      element: <SuccessApplicationPage />,
    },

    { path: 'home', element: <WelcomePage /> },
    
    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/login" />, index: true },
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
