import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import ProfileForm from 'src/components/login/profileForm';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import JobPostingForm from 'src/components/login/jobPostingForm';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import folder from 'src/assets/images/folder.png'
import { useDispatch, useSelector } from 'react-redux';
import { AddRecruiterJobs, fetchAllJobs,fetchAllJobsRecruiter, fetchAllJobsForMyBusiness, fetchGroups, fetchRecruiterJobs } from 'src/redux/actions/group.action';
import { LoadingButton } from '@mui/lab';
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
   // fontFamily:"Libre Baskerville, sans serif",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));

// ----------------------------------------------------------------------

export default function MyJobsRecruiterPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState('');
  const [dropDown,setDropDown] = useState(null);


  const { user } = useSelector((state) => state.auth);
  const { myGroups,allJobs,allJobsRecruiter,allGroups, isLoading } = useSelector((state) => state.group);

  //let notMyGroups = allJobs
 const [notMyGroups,setNotMyGroups] = useState(allJobsRecruiter)



 
useEffect(()=>{

  dispatch(fetchRecruiterJobs(user && user.recruiterJobs));

},[user])



useEffect(() => {

  if(user && user.userType=== 'recruiter'){
    dispatch(setRecruiter(true));
    dispatch(setDemo(false));
  }
  else{

   dispatch( setRecruiter(false));
   dispatch(setDemo(true));
  }
},[])

useEffect(()=>{

  dispatch(fetchAllJobsRecruiter());

},[])




  useEffect(() => {



    
    
   

let filterMaterial = allJobsRecruiter && allJobsRecruiter


if(filterMaterial){

myGroups.forEach((group) => {
   filterMaterial =  filterMaterial.filter((item)=>(item.id !== group.id))
  
  })

}

  
  setNotMyGroups(filterMaterial)

  }, [user,allJobsRecruiter])



  const addToTheRecruiterJobs = (userId,jobID,userFirstName,userLastName)=>{
   
     dispatch(AddRecruiterJobs(userId,jobID,userFirstName,userLastName))

     dispatch(fetchRecruiterJobs(user && user.recruiterJobs));

     let filterMaterial = allJobsRecruiter
     myGroups.forEach((group) => {
      filterMaterial =  filterMaterial.filter((item)=>(item.id !== group.id))
       })

       setNotMyGroups(filterMaterial) 
     

  }

  const rows= [
    {jobId:"IT-22", title:"Lead Product Designer",   status:"Live",hiringManager:"Michael Davis", talentExpert:"Tina Fay"},
    {jobId:"IT-522", title:"Data Architect",   status:"Live",hiringManager:"Tamera Johnson", talentExpert:"Karla Green"},
    {jobId:"OPS-22", title:"Director of People Operations",   status:"Assigned",hiringManager:"Christopher Morris", talentExpert:"Fredrick Jordan"},
    {jobId:"OPS-22", title:"Director of People Operations",   status:"Needs Attention",hiringManager:"Tammy Morris", talentExpert:"Dorian Smith"},
    {jobId:"OPS-22", title:"Director of People Operations",   status:"Assigned",hiringManager:"Maria Ortiz", talentExpert:"Devin Dottson"},
  ];

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };



  return (
    <>
      <Helmet>
        <title> My Jobs| oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       
        <Container style={{marginTop:"0rem",width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

        
          
            <Typography variant="h2" gutterBottom style={{marginTop:"3rem",width:"44rem"/*,color:"#40255F"*/,display:"flex",justifyContent:"center",marginBottom:"3rem"}}>
                My Jobs
            </Typography>





           <div style={{padding:"1rem",borderRadius:"0.5rem",border:"3px solid lightgray",width:"66rem"}}>
            <TableContainer sx={{py:"2rem", width: "100%",}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF"}}>

                          <TableCell sx={{color:"black",fontSize:"1.2rem",fontWeight:"800",fontFamily:"Public Sans, sans-serif !important",}}  align="center">JP ID</TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1.2rem",fontWeight:"800",fontFamily:"Public Sans, sans-serif !important",}} align="center">Title</TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1.2rem",fontWeight:"800",fontFamily:"Public Sans, sans-serif !important",}} align="center">Status</TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1.2rem",fontWeight:"800",fontFamily:"Public Sans, sans-serif !important",}} align="center">Hiring Manager</TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1.2rem",fontWeight:"800",fontFamily:"Public Sans, sans-serif !important",}} align="center"></TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1.2rem",fontWeight:"800",fontFamily:"Public Sans, sans-serif !important",}} align="center"></TableCell>
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { myGroups && myGroups.length ? myGroups.map((row,index) => (
                       
                     <>
                       <TableRow
                            key={index}
                            sx={{my:2, '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                          >
                            <TableCell sx={{ backgroundColor:"#e0dcdc"}}  scope="row">  
                            
                          <TextField  
                            name="interview round"
                            inputProps={{
                              style: {
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif !important",
                              },
                              }}
                            placeholder='Round -1'
                            value={rows[0].jobId}
                            onChange={(e) => setCompanyName(e.target.value)}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                          />
                            
                      
                            </TableCell>


                            <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>

                            <TextField 
                            name="type of interview"
                            inputProps={{
                              style: {
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif !important",
                              },
                              }}
                            placeholder='Virtual'
                            value={row.jobRole}
                            onChange={(e) => setCompanyName(e.target.value)}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                          />  

                            </TableCell>


                          { <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="duration"
                           inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif !important",
                            },
                            }}
                           placeholder='60 mins'
                           value={rows[1].status}
                           onChange={(e) => setCompanyName(e.target.value)}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                           />  
                           
                           </TableCell> }


                           <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>

                          <TextField 
                          name="feedback"
                          inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif !important",
                            },
                            }}
                          placeholder=''
                         
                          value={/*user && user.hiringManager?user.hiringManager:''*/row.hiringManagerName?row.hiringManagerName:rows[0].hiringManager}
                          onChange={(e) => setCompanyName(e.target.value)}
                          style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif !important",}}
                          />  
                          
                          </TableCell>

                          {/*<TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>

                          <TextField 
                          name="feedback"
                          inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif !important",
                            },
                            }}
                          placeholder=''
                          value={row.recruiterName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif !important",}}
                          />  
                          
                          </TableCell>*/}


                        <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>
                          <LoadingButton onClick={()=>{{
                                           
                                           navigate('/dashboard/view-posted-job',{state:{currentJob:row}})
                                           /*dropDown === index ?setDropDown(null):setDropDown(index)  */   
                                         
                                         
                                          }}}
                           sx={{bgcolor:"#40255F",fontFamily:"Public Sans, sans-serif",width:"10rem",padding:"1rem"}}   type="submit" variant="contained" disabled={false}>
                          { "View Job"}
                         </LoadingButton>
                         </TableCell>


                          <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>
                          <LoadingButton onClick={()=>{{
                                           
                                           
                                           navigate('/dashboard/add-candidate', { state: { row } })
                                         
                                         
                                          }}}
                           sx={{bgcolor:"#40255F",fontFamily:"Public Sans, sans-serif",width:"10rem",padding:"1rem"}}   type="submit" variant="contained" disabled={false}>
                          { "Add Candidate"}
                         </LoadingButton>
                         </TableCell>

                          </TableRow>



             
                     
                  {dropDown === index &&
                 

                  <TableRow>
                  <TableCell colSpan={5}>  
                    <div style={{display:"flex",justifyContent:"flex-start",width:"100%",color:"black",fontSize:"22px",fontWeight:"800"}}>
                      <SlideDown style={{width:"100%"}} >
                        Job Title: {row.jobRole}
                     </SlideDown>
                      
                   </div>
                      
                      </TableCell>
                  </TableRow>
                      }
                  
                      
                    

        </>
                        )):
                        
                        <TableRow>
                        <TableCell colSpan={5}>  <center style={{display:"flex",justifyContent:"center",padding:"3rem",width:"100%",color:"gray",fontSize:"22px",fontWeight:"800"}}> Jobs you have added will be displayed here... </center></TableCell>
                        </TableRow>
                        }
                      </TableBody>
                    </Table>
                </TableContainer>

            </div>




         



       

           
            {/*<JobPostingForm />*/}
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
