import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, 
      InputAdornment, TextField, Checkbox,
       Typography, Grid, FormControlLabel, RadioGroup, 
       FormControl, FormLabel, Radio } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';
import { useDispatch, useSelector } from 'react-redux';
import { signin, signup } from 'src/redux/actions/auth.action';

// ----------------------------------------------------------------------

export default function ApplicationForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fname, setFName] = useState('');
  //const [lname, setLName] = useState('');
  //const [bio, setBio] = useState('');

  const [acctHolderName, setAcctHolderName] = useState('');
  const [bestContactNo, setBestContactNo] = useState('');
  const [positionTitle, setPositionTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companySocMedia, setCompanySocMedia] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [ceoName, setCeoName] = useState('');
  //const [employeeNum, setEmployeeNum] = useState('');
  const [yearFounded, setYearFounded] = useState('');
  const [companyBio, setCompanyBio] = useState('');
  const [additionalBenefits, setAdditionalBenefits] = useState('');
  const [companyCulture, setCompanyCulture] = useState('');
  const [companyOnboarding, setCompanyOnboarding] = useState('');
  const [howDidYouHear, setHowDidYouHear] = useState('');
 const [employeeBenefits,setEmployeeBenefits] = useState([])



  const dispatch = useDispatch(); 

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };


  const userSignup = (e) => {
    e.preventDefault();
    setLoading(true);
    const user = { 
       email,
       password,
       fname,
       acctHolderName,
       bestContactNo,
       positionTitle,
       companyName,
       companyWebsite,
       companySocMedia,
       companyEmail,
       ceoName,
       yearFounded,
       companyBio,
       additionalBenefits,
       companyCulture,
       companyOnboarding,
       howDidYouHear,
       employeeBenefits
       };
    dispatch(signup(user, navigate, setLoading));
  }

  return (
    <div style={{width:"80%",zIndex:"0"}}>
     <form onSubmit={userSignup}>

     <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
              Account Holder Information
            </Typography>


            <Grid container spacing={6} direction="row" style={{fontFamily:"Public Sans, sans-serif ",}}>
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative",top:"0.9rem"  }}>Account holder's name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Account Holder'
                      value={acctHolderName}
                      onChange={(e) => setAcctHolderName(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem" }}>Company Email Address*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='email@company.com'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>



     
      
              <Grid container spacing={6} direction="row" style={{fontFamily:"Public Sans, sans-serif ",}}>
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>Best Contact Number with Area Code*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='(123) 456-789'
                      value={bestContactNo}
                      onChange={(e) => setBestContactNo(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>Position Title*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='Human Resource Manager'
                      value={positionTitle}
                      onChange={(e) => setPositionTitle(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>





              <Typography variant="h3" gutterBottom style={{marginTop:"5rem", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
             Company Profile Information
              </Typography>
           
              <Grid container spacing={6} direction="row" style={{fontFamily:"Public Sans, sans-serif ",}}>
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative",top:"0.9rem" }}>Company name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Add answer here'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>Company Mailing Address (HQ)*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='123 Street name, suite #, City, ST, 12345'
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>


              <Grid container spacing={6} direction="row" style={{fontFamily:"Public Sans, sans-serif ",}}>
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>Company Website*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Enter URL'
                      value={companyWebsite}
                      onChange={(e) => setCompanyWebsite(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}} >
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>Company LinkedIn or Social Media Link*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='Enter URL'
                      value={companySocMedia}
                      onChange={(e) => setCompanySocMedia(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>


              <Grid container spacing={6} direction="row" style={{fontFamily:"Public Sans, sans-serif ",}}>
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>CEO Name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='First and Last Name'
                      value={ceoName}
                      onChange={(e) => setCeoName(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                <Grid container spacing={1} direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>Total # of Employees*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Enter #'
                      value={fname}
                      onChange={(e) => setFName(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>Year Founded*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='YYYY'
                      value={yearFounded}
                      onChange={(e) => setYearFounded(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
                </Grid>
              </Grid>


          
              <Typography variant="h3" gutterBottom style={{marginTop:"5rem", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
             Company Employee Information
            </Typography>
              <Grid container direction="row" sx={{mt:2 ,fontFamily:"Public Sans, sans-serif ",}}>
                <Grid item xs={12} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"0.9rem"  }}>Company Bio: What Do You Do ?*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    placeholder='A company bio is a brief description of a company. It gives an overview of the companys history products or services'
                    value={companyBio}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",color:"gray"}}
                    onChange={(e) => setCompanyBio(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>


              <Grid container spacing={6}  sx={{mt:3,fontFamily:"Public Sans, sans-serif ",}} direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>Employee Benefits*</b>
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup
                     aria-labelledby="demo-checkbox-buttons-group-label"
                     defaultValue="female"
                     name="checkbox-buttons-group"
                   >
                     <FormControlLabel value="Medical" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }} control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>Medical</Typography>} />
                     <FormControlLabel value="Dental" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }}     control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>Dental</Typography>} />
                     <FormControlLabel value="Vision" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }}     control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>Vision</Typography>} />
                     <FormControlLabel value="Stock Options" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }}     control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>Stock Options</Typography>} />
                     <FormControlLabel value="401(k)" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }}     control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>401(k)</Typography>} />
                     <FormControlLabel value="Company 401(k) Match" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }}     control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>Company 401(k) Match</Typography>} />
                    
                     <FormControlLabel value="Tuition Assistance" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }}     control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>Tuition Assistance</Typography>} />
                     <FormControlLabel value="Unlimited PTO" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }}     control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>Unlimited PTO</Typography>} />
                     <FormControlLabel value="Free Lunches" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }}     control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>Free Lunches</Typography>} />
                     <FormControlLabel value="4-Day Work Week" onClick={(e)=>{if(employeeBenefits.includes(e.target.value)){setEmployeeBenefits(employeeBenefits.filter((item)=>(item !== e.target.value))) }else{setEmployeeBenefits([...employeeBenefits,e.target.value])} }}     control={<Checkbox />} label={<Typography style={{fontFamily:"Public Sans, sans-serif"}}>4-Day Work Week</Typography>} />
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>

                <Grid item xs={5.5} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '17px', width: '90%',position:"relative",top:"0.9rem" }}>Please add additional benefit information and employee programs that would resonate well with candidates*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={11} 
                    name="jobResponsibilities"
                    placeholder="enter benefits here"
                    value={additionalBenefits}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setAdditionalBenefits(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>
              </Grid>



    


              <Grid container direction="row" sx={{mt:2,fontFamily:"Public Sans, sans-serif ",}}>
                <Grid item xs={12} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '17px', width: '60%',marginBottom:"2rem" ,position:"relative",top:"0.9rem" }}>Company Culture*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={companyCulture}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setCompanyCulture(e.target.value)}
                    placeholder=' Company Culture is the shared values, attitudes, behaviors and standards that make up a work environment.It is about the experience people have at work and how that experience
                     aligns with the external brand and messaging of the company. Culture is what creates the day-to-day experience at a company.
                      When an organization has a good company culture, employees are engaged, committed and excited to come to work.'
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>




              <Grid container direction="row" sx={{mt:2,fontFamily:"Public Sans, sans-serif ",}}>
                <Grid item xs={12} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '17px', width: '80%',marginBottom:"2rem" ,position:"relative",top:"0.9rem" }}>
                  What is your company's employee onboarding process? Please
                   include background checks and anything required to start.
                   What should a new employee expect before their first day? 
                   What's required before a new employee can be an independent contributor?*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={companyOnboarding}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",color:"gray"}}
                    onChange={(e) => setCompanyOnboarding(e.target.value)}
                    placeholder=' Enter onboarding process '
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>



              <Grid container spacing={6} direction="row" sx={{mt:2,fontFamily:"Public Sans, sans-serif ",}}>
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative",top:"0.9rem" }}>How did you hear about us ?</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Add answer here'
                      value={howDidYouHear}
                      onChange={(e) => setHowDidYouHear(e.target.value)}
                      style={inputStyle}
                      InputProps={{
                        style:{
                          fontFamily:"Public Sans, sans-serif ",
                        }
                      }}
                    />
                  </div>
                </Grid>

              
              </Grid>




              



      <center style={{marginTop:"6rem"}}>
      <LoadingButton  sx={{bgcolor:"#40255F",fontFamily:"Public Sans, sans-serif ",}}  size="large" type="submit" variant="contained" disabled={loading}>
        {loading ? "Loading..." : "Submit Application"}
      </LoadingButton>
      </center>

      </form>


      

   
     

     {/* <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 1,color:"gray" }}>
        <Typography>
        Thanks for Submitting
        </Typography>
      </Stack> */}




    </div>
  );
}
