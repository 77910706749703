import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import ProfileForm from 'src/components/login/profileForm';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import JobPostingForm from 'src/components/login/jobPostingForm';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import folder from 'src/assets/images/folder.png'
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidatesPendingReview, fetchJobsWithCandidatesPendingReview } from 'src/redux/actions/group.action';
import { saveJobInFocus } from 'src/redux/reducers/group.slice';




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
   // fontFamily:"Libre Baskerville, sans serif",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));

// ----------------------------------------------------------------------

export default function CandidatesPendingReviewPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [companyName, setCompanyName] = useState('');


  const { user } = useSelector((state) => state.auth);


  const { candidatesPendingReview,jobsPendingReview, isLoading } = useSelector((state) => state.group);
 // console.log("JOBS PENDING REVIEW IS --->",jobsPendingReview )
 // console.log("CANDIDOS PENDING REVIEW IS --->",candidatesPendingReview )
 // console.log("user details-->",user)

  //let notMyGroups = allJobs
 //const [notMyGroups,setNotMyGroups] = useState(allJobs)

 
useEffect(()=>{

  dispatch(fetchJobsWithCandidatesPendingReview(user && user.id?user.id:user && user.uid));

  dispatch(fetchCandidatesPendingReview());

},[])

  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])
  

  const rows= [
    {interviewRound:"IT-544", typeOfInterview:"Sr. Full Stack Developer",feedback:"0 Days", duration:"0/7"},
    {interviewRound:"IT-522", typeOfInterview:"Data Architect",feedback:"3 Days", duration:"0/8"},
    {interviewRound:"OPS-322", typeOfInterview:"Director of People Operations",feedback:" Days", duration:"3/7"},
    
  ];

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };



  return (
    <>
      <Helmet>
        <title> Action Items | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       
        <Container style={{marginTop:"0rem",width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         



      
       {/*THIRD SECTION  */}
            <Typography variant="h2" gutterBottom style={{marginTop:"0rem",width:"44rem",/*color:"#40255F",*/display:"flex",justifyContent:"center"}}>
            Candidates Pending Review
            </Typography>
   
          {/*<Divider style={{width:"100%",marginBottom:"3rem"}}/>*/}

          <Typography variant="body2" sx={{ mt: 1,mb:10, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem",textAlign:"center" }}>
           {/*The Job postings listed below contain candidates who have already completed their phone interviews
            with the Talent Expert and are now waiting for the next steps.
           Please select the candidates you would like to move forward to the next round.
           If you decide not to select a candidate, please provide valueable feedback. We 
           urge you to review the candidates' profiles immediately, to ensure the best candidate for your company.
          Thank you for your cooperation.*/}


           Please keep in mind that the candidates mentioned in the job postings are in the final stage
            of the interview process and awaiting your final decision. Before sending an offer letter, 
            if you have any additional questions about a candidate, we suggest using our communication
             tool to contact the Talent Expert assigned to the job posting. It is important to consider
              that candidates may need some time to evaluate the offer or discuss it with their support
               system. We believe that a three to five business day timeframe is ideal for making a
                decision to accept or reject the offer. If a candidate takes longer than three days
                 to respond, please reach out to OneRecruiter Support. 
               
              
            </Typography>



           
            


            
            <div style={{padding:"1rem",border:"2px solid lightgray",borderRadius:"0.5rem",width:"66rem"}}>
            <TableContainer sx={{py:"0rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black"}}>

                          <TableCell style={{fontSize:"1.2rem",color:"black" }} align="center">JP ID</TableCell>
                          <TableCell style={{fontSize:"1.2rem",color:"black" }} align="center">Title</TableCell>
                          <TableCell style={{fontSize:"1.2rem",color:"black" }}  align="center">Time Lapsed</TableCell>
                          {/*<TableCell align="center">Candidate Reviewed</TableCell>*/}
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobsPendingReview && jobsPendingReview.length && candidatesPendingReview && candidatesPendingReview.length? jobsPendingReview.map((row,index) => (
                           <TableRow
                           key={index}
                           sx={{my:2, '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                           onClick={()=>{dispatch(saveJobInFocus(row));navigate('/dashboard/candidates-pending-review-candidates',{state:{currentJob:row}})}}
                         >
                           <TableCell sx={{ backgroundColor:"#e0dcdc",width:"28%"}}  scope="row">  
                           
                         <TextField  
                           name="interview round"
                           inputProps={{
                             style: {
                             height:'0.6rem'
                             ,fontFamily:"Public Sans, sans-serif !important",
                             },
                             }}
                           placeholder='Round -1'
                           value={/*row.candidateLocation*/"IT-22"}
                           onChange={(e) => setCompanyName(e.target.value)}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                         />
                           
                     
                           </TableCell>


                           {<TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="type of interview"
                           inputProps={{
                             style: {
                             height:'0.6rem'
                             ,fontFamily:"Public Sans, sans-serif !important",
                             },
                             }}
                           placeholder='Virtual'
                           value={row.jobRole}
                           onChange={(e) => setCompanyName(e.target.value)}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                         />  

                           </TableCell>}


                         {/*  <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                          <TextField 
                          name="duration"
                          inputProps={{
                           style: {
                           height:'0.6rem'
                           ,fontFamily:"Public Sans, sans-serif !important",
                           },
                           }}
                          placeholder='60 mins'
                          value={row.jobRole}
                          onChange={(e) => setCompanyName(e.target.value)}
                          style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                          />  
                          
                         </TableCell> */}


                          <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>

                         <TextField 
                         name="feedback"
                         inputProps={{
                           style: {
                           height:'0.6rem'
                           ,fontFamily:"Public Sans, sans-serif !important",
                           },
                           }}
                         placeholder=''
                         value={`${ Math.round(((new Date().getTime()/1000) - (candidatesPendingReview.filter((candidate)=>(candidate.jobId === row.id))[0] && candidatesPendingReview.filter((candidate)=>(candidate.jobId === row.id))[0].pendingReviewPeriodStarted.seconds ))/86400) } Days` /*new Date(row.pendingReviewPeriodStarted.seconds*1000).toDateString()*/ }
                         onChange={(e) => setCompanyName(e.target.value)}
                         style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif !important",}}
                         />  
                         
                         </TableCell>

                        {/*  <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>

                        <TextField 
                         name="feedback"
                         inputProps={{
                           style: {
                           height:'0.6rem'
                           ,fontFamily:"Public Sans, sans-serif !important",
                           },
                           }}
                         placeholder=''
                         value={row.salaryRange}
                         onChange={(e) => setCompanyName(e.target.value)}
                         style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif !important",}}
                         />  
                         
                         </TableCell>*/}



                        {/* <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>
                         <LoadingButton onClick={()=>{addToTheRecruiterJobs(user && user.id,row.id,user &&user.firstName,user && user.lastName)}}
                          sx={{bgcolor:"#40255F",fontFamily:"Public Sans, sans-serif",width:"10rem",padding:"1rem"}}   type="submit" variant="contained" disabled={false}>
                         { "Add Job"}
                        </LoadingButton>
                         </TableCell>*/}

                         </TableRow>
                       )):
                       <TableRow  sx={{marginTop:"0.5rem",fontFamily:"Public Sans, sans-serif !important",width:"100%"}}>
                       <TableCell colSpan={5}>  <center style={{display:"flex",justifyContent:"center",padding:"0.5rem",width:"100%",color:"gray",fontSize:"22px",fontWeight:"800"}}> Candidates for job will be displayed here.. </center></TableCell>
                       </TableRow>
                      }  
                      </TableBody>
                    </Table>
                </TableContainer>
              </div>
   

           
            {/*<JobPostingForm />*/}
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
